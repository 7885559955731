/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 17-May-2024
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For customer personal info
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem                                                   |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* #1978 added property name stories and sft for billing adress      21-May-2024   Asish
#1979 added property name stories and sft for site address          21-May-2024   Asish
#1980 Created ui for site address                                   21-May-2024   Asish
#1981 integration for adding billing address and site address       21-May-2024   Asish
* 
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, IconButton, Tooltip, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox } from '@react-google-maps/api';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./Customer.css";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { UserContext, getPreviousPageDetails, getUserSession } from '../../../hooks/UserSession';
import { createCustomer, getCustomer, getCustomerUidByEmail, updateCustomer } from '../../../services/CustomerService';
import CustomDialog from '../../Dialog';
import { connect } from 'http2';
import { color, useTheme } from '@mui/system';
import { getTenantDetails } from '../../../services/TenantService';
import FileUpload from '../../../components/fileUploader/FileUploader';
import { tokens } from '../../../hooks/theme';
import { checkAsset } from '../../../services/AssetService';
import { getcustomerInfo } from '../../../services/EmployeeService';

// import Attachment from "./Fileupload/Attachment";

const libraries: any = ['places'];

const containerStyle = {
    width: '100%',
    height: '300px'
};

const Customer: React.FC = () => {

    let location = useLocation()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries
    })

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [map, setMap] = React.useState(null);
    const [sitemap, setSiteMap] = React.useState(null);
    const [save, setSave] = React.useState(false);

    const { userSessionDetails } = React.useContext(UserContext);
    const [tenantDetails, setTenantDetails] = React.useState<any>();
    const [iconVisible, setIconVisible] = React.useState(true);
    const [showShippingAddress, setShowShippingAddress] = React.useState(true);
    const { accessToken } = useOidcAccessToken();
    const [role, setRole] = React.useState<any>([] as any[]);
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [selectedRow, setSelectedRow] = React.useState<any>(null);
    const [MobileNumberValid, setMobileNumberValid] = React.useState(true);
    const [selectedFile, setSelectedFile] = React.useState<File | string>();
    const [image, setImage] = React.useState<any>(null);
    const [isAssetAddress, setIsAssetAdress] = React.useState(false);
    const [hasNoAsset, sethasNoAsset] = React.useState(false);
    const [hasCustomerEmail, setHasCustomerEmail] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = React.useState(false);


    const navigate = useNavigate();
    var userRolePermissions: any;
    var userRole: any;
    let userPermissions: Array<any> = [];
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    userRolePermissions = userSessionDetails;
    if (userRolePermissions != null && userRolePermissions != undefined) {
        userRole = userRolePermissions.role;
        if (userRole != null && userRole.userRolePermissions != null) {
            userPermissions = userRole.userRolePermissions;
        }
    }

    const [message, setMessage] = React.useState<any>({

        country: '' || null || undefined,
        zipCode: '' || null || undefined,
        state: '' || null || undefined,
        address: '' || null || undefined,
        city: '' || null || undefined,
        firstName: '' || null || undefined,
        lastName: '' || null || undefined,
        gender: '' || null || undefined,
        birthdate: null || undefined,
        mobileNo: '' || null || undefined,
        area: '',
        stories: '',
        propertyName: '',
        siteAddress: '',
        siteZipCode: '',
        sitePropertyName: '',
        siteStories: null,
        siteCountry: '',
        siteState: ''

    });
    const [show, setShow] = React.useState(false);


    const [assetData, setAssetData] = React.useState<any>({

        status: "Active",
        customerUid: "" || undefined,
        firstName: '' || undefined || null,
        lastName: '' || undefined || null,
        middleName: '' || undefined || null,
        alternateMobileNo: undefined || null,
        mobileNo: '' || null,
        birthdate: null,
        gender: '' || undefined || null,
        address: "" || undefined || null,
        name: "" || undefined,
        country: '' || undefined || null,
        zipCode: '' || undefined || null,
        state: '' || undefined || null,
        city: '' || undefined || null,
        location: "" || undefined,
        assetType: "Residencial" || undefined,
        stories: null,
        profile: '',
        siteAddress: "" || undefined || null,
        siteCountry: '' || undefined || null,
        siteZipCode: '' || undefined || null,
        siteState: '' || undefined || null,
        siteCity: '' || undefined || null,
        siteLocation: "" || undefined,
        area: '',
        siteArea: '',
        siteStories: null,
        sitePropertyName: '',
        propertyName: '',
        isAsset: false
    });
    console.log("location",location?.state);
    console.log("asset",assetData.location);
    const theme = useTheme();

    const assetDataObject = location?.state?.res?.data;
    const inputRef = React.useRef<any>();
    const inputSiteRef = React.useRef<any>();
    const [update, setUpdate] = React.useState(false);

    const [error, setError] = React.useState(false);
    const [successType, setSuccessType] = React.useState('');
    const [content, setContent] = React.useState('');

    const componentData = React.useRef<any>()
    const componentSiteData = React.useRef<any>()

    const convertLocation = (locationString: any) => {
if (locationString === null || locationString === "" || locationString === undefined){
console.log("location is null");
}
else{
        let locationObject: any = {}
        let string = locationString;
        let st = string?.split(",") || '';
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
}
    }

    const convertSiteLocation = (locationString: any) => {

        let locationObject: any = {}
        let string = locationString;
        let st = string?.split(",") || '';
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    }
    const [checked, setChecked] = React.useState(true);

    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentData.current);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback() {
        setMap(null)
    }, [])

    const onSiteLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentSiteData.current);
        map.fitBounds(bounds);
        setSiteMap(map)
    }, [])

    const onSiteUnmount = React.useCallback(function callback() {
        setSiteMap(null)
    }, [])
    const onclose = () => {
        //console.log(assetDataObject,location?.state && location.state.res?.data)
        if (userRole !== undefined && userRole.name !== "admin") {
            if (save) {
            setOpenDialogs(false);
            //    navigate('/commondashboard')
            window.location.reload();
            }
            else{
                setOpenDialogs(false);
                navigate('/soldproduction')


            }
        }
        else {
            setOpenDialogs(false);
            navigate('/admin')
        }
    }

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setShowShippingAddress(!isChecked);
        if (isChecked) {
            setAssetData((prevData) => ({
                ...prevData,
                siteAddress: prevData.address,
                siteState: prevData.state,
                siteCountry: prevData.country,
                siteLocation: prevData.location,
                siteCity: prevData.city,
                siteZipCode: prevData.zipCode,
                sitePropertyName: prevData.propertyName,
            }));
        }
    };

    const fileInputRef = React.useRef<HTMLInputElement | null>(null);
    const colors = tokens(theme.palette.mode);
    const handleIconClick = () => {
        // setIconVisible(false);

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const getTenantConfig = async () => {
        const response = await getTenantDetails(accessToken);
        //console.llog(response.data)
        if (response.data == undefined) {
            navigate("/tenantconfiguration");
        }
        let details = response.data
        setTenantDetails(details);
        // Check if response.data is defined before accessing its elements

    }
    const getFile = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(URL.createObjectURL(file));
        setImage(file);
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setAssetData({
                        ...assetData,
                        profile: reader.result,



                    })
                }
            };
            reader.onerror = (error) => {
                console.error('Error occurred while reading the file:', error);
            };
        }
        // Do something with the selected file, e.g., upload or process it
        ////console.log('Selected file:', selectedFile);
    };
    const onClick = (e: google.maps.MapMouseEvent) => {
        const { latLng } = e;
        if (!latLng) return; // Ensure latLng is available

        const { lat, lng } = latLng.toJSON();
        componentData.current = { lat, lng };

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } })
            .then((response) => {
                if (response && response.results && response.results.length > 0) {
                    const addressComponents = response.results[0].address_components;
                    const locationObject: any = {};

                    addressComponents.forEach((component) => {
                        const { long_name, types } = component;

                        if (types.includes("street_number") || types.includes("route")) {
                            locationObject.address = long_name;
                        } else if (types.includes("locality")) {
                            locationObject.city = long_name;
                        } else if (types.includes("administrative_area_level_1")) {
                            locationObject.state = long_name;
                        } else if (types.includes("country")) {
                            locationObject.country = long_name;
                        } else if (types.includes("postal_code")) {
                            locationObject.zipCode = long_name;
                        }
                    });

                    locationObject.location = `${lat},${lng}`;
                    // locationObject.siteLocation= `${lat},${lng}`;
                    setAssetData({ ...assetData, ...locationObject });
                } else {
                    console.error("Geocoder returned no results or an empty results array.");
                }
            })
            .catch((error) => {
                console.error("Geocoding error:", error);
            });
    };
    const onClickSiteAddress = (e: google.maps.MapMouseEvent) => {
        const { latLng } = e;
        if (!latLng) return; // Ensure latLng is available

        const { lat, lng } = latLng.toJSON();
        componentSiteData.current = { lat, lng };

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } })
            .then((response) => {
                if (response && response.results && response.results.length > 0) {
                    const addressComponents = response.results[0].address_components;
                    const locationObject: any = {};

                    addressComponents.forEach((component) => {
                        const { long_name, types } = component;

                        if (types.includes("street_number") || types.includes("route")) {
                            locationObject.siteAddress = long_name;
                        } else if (types.includes("locality")) {
                            locationObject.siteCity = long_name;
                        } else if (types.includes("administrative_area_level_1")) {
                            locationObject.siteState = long_name;
                        } else if (types.includes("country")) {
                            locationObject.siteCountry = long_name;
                        } else if (types.includes("postal_code")) {
                            locationObject.siteZipCode = long_name;
                        }
                    });

                    locationObject.siteLocation = `${lat},${lng}`;
                    setAssetData({ ...assetData, ...locationObject });
                } else {
                    console.error("Geocoder returned no results or an empty results array.");
                }
            })
            .catch((error) => {
                console.error("Geocoding error:", error);
            });
    };
    const getCustomerUidByemail = (email: any) => {
        getCustomerUidByEmail(email, accessToken).then((res) => {
            ////console.log(res);
            navigate("/assetList", { state: { res } });
        });
    }
    const handlePlaceChanged = () => {
        ////console.log(inputRef.current.getPlaces())
        const [place] = inputRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    // setLocation({...location,address:fulladdress.join(",")});
                    // Object.assign(location,{address:fulladdress.join(",")});
                    locationObject['address'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    // setLocation({...location,city:address.long_name});
                    locationObject['city'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    // setLocation({...location,state:address.long_name});
                    locationObject['state'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    // setLocation({...location,country:address.lon;g_name});
                    locationObject['country'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    // setLocation({...location,zipCode:address.long_name});
                    locationObject['zipCode'] = address.long_name;
                }
                else {
                    locationObject['zipCode'] = '';
                    locationObject['address'] = fulladdress.join(",");

                }
            })

            componentData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setAssetData({ ...assetData, zipCode: locationObject.zipCode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
        }
    }
    const handlePlaceChangedSiteAddress = () => {
        ////console.log(inputSiteRef.current.getPlaces())
        const [place] = inputSiteRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    // setLocation({...location,address:fulladdress.join(",")});
                    // Object.assign(location,{address:fulladdress.join(",")});
                    locationObject['siteAddress'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    // setLocation({...location,city:address.long_name});
                    locationObject['siteCity'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    // setLocation({...location,state:address.long_name});
                    locationObject['siteState'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    // setLocation({...location,country:address.lon;g_name});
                    locationObject['siteCountry'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    // setLocation({...location,zipCode:address.long_name});
                    locationObject['siteZipCode'] = address.long_name;
                }
                else {
                    locationObject['siteZipCode'] = '';
                    locationObject['siteAddress'] = fulladdress.join(",");

                }
            })

            componentSiteData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setAssetData({ ...assetData, siteZipCode: locationObject.siteZipCode, siteCountry: locationObject.siteCountry, siteAddress: locationObject.siteAddress, siteCity: locationObject.siteCity, siteState: locationObject.siteState, location: Object.values(componentSiteData.current).join(",") });
        }
    }

    const inputchange = (e: any) => {
        if (e.target.name === "stories" || e.target.name === "siteStories") {

            const enteredValue = parseFloat(e.target.value);
            if (enteredValue >= 0) {
                setAssetData({
                    ...assetData,
                    [e.target.name]: enteredValue

                });
            }
        }
        // else if(e.target.name === "area"){
        //     setAssetData({
        //         ...assetData,
        //         [e.target.name]: parseFloat(e.target.value)

        //     });
        // }

        else if (e.target.name === "area" || e.target.name === "siteArea") {
            const enteredValue = parseFloat(e.target.value);
            // Check if the entered value is a valid non-negative number
            if (enteredValue >= 0) {
                // If it's valid, update the state
                setAssetData({
                    ...assetData,
                    [e.target.name]: enteredValue
                });
            }
        }

        else {
            setAssetData({
                ...assetData,
                [e.target.name]: e.target.value

            });
        }

    };
    const handleCancelAssetAddress = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSave(true);

        if (assetData.isAsset === null) {
            setAssetData(prevState => ({ ...prevState, isAsset: false }));
        }
        else {
            setAssetData(prevState => ({ ...prevState, isAsset: false }));

        }
        setIsAssetAdress(false);
        handleCreateCustomer();

    }
    const handleSave = () => {
        
        // setIsAssetAdress(true);
        let validated = validation();
        setCount(count + 1);
        if(validated){
       setIsAssetAdress(true);
        }
        else{
            console.log("fill the required")
            
        }
    }
    const handleUpdate = () => {
        //console.log(role ==='customer',hasNoAsset)

        if(role ==='customer'){
//console.log(role ==='customer',hasNoAsset)
       if(hasNoAsset){
        if (
            assetDataObject.address !== assetData.address ||
            assetDataObject.country !== assetData.country ||
            assetDataObject.zipCode !== assetData.zipCode ||
            assetDataObject.state !== assetData.state ||
            assetDataObject.city !== assetData.city ||
            assetDataObject.isBilling !== assetData.isBilling
        ) {
            setIsAssetAdress(true);
        }
        else {
            updateHandleSubmit();
        }
       }
       else{
        updateHandleSubmit();

       }
         }
        else{
            updateHandleSubmit();

        }
    }
    const handleUpdateAssetAddress = () => {
        //console.log(assetData)
        setSave(false);
        if (assetData.isAsset === false || assetData.isAsset === null) {
            setUpdate(true)
            setAssetData(prevState => ({
                ...prevState,
                isAsset: true
            }));
            setIsAssetAdress(false);
        }
    };
    const handleCancelUpdateAssetAddress = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSave(false);

        if (assetData.isAsset === null) {
            setAssetData(prevState => ({ ...prevState, isAsset: false }));
        }
        else {
            setAssetData(prevState => ({ ...prevState, isAsset: false }));

        }
        setIsAssetAdress(false);
        updateHandleSubmit();

    }
    const handleAssetAddress = () => {
        setSave(true);

        if (assetData.isAsset === false) {
            setUpdate(false)

            setAssetData(prevState => ({
                ...prevState,
                isAsset: true
            }));
            setIsAssetAdress(false);
        }
    };
    const [isTaxExempt, setIsTaxExempt] = React.useState(false);
    const validation = () => {
        let validated: any = true;

        if (assetData.state === undefined || assetData.state === '' || assetData.state === null) {
            // setMessage({...message,state:'Enter Your State'});
            message.state = 'Enter Your State';
            validated = false;

        }
        if (assetData.city === undefined || assetData.city === '' || assetData.city === null) {
            // setMessage({...message,city:'Enter Your City'});
            message.city = 'Enter Your City';
            validated = false;

        }
        if (assetData.location === undefined || assetData.location === '' || assetData.location === null) {
            // setMessage({...message,city:'Enter Your City'});
            message.city = 'Enter Your location';
            console.log("location null")
            validated = false;

        }

        // if (assetData.area === undefined || assetData.area === '' || assetData.area === null) {
        //     // setMessage({...message,city:'Enter Your City'});
        //     message.area = 'Enter Your Area';
        //     validated = false;

        // }
        // if (assetData.stories === undefined || assetData.stories === '' || assetData.stories === null) {
        //     // setMessage({...message,city:'Enter Your City'});
        //     message.stories = 'Enter Your Stories';
        //     validated = false;

        // }
        if (assetData.address === undefined || assetData.address === '' || assetData.address === null) {
            // setMessage({...message,city:'Enter Your City'});
            message.city = 'Enter Your City';
            validated = false;

        }
        if (assetData.country === undefined || assetData.country === '' || assetData.country === null) {
            //setMessage({...message,country:'Enter Your Country'});
            message.country = 'Enter Your Country';
            validated = false;

        }

        if (assetData.gender === null || assetData.gender === "" || assetData.gender === undefined) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.gender = 'Select Your gender';
            validated = false;

        }
        if (assetData?.birthdate?.$d?.toString() === "Invalid Date") {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.birthdate = 'Enter your  date of birth';
            validated = false;

        }
        if (assetData.firstName === null || assetData.firstName === "" || assetData.firstName === undefined) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.firstName = 'Enter your  first name';
            validated = false;

        }
        if (assetData.lastName === null || assetData.lastName === "" || assetData.lastName === undefined) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.lastName = 'Enter your last name';
            validated = false;

        }
        if (assetData.email === null || assetData.email === undefined || assetData.email === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.email = 'Enter your email';
            validated = false;

        }
        if (assetData.zipCode === null || assetData.zipCode === "" || assetData.zipCode === undefined) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.zipCode = 'Enter your mobile no';
            validated = false;

        }
        // if (assetData.propertyName === null || assetData.propertyName === "" || assetData.propertyName === undefined) {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.propertyName = 'Enter your property name ';
        //     validated = false;

        // }
        // if (assetData.sitePropertyName === null || assetData.sitePropertyName === "" || assetData.sitePropertyName === undefined) {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.sitePropertyName = 'Enter your property name';
        //     validated = false;

        // }
        // if (assetData.siteAddress === null || assetData.siteAddress === "" || assetData.siteAddress === undefined) {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.siteAddress = 'Enter your Site Address';
        //     validated = false;

        // }
        // if (assetData.siteZipCode === null || assetData.siteZipCode === "" || assetData.siteZipCode === undefined) {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.siteZipCode = 'Enter your Site zipcode';
        //     validated = false;

        // }
        // if (assetData.siteState === null || assetData.siteState === "" || assetData.siteState === undefined) {
        //     // setMessage({...message,stories:'Enter Your Stories'});
        //     message.siteState = 'Enter your Site zipcode';
        //     validated = false;

        // }
        if (!MobileNumberValid || assetData.mobileNo === null || assetData.mobileNo === "" || assetData.mobileNo === undefined) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.mobileNo = 'Enter your mobile no';
            validated = false;

        }
        return validated;

    }
    const handleCreateCustomer = async () => {
        //setAssetData({ ...assetData, customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        // Object.assign(assetData,{customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });

        //console.log(assetData);
        setCount(count + 1);
        // formData.append("file", image);
        let validated = validation();
        if (validated === true) {
            let result = await createCustomer(assetData,tenantDetails?.tenantid
                ,accessToken);
            setOpenDialogs(true);
            //////console.log(result)
            // let uploadResult = await saveAssetDocument(formData, result.data, accessToken);
            if (result.ok === true) {
                // handleClick('Inserted Successfully');
                setSuccessType('save');
                // setOpenDialogs(true);
                setCreateSuccess(true);
                setContent("Created successfully")


                // setSuccess(true);
                // setTimeout(() => {
                //     onNextPage();
                // }, 2000);
            }
            else {
                // handleClick('Not Inserted');
                setCreateError(true);
                setError(true);
            }
        }
        // else{
        //     handleClick('Not Inserted');
        // }

    }

    const handleClose = () => {
        const previousPageDetails = getPreviousPageDetails();
        navigate(previousPageDetails.path,
            { state: { selectedTab: previousPageDetails.tabPosition } }
        );
    }

    const validateMobileNumber = (mobileNo) => {
        return /^\d{10}$/.test(mobileNo);

    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        ////console.log(name);
        setAssetData({
            ...assetData,
            [name]: value
        });

        if (name === 'mobileNo') {
            const isValidMobileNumber = validateMobileNumber(value);
            setMobileNumberValid(isValidMobileNumber);
        }



    };


    const handleCardClick = () => {
        const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
        fileInput.click();
    };
    const handleNoChange = () => {

        setNoChange(false)

    }
    const handleDateChange = (event: any) => {
        //////console.log(event.$d);
        const formattedDate = dayjs(event.$d).format('YYYY-MM-DD');
        setAssetData({
            ...assetData,
            // birthdate: formattedDate(in customer dto is local date use it otherwise offsetdate time use below format)
            birthdate: event.$d
        });
    }
    const updateHandleSubmit = async () => {
        setCount(count + 1);
        let validated = validation();
        // formData.append("file", image);
        //console.log(assetData)
        if (assetDataObject.address === assetData.address && assetDataObject.country === assetData.country
            && assetDataObject.zipCode === assetData.zipCode && assetDataObject.state === assetData.state && assetDataObject.city === assetData.city && assetDataObject.mobileNo === assetData.mobileNo && assetDataObject.gender === assetData.gender
            && assetDataObject.birthdate === dayjs(assetData.birthdate).format('YYYY-MM-DD') && assetDataObject.firstName === assetData.firstName && assetDataObject.lastName === assetData.lastName && assetDataObject.middleName === assetData.middleName
            && assetDataObject.alternateMobileNo === assetData.alternateMobileNo && assetDataObject.profile === assetData.profile
            // && assetDataObject.siteAddress === assetData.siteAddress && assetDataObject.siteCountry === assetData.siteCountry
            // && assetDataObject.siteZipCode === assetData.siteZipCode && assetDataObject.siteState === assetData.siteState && assetDataObject.siteCity === assetData.siteCity
            // && assetDataObject.siteArea === assetData.siteArea && assetDataObject.siteStories === assetData.siteStories && assetDataObject.area === assetData.area && assetDataObject.stories === assetData.stories && assetDataObject.propertyName === assetData.propertyName && assetDataObject.sitePropertyName === assetData.sitePropertyName
        ) {
            // handleClick('No valued Changed');
            setNoChange(true);

        }
        else {
            if (validated === true) {
                // if (typeof (assetData.location) != "string") {
                //     let data = assetData;
                //     data.location = Object.values(assetData.location).join(",")
                //     setAssetData(data);
                // }
                setAssetData({
                    ...assetData,
                    status: "Active"

                });
                //////console.log(assetData)
                setOpenDialogs(true);

                let result = await updateCustomer(assetData, accessToken);
                //////console.log(result)
                // let uploadResult = await editAssetDocument(formData, assetId, accessToken);
                if (result.status === 200 || result.status === 201) {
                    // handleClick('Updated Successfully');
                    setSuccessType('update');
                    setCreateSuccess(true);
                    setContent("Updated successfully")
                    // setTimeout(() => {
                    //     onNextPage();
                    // }, 2000);
                }
                else {
                    // handleClick('Not Updated');
                    setCreateError(true)
                    setError(true);
                }
            }

        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setIconVisible(false);
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setAssetData({
                        ...assetData,
                        profile: reader.result,

                    })
                }
            };
            reader.onerror = (error) => {
                console.error('Error occurred while reading the file:', error);
            };
        }
        // Do something with the selected file, e.g., upload or process it
        ////console.log('Selected file:', selectedFile);
    };
    React.useEffect(() => {
        getUserSession(accessToken).then(res => {
            setRole(res.role.name);
        });

        hasCustomerEmailCheck();
        //console.log(location?.state, (location.state === null) || (location?.state?.res.data === ''))
        // if (location.state !== null) {
            checkAsset(accessToken).then(res => {

                //console.log(res.data)
                sethasNoAsset(res.data);
            })

        if (location?.state && location.state.res?.data) {
            // setIconVisible(false);
            ////console.log(location?.state?.res?.data)
            setAssetData(location?.state?.res?.data);
            if (location?.state?.res?.data?.profile !== '') {
                setSelectedFile(location?.state?.res?.data?.profile);
                if ((location?.state?.res?.data?.address === location?.state?.res?.data?.siteAddress) && (location?.state?.res?.data?.location === location?.state?.res?.data?.siteLocation)) {
                    setShowShippingAddress(false);

                }
            }
        }
        else {
            //console.log("hii")
            getUserSession(accessToken)
                .then(res => {
                    //console.log(res)

                    setAssetData({
                        ...assetData,
                        firstName: res.firstname,
                        lastName: res.lastname,
                        middleName: res.middlename,
                        birthdate: dayjs(res.birthdate),
                        mobileNo: res.mobileNo,
                        gender: res.gender,
                        email: res.email,
                        alternateMobileNo: res.alternateMobileNo


                    });
                })

        }


        getTenantConfig();
        if (assetData.isAsset === true) {
            if(!update){
                handleCreateCustomer();

            }
            else{
                updateHandleSubmit();
            }
        }
        //    setHasEmail(response);

    }, [assetData.isAsset]);

    const hasCustomerEmailCheck = async () => {
        try {
            const response = await getcustomerInfo(userSessionDetails?.email, accessToken);
          console.log(response);
          setHasCustomerEmail(response?.data);
        } catch (error) {
            console.error(error);
            return null; // Or handle the error as needed
        }
    };
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >

                <Card sx={{ minWidth: '100%', p: 3 }} className='contractCard'>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                    >
                        <Grid item xs={12} md={12} className="myDiv customerPersonalInfo">
                            <Box
                                sx={{
                                    // borderBottom: 1,
                                    // borderColor: "divider",
                                    // backgroundImage: "linear-gradient(to bottom, #3c5bfa, white)", // Gradient from blue to white
                                }}
                            >
                                <Grid xs={12} md={12} className='personalInfo'>
                                    <Typography className='personalInfo' variant="h3" component="h2" align='left' sx={{ mx: 1, py: 1 }} style={{ color: 'white' }}>
                                        Personal Info
                                    </Typography>
                                </Grid>
         
                                <Card variant="outlined" className='tenantImageUpload' onClick={handleCardClick} raised sx={{ maxWidth: 160, maxHeight: 150, margin: '0 auto', padding: '0.1em', borderRadius: '50% !important' }}

                                >
                                    <label>
                                        {typeof selectedFile === "string" ? (
                                            <img src={selectedFile} alt="Selected" className='provisioningImage configCusImg' />
                                        ) : (
                                            <Stack sx={{ textAlign: 'center', cursor: "pointer", }}>
                                                <Typography>
                                                    <AddIcon />
                                                </Typography>
                                                <Typography className="logoCusText">
                                                    Upload profile
                                                </Typography>
                                            </Stack>
                                        )}
                                    </label>
                                    <input
                                        type="file"
                                        id="image-upload-input"
                                        onChange={(e) => getFile(e)}
                                        style={{ display: "none" }}
                                        accept="image/*"
                                    />

                                </Card>
                            </Box></Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant='h4' component="h2" align='left' sx={{ mx: 1, py: 1 }}>

                                Name and contact
                            </Typography>
                        </Grid>


                        <Grid item xs={12} md={4}>
                            <TextField
                                error={(assetData.firstName === '' && count !== 0)}

                                id="outlined-multiline-static"
                                label="First name"
                                size="small"
                                fullWidth

                                // defaultValue=""
                                // type="date"
                                name="firstName"
                                helperText={(assetData.firstName === '' && count !== 0) ? message.firstName : null || undefined}
                                InputLabelProps={{ shrink: !!assetData.firstName }} // This will move the label to the top if there's a value

                                onChange={handleInputChange}
                                value={assetData.firstName}

                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Middle Name"
                                size="small"
                                fullWidth

                                // defaultValue=""
                                // type="date"
                                name="middleName"
                                value={assetData.middleName}
                                InputLabelProps={{ shrink: !!assetData.middleName }} // This will move the label to the top if there's a value

                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                error={(assetData.lastName === '' || assetData.lastName === null || assetData.lastName === undefined) && count !== 0}
                                id="outlined-multiline-static"
                                label="Last name"
                                size="small"
                                fullWidth
                                // defaultValue=""
                                // type="date"
                                name="lastName"
                                InputLabelProps={{ shrink: !!assetData.lastName }} // This will move the label to the top if there's a value

                                helperText={(assetData.lastName === '' && count !== 0) ? message.lastName : null}
                                value={assetData.lastName}
                                onChange={handleInputChange}
                            />
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Company name"
                                size="small"
                                fullWidth
                                defaultValue=""
                                // type="date"
                                name="companyname"
                                value={tenantDetails?.name}
                                InputLabelProps={{ shrink: !!tenantDetails?.name }} // This will move the label to the top if there's a value

                                onChange={handleInputChange}
                            />
                        </Grid>



                        <Grid item xs={12} md={6}>
                            <TextField
                                error={(assetData.email === '' && count !== 0)}

                                id="outlined-multiline-static"
                                label="Email"
                                size="small"
                                fullWidth

                                defaultValue=""
                                // type="email"
                                InputLabelProps={{ shrink: !!assetData.email }} // This will move the label to the top if there's a value

                                name="email"
                                value={assetData.email}
                                onChange={handleInputChange}
                                helperText={(assetData.email === '' && count !== 0) ? message.email : null}
                                InputProps={{ readOnly: true }} // Make the TextField read-only
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
              
                            <FormControl fullWidth required error={(assetData.gender === '' && count !== 0)}>
                                <InputLabel id="demo-simple-select-label" >
                                    Gender
                                </InputLabel>
                                <Select
                                    error={(assetData.gender === null && count !== 0)}

                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={assetData?.gender || ''}
                                    label="Gender"
                                    name="gender"
                                    onChange={(e: any) => inputchange(e)}
                                    size='small'

                                >
                                    <MenuItem value={"male"}>Male </MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>

                                </Select>
                                {assetData.gender === null && count !== 0 && (
                                    <FormHelperText style={{ color: 'red' }}>{message.gender}</FormHelperText>
                                )}
                                {/* {genderError && <FormHelperText>{(assetData.gender === '' && count !== 0) ? message.gender : null}</FormHelperText>} */}
                            </FormControl>


                        </Grid>

                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label="Birth Date"
                                        name='birthdate'
                                        onChange={(e: any) => handleDateChange(e)}
                                        value={dayjs(assetData ? assetData.birthdate : null)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                size: 'small',
                                                error: (assetData.birthdate?.$d?.toString() === 'Invalid Date' && count !== 0),
                                                helperText: (assetData.birthdate?.$d?.toString() === 'Invalid Date' && count !== 0) ? 'Enter your date of birth' : null
                                            }
                                        }}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                error={!MobileNumberValid}
                                helperText={!MobileNumberValid ? "Invalid mobile number" : null}

                                id="outlined-multiline-static"
                                label="Primary number"
                                size="small"
                                fullWidth

                                defaultValue=""
                                // type="date"
                                name="mobileNo"
                                type='number'
                                InputLabelProps={{ shrink: !!assetData.mobileNo }} // This will move the label to the top if there's a value

                                value={assetData.mobileNo}
                                onChange={handleInputChange}
                                inputProps={{
                                    maxLength: 10,
                                }}


                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Secondary mobile number"
                                size="small"
                                fullWidth

                                defaultValue=""
                                // type="date"
                                value={assetData.alternateMobileNo}

                                name="alternateMobileNo"
                                onChange={handleInputChange}
                            />
                        </Grid>

           

                    </Grid>
                </Card>


                <Grid item xs={12} md={12}>
                    <Typography variant='h4' component="h2" align='left' sx={{ mx: 1, py: 1 }}>
                        Address
                    </Typography>
                </Grid>

                <Card sx={{ minWidth: '100%', p: 3 }} className='contractCard'>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                    >

                        <Grid item xs={12} md={12}>
                            <Typography variant='h6'>
                                Billing Address
                            </Typography>
                        </Grid>
                  
                        <Grid item xs={6}>
                            {isLoaded
                                &&
                                <StandaloneSearchBox
                                    onLoad={ref => inputRef.current = ref}
                                    onPlacesChanged={handlePlaceChanged}
                                >
                                    <TextField
                                        error={(assetData.address === '' || assetData.address === null || assetData.address === undefined) && count !== 0}
                                        id="Street address"
                                        label="Street address"
                                        name="address"
                                        size="small"
                                        fullWidth
                                        value={assetData.address}
                                        // value={assetDataObject ? assetDataObject.address : assetData.address}
                                        InputLabelProps={{ shrink: !!assetData.address }} // This will move the label to the top if there's a value

                                        required
                                        helperText={(assetData.address === undefined && count !== 0) ? message.address : null}
                                        // helperText={message.address}
                                        onChange={(e) => inputchange(e)}
                                    />
                                </StandaloneSearchBox>}
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                error={(assetData.city === '' || assetData.city === null || assetData.city === undefined) && count !== 0}
                                id="demo-helper-text-aligned"
                                label="City"
                                size="small"
                                fullWidth
                                required
                                name="city"
                                value={assetData.city}
                                InputLabelProps={{ shrink: !!assetData.city }} // This will move the label to the top if there's a value

                                // value={assetDataObject ? assetDataObject.city : assetData.city}
                                helperText={(assetData.city === undefined && count !== 0) ? message.city : null}
                                onChange={(e) => inputchange(e)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                error={(assetData.state === '' || assetData.state === null || assetData.state === undefined) && count !== 0}

                                id="demo-helper-text-aligned-no-helper"
                                label="State"
                                size="small"
                                fullWidth
                                name="state"
                                required
                                value={assetData.state}
                                InputLabelProps={{ shrink: !!assetData.state }} // This will move the label to the top if there's a value

                                // value={assetDataObject ? assetDataObject.state : assetData.state}
                                onChange={(e) => inputchange(e)}
                                helperText={((assetData.state === '' || assetData.state === null || assetData.state === undefined) && count !== 0) ? message.state : null}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                error={(assetData.country === '' || assetData.country === null || assetData.country === undefined) && count !== 0}
                                id="demo-helper-text-aligned-no-helper"
                                label="Country"
                                size="small"
                                fullWidth
                                value={assetData.country}
                                InputLabelProps={{ shrink: !!assetData.country }} // This will move the label to the top if there's a value

                                // value={assetDataObject ? assetDataObject.country : assetData.country}
                                helperText={((assetData.country === '' || assetData.country === null || assetData.country === undefined) && count !== 0) ? message.country : null}
                                name="country"
                                required
                                onChange={(e) => inputchange(e)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                error={(assetData.zipCode === '' || assetData.zipCode === null || assetData.zipCode === undefined) && count !== 0}
                                id="demo-helper-text-aligned-no-helper"
                                label="Zip Code"
                                size="small"
                                name="zipCode"
                                fullWidth
                                value={assetData.zipCode}
                                InputLabelProps={{ shrink: !!assetData.zipCode }} // This will move the label to the top if there's a value

                                // value={assetDataObject ? assetDataObject.zipCode : assetData.zipCode}
                                required
                                helperText={((assetData.zipCode === '' || assetData.zipCode === null || assetData.zipCode === undefined) && count !== 0) ? message.zipCode : null}
                                onChange={(e) => inputchange(e)}
                            />
                        </Grid>
      
                        <Grid item xs={12}>

                            {/* <Link  href='/asset' underline="none" color="inherit">{'Property Not Found? Add New Property'}</Link> */}
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <Typography color="grey">Property Details</Typography> */}
                                <Link
                                    component='button'
                                    underline="none"
                                    key="1"
                                    color="inherit"
                                    id=''
                                    onClick={() => getCustomerUidByemail(assetData.email)}
                                    sx={{
                                        "&:hover": {
                                            color: colors.primary[500] + ' !important',
                                            background: 'none !important',
                                        },
                                    }}
                                    className='formSubTitle'

                                >
                                    <Tooltip
                                        title="show properties"
                                        arrow
                                    >

                                        <Link>
                                            click to view property details
                                        </Link>
                                    </Tooltip>
                                </Link>

                            </div>

                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                            <Grid item xs={12} md={12}>

                                <FormGroup>
                                    <FormControlLabel  control={<Checkbox required  defaultChecked checked={isTaxExempt} onChange={handleChange} />} label="Have Tax Exemption certificate" onClick={onTax} /></FormGroup></Grid>
                            <FileUpload open={isDialogOpen} props={selectedRow} onClose={closeDialog} onFileUpload={onFileUpload} /></Grid> */}
                        <Grid item xs={12}>
                            <Stack direction={'row'}>
                                <Typography><MapOutlinedIcon fontSize='small' /></Typography>
                                <Typography>Choose from MAP</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            {isLoaded ? (
                                assetData.location == null ? (
                                    <GoogleMap
                                        mapContainerStyle={{ width: '100%', height: '400px' }}
                                        center={convertLocation(assetData?.location)}
                                        zoom={15}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        onClick={onClick}
                                    >

                                        <MarkerF position={convertLocation(assetData.location)} />

                                    </GoogleMap>
                                ) : <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: '400px' }}
                                    center={convertLocation(assetData.location)}
                                    zoom={15}
                                    // onLoad={onLoad}
                                    onUnmount={onUnmount}
                                    onClick={onClick}
                                >

                                    <MarkerF position={convertLocation(assetData.location)} />

                                </GoogleMap>) : <></>}
                        </Grid>

                      

                    </Grid>
                </Card>



               

                <Card sx={{ minWidth: '100%', p: 3 }} className='contractCard'>
                  
                    <Grid container spacing={2} justifyContent="flex-end" mt={2}>

                            {/* <Grid item>
                                <Button className='dangerButton' variant="contained" size="medium" onClick={() => setOpenChangePassword(true)}>
                                    Reset Password
                                </Button>
                            </Grid> */}
                       
                        {location.state !== null? (
                            <Grid item mt={1}>
                                <Button className='successButton' variant="contained" size="medium" onClick={handleUpdate}>
                                    Update
                                </Button>
                            </Grid>
                        ) : (
                            <Grid item mt={1}>
                                <Button className='successButton' variant="contained" size="medium" onClick={handleSave}>
                                    Save
                                </Button>
                            </Grid>
                        )}
                        {/* <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={handleCreateCustomer}>
                                        Save
                                    </Button>
                                </Grid> */}

                        <Grid item>
                            <Button className='dangerButton' variant="contained" size="medium" onClick={handleClose} >
                                Cancel
                            </Button>
                        </Grid>

                    </Grid>
                </Card>


                <Dialog open={isAssetAddress} >
                    <DialogTitle >

                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                        <DialogContent >
                            <Box paddingLeft={8} paddingRight={8}>
                                {/* <Typography textAlign={'center'}> */}
                                {/* <AttachMoneyIcon sx={{ fontSize: "50px", color: "white" }} /></Typography> */}
                                <Typography textAlign={'center'} color='black' fontSize={'20px'} fontWeight={'400'} >
                                   Your asset is empty , Set this as your new asset address ?                               </Typography>
                            </Box>
                            {/* <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>{successType === 'save' ? 'Error Couldnot  Inserted' : 'Error Couldnot  Updated'}</DialogContentText> */}
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>

                            {location.state ? (<>  <Button variant='contained' onClick={handleUpdateAssetAddress}>Yes</Button>
                                <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleCancelUpdateAssetAddress}>No</Button></>
                            ) : (
                                <>  <Button variant='contained' onClick={handleAssetAddress}>Yes</Button>
                                    <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleCancelAssetAddress}>No</Button></>

                            )}
                        </DialogActions>
                    </Box>
                </Dialog>
                <Dialog open={noChange} >
                    <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                            {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                        </Box>
                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                        <DialogContent >
                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <CustomDialog
                    open={openDialogs}
                    onClose={() => onclose()}
                    success={createSuccess}
                    error={createError}
                    Content={createSuccess ? content : " Error Couldnot create"} />
            </Grid>


        </Box >


    )
};

export default Customer;
