// components/FileUpload.tsx
import React, { useCallback, useRef, useState } from "react";
// import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import axios from "axios";
import { Paper, IconButton, Typography, CircularProgress, Grid, Dialog, DialogContent, DialogActions, Button, DialogTitle, Divider, TextField } from "@mui/material";
import { Box } from "@mui/system";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from "../../../../../pages/Dialog";
import { saveDocument } from "../../../../../services/DocumentService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

// interface FileUploadProps {   onFileUpload: (file: File) => void;  }


const FileUpload = ({ open, props, onClose, onFileUpload }) => {
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>([]);

  // const [imagePreview, setImagePreview] = useState(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createError, setCreateError] = React.useState(false);
  const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);

  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const handleDragOver = useCallback((event: any) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event: any) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event: any) => {
      event.preventDefault();
      setDragOver(false);
      const files = event.dataTransfer.files;
      console.log(files)
      if (files && files[0]) {
        handleFileChange(files[0]);
      }
    },
    []
  );
  const handleFileChange = (file: File) => {
    console.log(file);
    setLoading(true);
    setFile(file);
    const formData = new FormData();
    formData.append('file', file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setLoading(false);
      if (reader.result === null) {
        // Handle the case where reader.result is null
        setImagePreview(null);
      } else {
        // Handle the case where reader.result is a string
        setImagePreview(reader.result as string);
      }
    };
    reader.readAsDataURL(file);
    console.log(reader)
    onFileUpload(file);
  };

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  let images: any = [];
  const handleChange = useCallback(
    (event: any) => {
      // const files = event.target.files;
      // if (files && files[0]) {
      //   handleFileChange(files[0]);
      // }
      for (let i = 0; i < event.target.files.length; i++) {
        images.push((event.target.files[i]));
        let reader = new FileReader();
        let file = event.target.files[i];
        // handleFileChange(images);
        setFile(images);
        reader.onloadend = () => {
          SetSelectedFile((preValue) => {
            return [
              ...preValue,
              {
                // id: shortid.generate(),
                filename: event.target.files[i].name,
                filetype: event.target.files[i].type,
                fileimage: reader.result,
                datetime: event.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                filesize: filesizes(event.target.files[i].size)
              }
            ]
          });
        }
        if (event.target.files[i]) {
          reader.readAsDataURL(file);
        }
      }
    },
    []
  );

  const handleClose = () => {
    onClose();
  }

  const handleDialogClose = () => {
    setOpenDialogs(false);
    onClose();
    window.location.reload();// Close the dialog content
  };

  const handleUploadChange = async () => {
    setOpenDialogs(true);
    console.log("Upload file:", file);
    try {
      if (file !== null && file.length > 0) {
        const formData = new FormData();
        file.map((file: any) => {
          formData.append('file', file);
        });
        console.log(formData, file);
        const data = await saveDocument(props?.id, props?.projectId, formData, accessToken);
        console.log(data)
        if (data?.status == 200) {
          console.log("Upload successful:", data);
          setCreateSuccess(true);
        } else {
          setCreateError(true);
          console.error("Upload failed:", data);
        }
      }
      else {
        console.error("Invalid file:", file);
        setCreateError(true);
      }
    }
    catch (error) {
      console.error("Error uploading:", error);
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        // onClose={handleClose}
        //onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown

      >
        <DialogTitle className="verification" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Upload File
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "red" }} />
            </IconButton>
          </Box>
        </DialogTitle>

        <Divider />
        <DialogContent>

          <Box>
            <Paper
              variant="outlined"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{
                border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
                padding: 20,
                textAlign: 'center',
                cursor: 'pointer',
                background: dragOver ? '#eee' : '#fafafa',
                position: 'relative',
              }}
            >
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleChange}
              />
              <label htmlFor="raised-button-file">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <CloudUploadIcon style={{ fontSize: 60 }} />
                  </IconButton>
                  <Typography>Upload</Typography>
                  <Typography>Drag and drop files here or click to select files</Typography>
                </Box>
              </label>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Paper>

            <Grid container justifyContent="center" style={{ marginTop: 16 }}>
              {selectedfile.map((i: any) => {
                return (
                  < Grid item xs={12} sm={6} md={4} p={2}>
                    <Box
                      component="img"
                      src={i.fileimage}
                      alt="Image Preview"
                      sx={{ width: '100%', height: 'auto' }}
                    />
                  </Grid>
                )
              }
              )}
            </Grid>

          </Box>
        </DialogContent>
        <DialogActions><Button onClick={handleUploadChange}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog >
      <CustomDialog
        open={openDialogs}
        // onClose={() => setOpenDialogs(false)}
        onClose={handleDialogClose}
        success={createSuccess}
        error={createError}
        Content={createSuccess ? "Uploaded succesfully" : " Upload Failed "} />
    </>
  );
}

export default FileUpload;

