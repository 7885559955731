/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      :For showing project status for customer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE     |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*  1519          |  28/3/24    |   Nandha      |  In status of my project search during estimation cost is empty issue
*  2072          |  28/05/24   |   Vanitha     |  Status of my Project Ui changed 
*
*
*/
import * as React from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow,
    Paper, IconButton,
    Grid, Stack, Button, Typography, TextField, InputAdornment,
} from '@mui/material';
import './ProjectStatus.scss';
import { useState } from 'react';
import { getProjectBycustomerUId, getProjectCountByCustomerId, getProjectCountBycustomerUId, getSearchByCustomerAndStatus } from '../../services/ProjectService';
import dayjs from 'dayjs';
import OTPModal from '../otpGenerator/OtpGenerator';
import { getPercentage } from '../../services/TaskService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import SearchIcon from "@mui/icons-material/Search";
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';


interface Data {
    id: number;
    project: string;
    trade: string;
    manufacture: string;
    state: number;
    orderNo: number;
    estimate: any;

}
interface SortOrder {
    orderNo: string;
    projectName: string;
    trade: string;
    startDate: string;
    endDate: string;
    status: string;
    estimate: string;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const [sortOrder, setSortOrder] = useState<SortOrder>({
        orderNo: 'asc',
        projectName: 'asc',
        trade: 'asc',
        startDate: 'asc',
        endDate: 'asc',
        status: 'asc',
        estimate: 'asc',
    });

    return (
        <>
            <TableHead >
                <TableRow className=' tableHead'>
                    {/* <TableCell >#</TableCell> */}

                    <TableCell size='medium'>
                        Order No
                        {/* <IconButton size='small' onClick={() => handleSortClick('orderNo')}>
                            {sortOrder.orderNo === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton> */}
                    </TableCell>
                    <TableCell size='small'>
                        Project Name
                        {/* <IconButton size='small' onClick={() => handleSortClick('projectName')}>
                            {sortOrder.projectName === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton> */}
                    </TableCell>
                    {/* <TableCell size='small'>
                        Trade
                        <IconButton size='small' onClick={() => handleSortClick('trade')}>
                            {sortOrder.trade === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton>
                    </TableCell> */}
                    <TableCell size='small'>
                        Start Date
                        {/* <IconButton size='small' onClick={() => handleSortClick('startDate')}>
                            {sortOrder.startDate === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton> */}
                    </TableCell>
                    <TableCell size='small'>
                        End Date
                        {/* <IconButton size='small' onClick={() => handleSortClick('endDate')}>
                            {sortOrder.endDate === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton> */}
                    </TableCell>
                    <TableCell size='small'>
                        Estimate
                        {/* <IconButton size='small' onClick={() => handleSortClick('status')}>
                            {sortOrder.status === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton> */}
                    </TableCell>
                    <TableCell size='small' style={{ borderRight: '0px !important' }}>
                        Status
                        {/* <IconButton size='small' onClick={() => handleSortClick('estimate')}>
                            {sortOrder.estimate === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton> */}
                    </TableCell>
                    <TableCell size='small' style={{ borderRight: '0px !important' }}>
                        Action
                        {/* <IconButton size='small' onClick={() => handleSortClick('estimate')}>
                            {sortOrder.estimate === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton> */}
                    </TableCell>

                    {/*   <TableCell size='small'>Action</TableCell>*/}
                </TableRow>
            </TableHead>
        </>
    );
}

const ProjectStatus: React.FC = () => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('project');
    const [selected, setSelected] = React.useState<readonly number[]>([]);

    const [dense, setDense] = React.useState(false);
    const [count, setCount] = useState(Number);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [billList, setBillList] = React.useState([] as any[]);
    const [progress, setProgress] = React.useState(0);
    const [projects, setProjects] = useState<any>([]);
    const BIDPENDING = "bidPending";
    const BIDREVIEW = 'bidReview';
    const AWAITINGESTIMATION = 'awaitingEstimation';
    const PROJECTACTIVESTATUS = 'Project Active';
    const SALESPERSONAPPROVALSTATUS = 'Salesperson Approval';
    const CONTRACTUPLOAD = 'Contract Upload';
    const CONTRACTAPPROVAL = 'Contract Approval';
    const PAYMENTAPPROVAL = 'Payment Approval';
    const PROJECTINACTIVE = 'Project Inactive';
    const PROJECTCOMPLETE = "Project Complete"
    const PROJECTCLOSED   = "Project Closed";
    const CONSTRUCTIONCOMPLETE = "Construction Complete"
    const PAYMENT = 'Payment';
    const ONHOLD = "On Hold";
    const POGENERATION = 'PO Generation';
    const statuses = ["Contract Approval", "Contract Upload", "Salesperson Visit", 'Salesperson Approval', "Payment", "Project Active", "Payment Approval", "PO Generation", "Project Inactive"];
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [view, setView] = React.useState('');
    const [preview, setPreview] = React.useState();
    const [projectDetails, setProjectId] = useState<any>();

    // const onChange = async (event: any) => {
    //     const file = event.target.files[0];
    //     onSelectFile(file);
    // }





    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    console.log(accessTokenPayload.emails[0])
    const fetchProject = async () => {
        try {
            const res = await getProjectCountBycustomerUId(accessTokenPayload.oid, accessToken);
            setCount(res.data)
            const response = await getProjectBycustomerUId(accessTokenPayload.oid, page, rowsPerPage, accessToken);
            // const response = await getProjectBycustomerUId(accessTokenPayload.oid, accessToken);
            //console.log(response);
            if (response.data.length !== 0) {
                response.data.map(async (p: any) => {
                    const res = await getPercentage(p.id, accessToken);
                    setProgress(res.data);
                })
            }
            setProjects(response.data);
            setLoading(false);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // const fetchTask = async () => {

    //     const response = await getTasksBycustomerUIdAndStatus(accessTokenPayload.oid, 'sales', accessToken); // Replace with actual project ID and status
    //     //console.log(response)
    // };


    // Function to get the screen size
    // function getScreenSize() {
    //     const width = window.innerWidth;
    //     const height = window.innerHeight;

    //     return { width, height };
    // }

    // // Example usage:
    // const screenSize = getScreenSize();
    // console.log('Screen Width:', screenSize.width);
    // console.log('Screen Height:', screenSize.height);

    // const activeBreakpoint = useActiveGridBreakpoint();
    // console.log('Active Grid Breakpoint:', activeBreakpoint);





    React.useEffect(() => {

        fetchProject();
        // fetchTask();
        // fetchData();
        if (!selectedFile) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(selectedFile)
        setView(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile]);

    // const onSelectFile = (file: any) => {
    //     if (!file || file === 0) {
    //         setSelectedFile(undefined)
    //         return
    //     }

    //     // I've kept this example simple by using the first image instead of multiple
    //     setSelectedFile(file);
    // }

    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const handleOpenModal = (projectId: any) => {
        setProjectId(projectId)
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = billList.map((n: any) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = async (event: unknown, newPage: number) => {
        //const result = await getProjectBycustomerUId(accessTokenPayload.oid, newPage, rowsPerPage, accessToken);
        //console.log(result.data)
        //setProjects(result.data);
        const response = await getProjectBycustomerUId(accessTokenPayload.oid, newPage, rowsPerPage, accessToken);
        setProjects(response.data);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value);
        const newPage = Math.floor(page * rowsPerPage / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
        const result = await getProjectBycustomerUId(accessTokenPayload.oid, newPage, newRowsPerPage, accessToken);
        setProjects(result.data);
    };


    // const emptyRows = rowsPerPage - projects.length;

    const isSelected = (id: number) => selected.indexOf(id) !== -1;




    const navigate = useNavigate();
    const addContract = (project: any) => {
        console.log(project)
        navigate("/contract", {
            state: { project }
        });
    }

    const [value, setValue] = React.useState('');

    const handleTextChange = async (event: any) => {
        console.log(event.target.value)
        setValue(event.target.value);
        if (event.target.value.length >= 1) {
            const response = await getSearchByCustomerAndStatus(accessTokenPayload.oid, event.target.value, accessToken);
            // console.log(response.data);
            setProjects(response.data);
        }
        else {

            fetchProject();
        }
    };

    return (
        <>


            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid ><Header title="Status of my projects" subtitle="" /></Grid>


                <Box sx={{ width: '100%', }} >
                    <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
                        {/* <SearchBar /> */}
                        <br />
                        <br />
                        <Grid item md={12} ml={2}>
                            {/* <TextField id="outlined-basic" label="Search" variant="outlined" size='small' value={value} onChange={(e) => handleTextChange(e)} /> */}
                            <TextField
                                label="Search by status "
                                size='small'
                                name="materialName"

                                value={value}
                                onChange={(e) => handleTextChange(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <br />
                            <br />
                        </Grid>
                        <Grid item xs={12} >
                            <TableContainer  >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={billList.length}
                                    />
                                    <TableBody>
                                        {loading ? (
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography align="center">Loading... <CircularProgress size={20} /></Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <>
                                                {projects.length === 0 ?
                                                    (<TableRow>
                                                        <TableCell colSpan={10}>
                                                            <Typography align="center">No Active Projects</Typography>
                                                        </TableCell>
                                                    </TableRow>) :

                                                    (<> {projects.map((project: any, index: any) => {

                                                        const isItemSelected = isSelected(project?.id);
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return (

                                                            <TableRow
                                                                hover
                                                                onClick={(event) => handleClick(event, project?.id)}
                                                                role="checkbox"
                                                                aria-checked={isItemSelected}
                                                                tabIndex={-1}

                                                                key={project.id}
                                                                selected={isItemSelected}
                                                                sx={{ cursor: 'pointer' }}
                                                            >
                                                                {/* <TableCell padding="checkbox">
                                                        <CustomCheckbox
                                                            className='check'
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell> */}
                                                                <TableCell
                                                                    // className='tableRows'
                                                                    component="th"
                                                                    id={labelId}
                                                                    size='medium'
                                                                    scope="row"
                                                                    padding="none"

                                                                >
                                                                    <Stack className='orderNo ' sx={{ marginLeft: "6px !important" }}> 
                                                                        #{project?.orderNO || project?.jobNo}
                                                                        </Stack>
                                                                </TableCell>

                                                                <TableCell
                                                                    // className='tableRows'
                                                                    component="th"
                                                                    id={labelId}
                                                                    size='medium'
                                                                    scope="row"
                                                                    padding="none"
                                                                    sx={{ textAlign: "center" }}
                                                                >
                                                                    {project?.trade}
                                                                    {/* {project?.status === 'Project Active' && (
                                                                <Box mt={1}> <LinearProgress variant="determinate" value={progress} /></Box>
                                                            )}
                                                            {project?.status === 'Salesperson Visit' && (
                                                                <Box mt={1}>
                                                                    <Stack className='checkoutDetails' >
                                                                        Associate will see you soon.
                                                                    </Stack>
                                                                </Box>
                                                            )} */}
                                                                    {project?.status === 'Salesperson Approval' && (
                                                                        <Box mt={1}>
                                                                            {/* <Stack className='checkoutConfirm' >
                                                                        Team member verified.
                                                                    </Stack> */}
                                                                            {/* <Stack className='otpVerify' >
                                                                        <Button variant="contained" size="small" onClick={() => handleOpenModal()}>Enter OTP</Button>
                                                                    </Stack> */}
                                                                            {/* <OTPModal props={project.id} open={isModalOpen} onClose={handleCloseModal} /> */}

                                                                        </Box>
                                                                    )}
                                                                </TableCell>
                                                                {/* <TableCell size='medium'> {project?.trade}</TableCell> */}
                                                                <TableCell size='medium'>{dayjs(project?.startDate || project?.
                                                                    jobStartdate
                                                                ).format('DD MMM YYYY')}</TableCell>
                                                                <TableCell size='medium'>
                                                                    {(project?.endDate === null && project?.jobEnddate === null) || 
                                                                    (project?.endDate === null && project?.jobEndDate === null)? 
                                                                    "---"
                                                                    :
                                                                    dayjs(project?.endDate || project?.jobEnddate).format('DD MMM YYYY')}
                                                                    </TableCell>
                                                                <TableCell size='medium' 
                                                                // align="right"
                                                                >
                                                                    {(project?.totalCost === null && project?.projectCost === undefined) ||
                                                                    (project?.totalCost === null && project?.projectCost === null)?
                                                                        "---"
                                                                        :
                                                                        <Stack className = 'estimateCost'>
                                                                            ${project?.totalCost || project?.projectCost}
                                                                        </Stack>
                                                                    }
                                                                </TableCell>
                                                                <TableCell size='medium'>
                                                                    {(project?.status === BIDREVIEW || project?.status === BIDPENDING || project?.status === AWAITINGESTIMATION || project?.status === PROJECTINACTIVE ) && (<Stack className='statusvisit'>{project?.status}</Stack>
                                                                    )}
                                                                    {(project?.status === PROJECTACTIVESTATUS || project?.status === PROJECTCOMPLETE) && (<Stack className=' activeProject' >{project?.status}</Stack>
                                                                    )}
                                                                    {(project?.status === SALESPERSONAPPROVALSTATUS ||
                                                                   project?.status === PROJECTCLOSED || project?.status ===  CONSTRUCTIONCOMPLETE)&& (<Stack className=' salesApproval '>{project?.status}</Stack>
                                                                    )}
                                                                    {project?.status === CONTRACTAPPROVAL && (<Stack className=' contractStatus '>{project?.status}</Stack>
                                                                    )}
                                                                    {project?.status === CONTRACTUPLOAD && (<Stack className=' contractStatus '>{project?.status}</Stack>
                                                                    )}
                                                                    {project?.status === PAYMENTAPPROVAL && (<Stack className=' payment '>{project?.status}</Stack>
                                                                    )}
                                                                    {project?.status === PAYMENT && (<Stack className=' payment '>{project?.status}</Stack>
                                                                    )}
                                                                    {project?.status === POGENERATION && (<Stack className=' payment '>{project?.status}</Stack>
                                                                    )}
                                                                    {project?.status === ONHOLD && (<Stack className=' statusvisit' >{project?.status}</Stack>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell size='medium'>
                                                                    {project?.status === SALESPERSONAPPROVALSTATUS && (<Button variant="contained" size="small" onClick={() => handleOpenModal(project.id)}>Enter OTP</Button>)}
                                                                    {(project?.status === CONTRACTUPLOAD) && (<Button variant="contained" size="small" onClick={() => addContract(project)}>Contract</Button>)}
                                                                </TableCell>
                                                                {/* {/* <TableCell  size='medium' >  <Stack >
                                                            <Grid>
                                                                <input
                                                                    ref={inputRef}
                                                                    type="file"
                                                                    onChange={handleFileChange}
                                                                    style={{ display: "none" }}
                                                                />
                                                                {!selectedFile && (
                                                                    <Button  onClick={onChooseFile}>
                                                                       Upload Contractor
                                                                    </Button>
                                                                )}
                                                                {selectedFile && (
                                                                                                                                       <>
                                                                        <Grid className="file-card POCard">
                                                                            <Grid className="file-info">
                                                                            {uploadStatus === "select" && (
                                                                                    <Typography className="fileDetails" style={{ flex: 1 }}>
                                                                                        <Typography className="progress-bg">
                                                                                            <Typography className="progress" style={{ width: `${progress}%` }} />
                                                                                        </Typography>
                                                                                    </Typography>
                                                                                )} */}

                                                            </TableRow>

                                                        )
                                                    })}</>)
                                                }
                                            </>)}
                                        {/* {emptyRows > 0 && (
                                            Array.from(Array(emptyRows)).map((_, index) => (
                                                <TableRow key={`empty-row-${index}`}>
                                                    <TableCell colSpan={7} style={{border:'none'}}/>
                                                </TableRow>
                                            ))
                                        )} */}
                                    </TableBody>
                                </Table>
                                {/* <TablePagination
                                sx={{ border: 'none', }}
                                showFirstButton={false}
                                showLastButton={false}
                                rowsPerPageOptions={[]}
                                component="div"
                                count={projects.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                            </TableContainer>
                            {/* <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={projects.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Paper>
                </Box>
                <OTPModal props={projectDetails} projects={setProjects} open={isModalOpen} onClose={handleCloseModal} />
            </Grid>

        </>
    );
}


export default ProjectStatus;