export const sampleVendorData = [
  {
    "description":"sample",
    "Mobile Number": "555-123-4567",
    "Vendor Display Name": "Acmess Corp",
    "Company": "Acme Corporation",
    "Account": 123456,
    "Street": "123 Main St",
    "City": "Los Angeles",
    "Country": "USA",
    "State": "CA",
    "ZIP": 90001,
    "Email": "acme@example.com",
    "Created By": "acme",
    "Lead Time": 1,
    "Services": "Garbage Collector",
    "Terms": "Net 30"
  },
  {
   "description":"sample",
    "Mobile Number": "555-987-6543",
    "Vendor Display Name": "John's Supplies",
    "Company": "John's Supply Co",
    "Account": 654321,
    "Street": "456 Market St",
    "City": "New York",
    "Country": "USA",
    "State": "NY",
    "ZIP": 10002,
    "Email": "john@example.com",
    "Created By": "john",
    "Lead Time": 2,
    "Services": "Garbage Collector",
    "Terms": "Net 45"
  },
  {
    "description":"sample",
    "Mobile Number": "555-555-5555",
    "Vendor Display Name": "Doe Industries",
    "Company": "Doe Industries Ltd",
    "Account": 789123,
    "Street": "789 Industrial Rd",
    "City": "Chicago",
    "Country": "USA",
    "State": "IL",
    "ZIP": 60601,
    "Email": "doe@example.com",
    "Created By": "doe",
    "Lead Time": 3,
    "Services": "Garbage Collector",
    "Terms": "Net 60"
  }
 ]