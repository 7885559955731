/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 11-Dec-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    Dashboard 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |      AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*        2235    |  14/06/24  |  Vanitha    |  Widget ui changes 
*        2235    |  21/06/24  |  Vanitha    |  Widget ui changes for sold contract , ytd sales and bid out totals 

*
*
*/
import {
  useTheme,
  useMediaQuery,
  styled, Paper,
  Grid, Card,
  CardHeader, Box,
  Icon,
  Typography,
  CardContent,
  Stack,
} from "@mui/material";

import Header from "../components/Header";
import { tokens } from "../hooks/theme";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Chart } from "react-google-charts";
// import { data, options } from "../data/bidsOutTotal";
import { soldContractsoptions } from "../data/soldContracts";
import { UserContext, getUserSessionDetails } from "../hooks/UserSession";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBidCount, getEstimateByMonth, getEstimateByYear } from "../services/BidService";
import { bidsThisYearoptions } from "../data/bidsthisyear";
import { getClosingRatio, getCompletedProjectCount, getProjectEstimateByMonth, getProjectEstimateByYear, getProjectTotalCost } from "../services/ProjectService";
import { getIcon } from "../utils/DateFormatter";
import { getTenantReport } from "../services/TenantService";
import SalesTeamProficiencyRates from "../components/companydashboard/SalesTeamProfeciencyRates";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));


const Dashboard = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState<any>([]);
  const [chart, setChart] = useState<any>([]);
  const [count,setCount]=useState(Number);
  const [closingRatio,setClosingRatio]=useState(Number);
  const [projectTotalCost,setProjectTotalCost]=useState(Number);
  const [bidCount,setBidCount]=useState(Number);
  const [processData, setProcessData] = useState<any>([] as any[]);
  const [soldContractsData, setSoldContractsData] = useState<any>([]);
  const [bidOutData, setBidOutData] =useState<any>([]);

  const status = ["Salesperson Visit","Contract Approval", "Salesperson Approval"];
  const cartStatus = ["bidPending", "bidReview", "awaitingEstimation", "Contract Approval", "Contract Upload"]

  useEffect(() => {
    fetchBidEstimateByYear();
    fetchProjectEstimateByYear();
    fetchProjectEstimateByMonth();
    fetchBidEstimateByMonth();
    ProductionReport();
  }, [accessToken]);


  const { userSessionDetails, setUserDetails } = useContext(UserContext);
  var userRolePermissions: any;
  var userRole: any;
  let userPermissions: Array<any> = [];

  userRolePermissions = userSessionDetails;
  if (userRolePermissions !== null && userRolePermissions !== undefined) {
    userRole = userRolePermissions.role;
    if (userRole !== null && userRole.userRolePermissions !== null) {
      userPermissions = userRole.userRolePermissions;
    }
  }

  if (!accessToken) {
    return <p>You are not authenticated</p>;
  }


  const fetchProjectEstimateByMonth = async () => {
    try {
      const res = await getProjectEstimateByMonth(accessToken, status);
      console.log('res', res.data);
      if (!res || !res.data) {
        setSoldContractsData([
          ["Month", "Total Cost"],
          ["No Data", 0, "No Data"],
        ]);
        return;
      }

      const dataFromApi = res.data;

      if (!Array.isArray(dataFromApi) || dataFromApi.length === 0) {
        setSoldContractsData([
          ["Month", "Total Cost"],
          ["No Data", 0],
        ]);
        return;
      }

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const groupedData = {};

      dataFromApi.forEach(item => {
        const date = new Date(item.createdAt);
        const month = monthNames[date.getMonth()];
        if (!groupedData[month]) {
          groupedData[month] = [];
        }
        groupedData[month].push(item.totalCost);
      });

      const formattedData = [
        ["Month", "Total Cost"]
      ];
      for (const month in groupedData) {
        const totalCost = groupedData[month].reduce((acc, cost) => acc + cost, 0);
        formattedData.push([month, totalCost]);
      }

      setSoldContractsData(formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setSoldContractsData([
        ["Month", "Total Cost"],
        ["No Data", 0],
      ]);
    }
  };

  const fetchBidEstimateByMonth = async () => {
    try {
    const res = await getEstimateByMonth(accessToken, cartStatus);
    console.log('res',res.data);
    if (!res || !res.data) {
      setBidOutData([
        ["Month","Total Cost"],
        ["No Data", 0],
      ]);
      return;
    }
  
    const dataFromApi = res.data;
  
    if (!Array.isArray(dataFromApi) || dataFromApi.length === 0) {
      setBidOutData([
        ["Month","Total Cost"],
        ["No Data", 0],
      ]);
      return;
    }
  
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const groupedData = {};
  
    // Group data by month
    dataFromApi.forEach(item => {
      const date = new Date(item.createdAt);
      const month = monthNames[date.getMonth()];
      if (!groupedData[month]) {
        groupedData[month] = [];
      }
      groupedData[month].push(item.totalCost);
    });
  
    // Format data for Google Charts
    const formattedData = [
      ["Month", "Total Cost"]
    ];
    for (const month in groupedData) {
      const totalCost = groupedData[month].reduce((acc, cost) => acc + cost, 0);
      formattedData.push([month, totalCost]);
    }
  
    setBidOutData(formattedData);
  } catch (error) {
    console.error('Error fetching data:', error);
    setBidOutData([
      ["Month", "Total Cost"],
      ["No Data", 0],
    ]);
  } 
  };
 
  const fetchBidEstimateByYear = async () => {
    try {
      const response = await getEstimateByYear(accessToken);
      const bidCount = await getBidCount(accessToken);
      setBidCount(bidCount.data);
      if (!response || !response.data) {
        setChartData([["Name", "Total Cost"], ["No Data", 0]]);

        return;
      }

      const data = response.data;

      if (!Array.isArray(data) || data.length === 0) {
        setChartData([["Name", "Total Cost"], ["No Data", 0]]);
        return;
      }

      const formattedData = [["Name", "Total Cost"], ...data.map(item => [item.name, item.totalCost])];
      setChartData(formattedData);
    } catch (error) {
      setChartData([["Name", "Total Cost"], ["No Data", 0]]);
    }
  };



  const fetchProjectEstimateByYear = async () => {
    try {
      const result = await getProjectEstimateByYear(accessToken);
      const count =await getCompletedProjectCount(accessToken);
      console.log(count)
      setCount(count.data);
      const closingRatio = await getClosingRatio(accessToken);
      setClosingRatio(closingRatio.data);
        
      const projectTotalcost = await getProjectTotalCost(accessToken);

      setProjectTotalCost(projectTotalcost.data);

      if (!result || !result.data) {
        setChart([["Name", "Total Cost"], ["No Data", 0]]);
        return;
      }

      const res = result.data;
      console.log('data', res);

      if (!Array.isArray(res) || res.length === 0) {
        setChart([["Name", "Total Cost"], ["No Data", 0]]);
        return;
      }

      const barChart = [["Name", "Total Cost"], ...res.map(item => [item.name, item.totalCost])];
      setChart(barChart);
    } catch (error) {
      setChart([["Name", "Total Cost"], ["No Data", 0]]);
    }
  };

  const ProductionReport = async () => {
    const res = await getTenantReport(accessToken);
    console.log(res)
    if(res !== null && res !== undefined) {
      setProcessData(res.data);
    }
    // console.log('res', res.data);
    // setProcessData(res.data);
  }

  const func = (data: any) => {
    if (data.value === data.currentTrend) {
      return getIcon("Green");
    } else if (data.value > data.currentTrend) {
      return getIcon("Red");
    } else if (data.value > data.currentTrend && data.value < (data.threshold + data.currentTrend)) {
      return getIcon("Orange");
    } else if (data.value < data.currentTrend || data.value === 0) {
      return getIcon("Blue");
    } else {
      return getIcon("Grey");
    }
  }

  return (
    <>
      {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
            <Item>{accessTokenPayload != null && (
              <p className="card-text">OID : {accessTokenPayload.oid}</p>
            )}
            </Item>
          </Grid>
      </Grid> */}


      <Box>
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header title=" COMPANY DASHBOARD" subtitle="" />
        </Box>

        <Grid item xs={12} sm={12} md={6} lg={2} xl={2} sx={{ marginBottom: '2vh' }}>
          <Box>
            <Card sx={{ padding: '2vh' }}>
              <Stack direction={'row'} spacing={'auto'}>
                {/* <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Card className="YTD" >
                    <CardContent id='leads' style={{ backgroundColor: '#7367F629', color: '#7367F6' }}>
                      <Icon className="settingicon">
                        <SettingsOutlinedIcon className="dashboardicon" />
                      </Icon>
                    </CardContent>
                    <Stack direction='column'>
                      <Typography className="ytd-text">YTD Leads</Typography>
                      <Typography className="total-no">777</Typography>
                    </Stack>
                  </Card>
                </Grid> */}
                <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Card className="YTD">
                    <CardContent id='leads' style={{ backgroundColor: '#FF4C5129', color: '#FF4C51' }}>
                      <Icon className="settingicon">
                        <ThumbUpAltOutlinedIcon className="dashboardicon" />
                      </Icon>
                    </CardContent>
                    <Stack direction='column'>
                      <Typography className="ytd-text">YTD Bids Out</Typography>
                      <Typography className="total-no">{bidCount}</Typography>
                    </Stack>

                  </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Card className="YTD">
                    <CardContent id='leads' style={{ backgroundColor: '#28C76F29', color: '#28C76F' }}>
                      <Icon className="settingicon">
                        <ShoppingCartOutlinedIcon className="dashboardicon" />
                      </Icon>
                    </CardContent>
                    <Stack direction='column'>
                      <Typography className="ytd-text">YTD Sales</Typography>
                      <Typography className="total-no">{count}</Typography>
                    </Stack>

                  </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Card className="YTD">
                    <CardContent id='leads' style={{ backgroundColor: '#00BAD129', color: '#00BAD1' }}>
                      <Icon className="settingicon">
                        <GroupOutlinedIcon className="dashboardicon" />
                      </Icon>
                    </CardContent>
                    <Stack direction='column'>
                      <Typography className="ytd-text">Closing Ratio</Typography>
                      <Typography className="total-no">{closingRatio}%</Typography>
                    </Stack>

                  </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Card className="YTD">
                    <CardContent id='leads' style={{ backgroundColor: '#00BAD129', color: '#00BAD1' }}>
                      <Icon className="settingicon">
                        <GroupOutlinedIcon className="dashboardicon" />
                      </Icon>
                    </CardContent>
                    <div className="ytd-text">
                      <div>Forecasted Sales</div>
                      <div className="total-no">${projectTotalCost}</div> </div>
                  </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
                  <Card className="YTD">
                    <CardContent id='leads' style={{ backgroundColor: '#00BAD129', color: '#00BAD1' }}>
                      <Icon className="settingicon">
                        <GroupOutlinedIcon className="dashboardicon" />
                      </Icon>
                    </CardContent>
                    <div className="ytd-text">
                      <div>Sales ToTarget Ratio</div>
                      <div className="total-no">12 %</div> </div>
                  </Card>
                </Grid>
              </Stack>

            </Card>

          </Box>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {/* <Box>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Card className="YTD" >
              <CardContent id='leads' style={{ backgroundColor: '#18adb3', color: 'white' }}>
                <Icon className="settingicon">
                  <SettingsIcon className="dashboardicon" />
                </Icon>
              </CardContent>
              <Stack direction='column'>
                <Typography className="ytd-text">YTD Leads</Typography>
                <Typography className="total-no">777</Typography>
              </Stack>
             
            </Card>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Card className="YTD">
              <CardContent id='leads' style={{ backgroundColor: '#DC3545', color: 'white' }}>
                <Icon className="settingicon">
                  <ThumbUpIcon className="dashboardicon" />
                </Icon>
              </CardContent>
              <Stack direction='column'>
                <Typography className="ytd-text">TD Bids Out</Typography>
                <Typography className="total-no">760</Typography>
              </Stack>

            </Card>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Card className="YTD">
              <CardContent id='leads' style={{ backgroundColor: '#28A745', color: 'white' }}>
                <Icon className="settingicon">
                  <ShoppingCartIcon className="dashboardicon" />
                </Icon>
              </CardContent>
              <Stack direction='column'>
                <Typography className="ytd-text">YTD Sales</Typography>
                <Typography className="total-no">333</Typography>
              </Stack>

            </Card>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Card className="YTD">
              <CardContent id='leads' style={{ backgroundColor: '#FFC107', color: '#000000' }}>
                <Icon className="settingicon">
                  <GroupsIcon className="dashboardicon" />
                </Icon>
              </CardContent>
              <Stack direction='column'>
                <Typography className="ytd-text">Closing Ratio</Typography>
                <Typography className="total-no">20 %</Typography>
              </Stack>

            </Card>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Card className="YTD">
              <CardContent id='leads' style={{ backgroundColor: '#FFC107', color: '#000000' }}>
                <Icon className="settingicon">
                  <GroupsIcon className="dashboardicon" />
                </Icon>
              </CardContent>
              <div className="ytd-text">
                <div>Forecasted Sales</div>
                <div className="total-no">$2,400,728</div> </div>
            </Card>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={2} xl={2}>
            <Card className="YTD">
              <CardContent id='leads' style={{ backgroundColor: '#FFC107', color: '#000000' }}>
                <Icon className="settingicon">
                  <GroupsIcon className="dashboardicon" />
                </Icon>
              </CardContent>
              <div className="ytd-text">
                <div>Sales ToTarget Ratio</div>
                <div className="total-no">12 %</div> </div>
            </Card>
          </Grid>
          </Box> */}




          <Grid container xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid xs={true}>
              <Card className="productionReport">
                <CardHeader className="cardHead"
                  //   avatar={<Avatar>N</Avatar>}
                  title={
                    <Typography component="h6" className="cardTitle1" variant="h6" style={{ paddingLeft: 0, height: '15px', fontSize: '17px' }}>
                      Production Report
                    </Typography>
                  }
                />
                {/* <CardMedia className="product_image"
                component="img"
                image={production_report}
                alt="Your image title"
                height="200"
              /> */}
                <CardContent>
                  {processData?.map((item: any) => (
                    <Grid container alignItems='center' justifyContent='center' >
                      <Grid item xs={6} lg={3} xl={1}><img src={require('../assets/images/estimate.png')} alt='mySvgImage' height='120px' width='120px' /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={item.name === 'estimate' ? func(item) : getIcon('Grey')} alt='mySvgImage' className="iconcenter" /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={require('../assets/images/sales.png')} alt='mySvgImage' height='120px' width='120px' /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={item.name === 'sales' ? func(item) : getIcon('Grey')} alt='mySvgImage' className="iconcenter" /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={require('../assets/images/preproduction.png')} alt='mySvgImage' height='120px' width='120px' /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={item.name === 'preConstruction' ? func(item) : getIcon('Grey')} alt='mySvgImage' className="iconcenter" /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={require('../assets/images/activeconstruction.png')} alt='mySvgImage' height='120px' width='120px' /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={item.name === 'activeConstruction' ? func(item) : getIcon('Grey')} alt='mySvgImage' width='60px' className="iconcenter" /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={require('../assets/images/postproduction.png')} alt='mySvgImage' height='120px' width='120px' /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={item.name === 'postConstruction' ? func(item) : getIcon('Grey')} alt='mySvgImage' className="iconcenter" /></Grid>
                      <Grid item xs={6} lg={3} xl={1}><img src={require('../assets/images/billing.png')} alt='mySvgImage' height='120px' width='120px' /></Grid>
                      <Grid item xs={6} lg={3} xl={1}></Grid>
                    </Grid>
                  ))}
                </CardContent>
                {/* <Grid container>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={1}><img src={getIcon("Green")} alt='mySvgImage' height='60px' width='60px' style={{ transform: 'rotate(270deg)' }} /></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                </Grid> */}
                {/* <Grid container>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={1}></Grid>
                  <Grid item xs={6} lg={3} xl={3}><img src={require('../assets/images/sales.png')} alt='mySvgImage' height='80px' width='80px' /></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                </Grid> */}
                {/* <Grid container>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={1}></Grid>
                  <Grid item xs={6} lg={3} xl={3}><img src={require('../assets/images/arrowgreen.png')} alt='mySvgImage' height='60px' width='60px' style={{ transform: 'rotate(0deg)' }} /></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                </Grid> */}
                {/* <Grid container>
                  <Grid item xs={6} md={4} lg={3} xl={2}></Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}><img src={require('../assets/images/preproduction.png')} alt='mySvgImage' height='75px' width='75px' /></Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}><img src={getIcon("Blue")} alt='mySvgImage' height='60px' width='60px' style={{ transform: 'rotate(270deg)' }} /></Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}><img src={require('../assets/images/activeconstruction.png')} alt='mySvgImage' height='75px' width='75px' /></Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}><img src={getIcon("Orange")} alt='mySvgImage' height='60px' width='60px' style={{ transform: 'rotate(270deg)' }} /></Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}><img src={require('../assets/images/postproduction.png')} alt='mySvgImage' height='75px' width='75px' /></Grid>
                </Grid> */}
                {/* <Grid container>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}></Grid>
                  <Grid item xs={6} lg={3} xl={2}><img src={getIcon("Red")} alt='mySvgImage' height='60px' width='60px' style={{ transform: 'rotate(0deg)' }} /></Grid>

                </Grid> */}
                {/* <Grid container mb={2}>
                  <Grid item xs={6} lg={3} xl={1}>
                    {/* <div className="arrow_line"></div> */}
                {/* <div className="arrow_point"></div>  */}
                {/* <div className="line-vertical arrow-up"></div> 
                    
                  </Grid>
                  <Grid item xs={6} lg={3} xl={9}><img src={require('../assets/images/longarrow.png')} alt='mySvgImage' height='80px' width='100%' /><hr className="linearrow" /></Grid>
                  <Grid item xs={6} lg={3} xl={1}></Grid>
                </Grid> */}


              </Card>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
            <Grid xs={true}>
              <Card className="soldOutTotals" >
                <CardHeader className="cardHead"
                  //   avatar={<Avatar>N</Avatar>}
                  title={
                    <Typography component="h6" className="cardTitle1" variant="h6" style={{ paddingLeft: 0, height: '15px', fontSize: '17px' }}>
                      Bids out totals
                    </Typography>
                  }
                />
                <CardContent>
                  <Chart className="barChart"
                    chartType="BarChart"
                    width="auto"
                    height="400px"
                    data={bidOutData}
                    options={soldContractsoptions}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={true}>
              <Card className="soldOutTotals">
                <CardHeader className="cardHead"
                  //   avatar={<Avatar>N</Avatar>}
                  title={
                    <Typography component="h6" className="cardTitle1" variant="h6" style={{ paddingLeft: 0, height: '15px', fontSize: '17px' }}>
                      Sold Contracts
                    </Typography>
                  }
                />
                <CardContent>
                  <Chart className="barChart"
                    chartType="BarChart"
                    width="auto"
                    height="400px"

                    data={soldContractsData}
                    options={soldContractsoptions}
                  // chartContainerStyle={{ border: '1px solid black' }}
                  />
                </CardContent></Card>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
            <Grid xs={true}>
              <Card className="bidsOutTotals">
                <CardHeader className="cardHead"
                  //   avatar={<Avatar>N</Avatar>}
                  title={
                    <Typography component="h6" className="cardTitle1" variant="h6" style={{ paddingLeft: 0, height: '15px', fontSize: '17px' }}>
                      Bid this year
                    </Typography>
                  }
                />
                <CardContent>
                  <Chart className="barChart"
                    chartType="BarChart"
                    width="auto"
                    height="400px"
                    data={chartData}
                    options={bidsThisYearoptions}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={true}>
              <Card className="bidsOutTotals">
                <CardHeader className="cardHead"
                  //   avatar={<Avatar>N</Avatar>}
                  title={
                    <Typography component="h6" className="cardTitle1" variant="h6" style={{ paddingLeft: 0, height: '15px', fontSize: '17px' }}>
                      Sold this year
                    </Typography>
                  }
                />
                <CardContent>
                  <Chart className="barChart"
                    chartType="BarChart"
                    width="auto"
                    height="400px"
                    data={chart}
                    options={bidsThisYearoptions}
                  // chartContainerStyle={{ border: '1px solid black' }}
                  />
                </CardContent> </Card>
            </Grid>
            {/* <Grid xs={true}>
              <Card className="bidsOutTotals progressBarContainer" id="ProgressBar" sx={{ minHeight: "300px" }}>
                <CardHeader className="cardHead"
                  //   avatar={<Avatar>N</Avatar>}
                  title={
                    <Typography component="h6" className="cardTitle1" variant="h6" style={{ paddingLeft: 0, height: '15px', fontSize: '17px' }}>
                      Customer Satisfaction
                    </Typography>
                  }
                />
                <CardContent>
                  <CircularProgressBar progressValues={[80]} width={170} height={160} />
                </CardContent>
              </Card>
            </Grid> */}
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
              <Grid xs={true}>
                <Card className="soldOutTotals" id="soldOutTotal">
                  <CardHeader className="cardHead"
                    //   avatar={<Avatar>N</Avatar>}
                    title={
                      <Typography component="h6" className="cardTitle1" variant="h6" style={{ paddingLeft: 0, height: '15px', fontSize: '17px' }}>
                        Sales Team Proficiency Rates
                      </Typography>
                    }
                  />
                  <SalesTeamProficiencyRates />
                </Card>
              </Grid>
            </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default Dashboard;
