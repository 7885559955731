/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1506    26/3/24     Priyankaa    Checkout success dialog navigate issue
* 1530           |  2/4/24    |   Nandha      |  Checkout calender dialg not closed after booking successfully
* 1767           |  02/04/24   | shiyam Kumar  |  Salesperson Create Bid Issue
*/
import { useEffect, useState } from 'react';
import {
  DialogContent, DialogContentText, DialogActions, Button,
  DialogTitle, Dialog, Box, Grid, Typography, Stack, Backdrop
} from '@mui/material';
import SalesmanCalendar from '../calendar/SalesmanCalendar';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import React from 'react';
import dayjs from 'dayjs';
import './SalesBookingCheckot.scss'
import { bookSalesPerson, checkout, getProjectByJobno, getSalesPersonName } from '../../services/ProjectService';
import gmslogo from "../../assets/logo.gif";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { getResourceByUid } from '../../services/ResourceService';
import { TaskContext, UserContext } from '../../hooks/UserSession';




const SalesBookingCheckout: React.FC<any> = (props) => {
  console.log(props);
  const { onClose, open } = props;
  const [openSignup, setOpenSignup] = useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [resource, setResource] = React.useState<any>(null);
  const [assignee, setAssignee] = React.useState<any>(null);
  // const [user, setUser] = React.useState<any>(props.user);
  const { isTaskCompleted, handleCompleted } = React.useContext(TaskContext);
  const { userSessionDetails, setUserDetails } = React.useContext(UserContext);

  useEffect(() => {
    // Assuming props.selectedDate is a valid date

    // getUserSession(accessToken)
    //   .then(res => {
    //     setUser(res);
    //     console.log(res);
    //   })
    // setUser(props.user);

    const selectedDate = dayjs(props.selectedDate).isValid() ? dayjs(props.selectedDate).format("DD MMM YYYY") : dayjs(props.bid.salesStartDate).format("DD MMM YYYY");
    setSelectedData(selectedDate);

    // const fetchData = async () => {
    //   try {
    //    console.log(props.bid.assignedTo);
    // const response = await getProjectByJobno(props.bid.bidNo, accessToken);
    //    console.log(response);
    //     const result = await getResourceByUid(assignee, accessToken);
    //     setResource(result.data.name);
    //     console.log(result);
    //   } catch (error) {
    //     console.error("Error fetching resource:", error);
    //   }
    // };

    // fetchData();

    // console.log(props.bid.assignedTo);



  }, [props.selectedDate, props.bid.assignedTo]);

  const checkoutDetails = async () => {
    try {
      setLoading(true);
      setIsLoading(true)
      var result: any;
      let bidData = props.bid;
      console.log(props?.selectedDate, selectedData, props.bid.salesStartDate)
      //console.log(props.bid)
      // bidData['selectedDate'] = dayjs(props?.selectedDate).format('YYYY-MM-DDTHH:mm:ss[Z]');
      if (userSessionDetails.role.name === "sales") {
        bidData['sales'] = userSessionDetails.objectId;
        bidData['status'] = 'bidReview';

        if (bidData.selectedDate === null || props?.selectedDate === "Invalid Date") {
          bidData['selectedDate'] = props.bid.salesStartDate;
        }
      }
      if (bidData.selectedDate === null || props?.selectedDate === "Invalid Date") {
        bidData['selectedDate'] = props.bid.salesStartDate;
      }
      console.log(bidData)
      result = await bookSalesPerson(bidData, accessToken);
      //console.log(result);
      if (result.status === 200 || result.status === 201) {
        try {
          const response = await getSalesPersonName(result.data, accessToken);
          setResource(response.data);
        } catch (error) {
          console.log(error);
        }

        handleCompleted();
        setLoading(false);
        setIsLoading(false);
        setOpenToast(true);
        // setToastMessage({ ...toastMessage, message: "checkout Successfully", security: "success" });
        setSuccess(true)
        //window.location.reload();
      }
      else {
        setLoading(false)
        setIsLoading(false);
        setOpenToast(true);
        // setToastMessage({ ...toastMessage, message: "error", security: "error" });
        setError(true)
      }
      // console.log(result)
    } catch (error: any) {
      // console.log(error)
    }

  };

  const handleClose = () => {
    onClose();
  }

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };
  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSuccess(false);
    setError(false);
    onClose();
    window.location.href = "/salesestimate";

  }


  return (
    <>
      <Dialog open={success} >
        <DialogTitle className='success'>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
              Checkout Successful
            </Typography>
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}> Sales associate {resource} will contact you shortly</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={error} >
        <DialogTitle className='error'>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
              Error
            </Typography>
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Error Couldn't Checkout</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
          </DialogActions>
        </Box>

      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        // onClose={handleClose}
        //onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={handleClose}
        >
          <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
        </Backdrop>
        <DialogContent sx={{ backgroundColor: "#f8f7fa" }} >
          <DialogContentText>

            <Grid container justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>

              <Typography justifyContent="center" alignItems="center" className='salesBookingDetails' m={3}>
                <Stack className='salesHead otpGeneraterHead' mb={2} ><img src="https://www.logolynx.com/images/logolynx/48/48a1eadd8a123428fd88adeb3832e89c.png" className='otpsalesBooking' /></Stack>
                <Stack justifyContent='center' >You Booked an Associate on this date</Stack>
                <Stack justifyContent='center' className='selectedDate'>{selectedData}</Stack>
              </Typography>

            </Grid>


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">Cancel</Button>
          <Button onClick={checkoutDetails} color="primary">Checkout</Button>
        </DialogActions>
      </Dialog>

      <SalesmanCalendar props={selectedData} open={openSignup} handleClose={() => setOpenSignup(false)} />

    </>
  );
}


export default SalesBookingCheckout;

