/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Karthikeyan.A
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1231     dec-11,2023     xyz buildGetResult function implementation
* 2235           |  10/06/24   |   Vanitha     | Most Lead and Bid Total Esitmate for month display implementation
* 2235           |  27/06/24   |   Vanitha     | Most Lead Trade widget implementation
*
*
*/

import * as React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import "./Salescard.scss"

import { Chart } from "react-google-charts";



import bargraph from '../asset/images/sales/bar-graph.svg';
import Weathecard from '../gps/Weathecard';
import { Stack } from '@mui/system';
import { getBidStatus, getBidTotalEstimate } from '../../services/BidService';
import { useEffect, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getMostLeadTrade, getEmployeeTerget } from '../../services/ProjectService';
import { FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { UserContext } from '../../hooks/UserSession';
import { PieChart } from '@mui/x-charts/PieChart';
import { getResourcesByType } from '../../services/ResourceService';


const Item = styled('div')(({ theme }) => ({
    //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //   border: '1px solid',
    //   borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    //   padding: theme.spacing(1),
    //   borderRadius: '4px',
    //   textAlign: 'center',
}));



export const options = {
    title: "Bid Status",
    bar: { groupWidth: "50%" },
    legend: { position: "none" },
    bars: 'vertical',
    hAxis: {
        title: "Bids",
        minValue: 'none',
        color: '#fff'
    },
    vAxis: {
        textPosition: "none",
        gridlines: { count: 0 },
        titleTextStyle: {
            color: '#fff',
        }
    },
    baselineColor: "transparent",
};


interface WeatherProps {
    coordinates: string;
    projectId: any;
}

interface EmployeeStatistics {
    empName: string;
    empRole: string;
    totalAmount: number;
    targetAmount: number;
}

const Salescard: React.FC<WeatherProps> = () => {

    const { accessToken } = useOidcAccessToken();
    const [bidData, setBidData] = useState<any>(null);
    const [tradeData, setTradeData] = useState<any>(null);
    const [selectedCard, setSelectedCard] = useState<any>();
    const [salectedPerson, setSelectedPerson] = useState<any>('');
    const [salespersons, setSalesPersons] = useState<any>([]);
    const [count, setCount] = useState<any>(0);
    const [targetDetails, setTargetDetails] = useState<any>();
    const [seletedToggle, setSelectedToggle] = useState<any>('year');
    const [selectedData, setSelectedData] = useState<any>({});
    const [selectedEmployee, setSelectedEmployee] = useState<EmployeeStatistics | null>(null);
    const [role, setRole] = useState<string>(''); // Add state for user role
    const status = ["Salesperson Visit", "Contract Approval", "Salesperson Approval"];
    const cartStatus = ["bidPending", "bidReview", "awaitingEstimation", "Contract Approval", "Contract Upload"]
    let barChartData = ["3-5 days out", "10 days out", "15-10 days out", "20 days out"];




    const [chartData, setChartData] = useState([
        [
            "Element",
            "Bid Out",
            { role: "style" },
            {
                sourceColumn: 0,
                role: "annotation",
                type: "string",
                calc: "stringify",
            },
        ],
        ["3-5 days out", 0,],
        ["10 days out", 0,],
        ["15-10 days out", 0,],
        ["20 days out", 0,],
    ]);
    const { userSessionDetails, setUserDetails } = React.useContext(UserContext);

    useEffect(() => {
        if (count === 0) {
            fetchData();
        }
        setRole(userSessionDetails.role.name);

        let start: any;
        let end: any;
        start = selectedData[selectedCard]?.startDate;
        end = selectedData[selectedCard]?.endDate;
        if (start && end) {
            if (selectedCard === 'salesChart') {
                fetchMostLeadTrade(start, end);
            }
            else if (selectedCard === 'mostLeads') {
                fetchBidLead(start, end);
            }
        }
        fetchBidStatus();
    }, [selectedCard, selectedData]);


    const fetchBidLead = async (start: any, end: any) => {
        try {
            const response = await getBidTotalEstimate(accessToken, formatDate(start), formatDate(end));
            setBidData(response.data);
            console.log('bidEstimate', response.data);

        } catch (error) {
            console.error('Error fetching bid data:', error);
        }
    }

    const fetchData = async () => {
        const res = await getResourcesByType("sales", accessToken);
        if ((res.status === 200 || res.status === 201) && res.data.length !== 0) {
            setSalesPersons(res.data);
            setSelectedPerson(res.data[0]);
            fetchTarget(res.data[0].resourceUid, seletedToggle);
            setCount(count + 1);
        }

    }

    const fetchBidStatus = async () => {
        try {
            const result = await getBidStatus(barChartData, cartStatus, accessToken);
            console.log('bid status', result.data);

            // Construct chart data
            const newChartData = [
                [
                    "Element",
                    "Bid Out",
                    { role: "style" },
                    {
                        sourceColumn: 0,
                        role: "annotation",
                        type: "string",
                        calc: "stringify",
                    },
                ],
            ];



            // for (const [key, value] of Object.entries(bidStatusData)) {
            //     newChartData.push([key, value, value !== 0 ? "#656ee0" : "inhert", null]);
            // }

            Object.entries(result.data).map((data: any) => {
                newChartData.push([data[0], data[1], data[1] !== 0 ? "#656ee0" : "inhert", null]);
            })

            setChartData(newChartData);
        } catch (error) {
            console.error('Error fetching bid status data:', error);
        }
    };


    const fetchMostLeadTrade = async (start: any, end: any) => {
        try {
            const response = await getMostLeadTrade(accessToken, formatDate(start), formatDate(end), status);
            if(response.data !== undefined){
            setTradeData(response.data);
            }
            console.log('Most lead trade data:', response);
        } catch (error) {
            console.error('Error fetching most lead trade data:', error);
        }
    };


    const formatDate = (date: Date | null): string => {
        if (!date) return ''; // Handle case where date is null
        return date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    };

    const getTarget = async (e: any) => {
        console.log(e);
        let resource = e.target.value;
        if (resource !== undefined) {
            setSelectedPerson(resource);
            fetchTarget(resource.resourceUid, seletedToggle);
        }

    }

    const handleDateChange = (newValue: Date | null, type: 'startDate' | 'endDate', dateType: string) => {
        console.log(newValue);
        setSelectedCard(dateType);
        if (dateType in selectedData) {
            let data = selectedCard[dateType];
            console.log(data)
            setSelectedData({ ...selectedData, [dateType]: { ...selectedData[dateType], [type]: newValue } });
        } else {
            Object.assign(selectedData, { [dateType]: { [type]: newValue } })
        }
    };


    const handleEmployeeChange = (event) => {
        const empName = event.target.value;
        const employee = tradeData?.empSalesStatistics?.find((emp: EmployeeStatistics) => emp.empName === empName) || null;
        setSelectedEmployee(employee);
    };

    const handleChange = async (e: any) => {
        setSelectedToggle(e.target.value);
        fetchTarget(salectedPerson.resourceUid, e.target.value);
    }

    const fetchTarget = async (resourceUid: any, range: any) => {
        const result = await getEmployeeTerget(resourceUid, status, range, accessToken);
        console.log(result)
        setTargetDetails(result.data);
    }

    const caluculatePedningTarget = (selected: any, targetDetails: any) => {
        let target = 0;
        if (targetDetails !== undefined) {
            if (selected === "month") {
                target = targetDetails.targetAmount / 12 - targetDetails.totalAmount;
            } else {
                target = targetDetails.targetAmount - targetDetails.totalAmount;
            }
        }
        return target;
    }


    return (
        <Box >
            <Grid container spacing={2} >
                <Grid xs={12} sm={6} md={6} lg={4} xl={4}>
                    <Card className='salescardheight'>
                        <Grid container spacing={2}>
                            <Grid xs={7}>
                                <Item>
                                    <Box sx={{ display: 'grid', flexDirection: 'row' }}>

                                        <Box justifyContent="center" mt={2}>
                                            <Grid xs={12} md={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="Start Date"
                                                        value={selectedData['salesChart']?.startDate !== undefined ? selectedData['salesChart']?.startDate : null}
                                                        // onChange={(newValue) => handleDateChange(newValue, 'startDate')}
                                                        onChange={(newValue) => handleDateChange(newValue, 'startDate', 'salesChart')}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                size: 'small',
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid xs={12} md={12}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label="End Date"
                                                        value={selectedData['salesChart']?.endDate !== undefined ? selectedData['salesChart']?.endDate : null}
                                                        // onChange={(newValue) => handleDateChange(newValue, 'endDate')}
                                                        onChange={(newValue) => handleDateChange(newValue, 'endDate', 'salesChart')}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                size: 'small',
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                        </Box>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography variant="h6" color="text.secondary" component="div">
                                                Best Margin Sold by Trade:
                                            </Typography>
                                            <Typography variant="h4" color="text.secondary" component="div">
                                                {tradeData?.tradeName}
                                            </Typography>
                                            <Typography sx={{ mt: 2 }} variant="subtitle1" color="text.secondary" component="div">
                                                SOLD THIS MONTH
                                            </Typography>
                                            <Typography component="div" variant="h3" color="text.secondary">
                                                ${tradeData?.totalAmount !== undefined ? tradeData?.totalAmount : 0}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Item>
                            </Grid>

                            <Grid xs={5}>
                                <Item className='imagemargin'>
                                    <CardMedia
                                        component="img"
                                        className='salescardimage'
                                        image={bargraph}
                                        alt="Live from space album cover"
                                    />
                                </Item>
                            </Grid>
                            {role === 'admin' && (
                                <Grid xs={12}>
                                    <Box sx={{ mx: 2, color: "#fff", backgroundColor: "#656ee0bd", borderRadius: 1 }}>
                                        <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between', }}>

                                            <Grid xs={7} sx={{ display: 'flex', alignItems: 'start' }}>
                                                <Typography variant="h5" component="div" >
                                                    Select Sales Person
                                                </Typography>
                                            </Grid >
                                            <Grid xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <select onChange={handleEmployeeChange} defaultValue="">
                                                    <option value="" disabled>Select an employee</option>
                                                    {tradeData?.empSalesStatistics.map((emp, index) => (
                                                        <option key={index} value={emp?.empName}>
                                                            {emp?.empName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Grid>
                                        </Stack>
                                    </Box>
                                </Grid>)}


                            <Grid xs={12}>
                                <Box sx={{ mx: 2, mt: 2, color: "#fff", backgroundColor: "#656ee0bd", borderRadius: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ p: 1 }}>
                                        Monthly sales goal: {selectedEmployee?.targetAmount !== undefined ? selectedEmployee?.targetAmount : "--------"}
                                    </Typography>
                                </Box>

                                <Box sx={{ m: 2, color: "#fff", backgroundColor: "#656ee0bd", borderRadius: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ p: 1 }}>
                                        over/under: {selectedEmployee?.totalAmount !== undefined ? selectedEmployee?.totalAmount : "--------"}
                                    </Typography>
                                </Box>
                            </Grid>


                        </Grid>
                    </Card>

                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={4}>

                    <Card className='salescardheight cardFont' >
                        <Box sx={{ display: 'grid', flexDirection: 'row' }}>

                            <Grid>
                                <Box mt={2} sx={{ padding: '1vh' }}>
                                    <Stack direction={'row'} spacing={2}>
                                        <Grid xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="Start Date"
                                                    value={selectedData['mostLeads']?.startDate !== undefined ? selectedData['mostLeads']?.startDate : null}
                                                    // onChange={(newValue) => handleDateMostLeadTrade(newValue, 'startDate')}
                                                    onChange={(newValue) => handleDateChange(newValue, 'startDate', 'mostLeads')}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid xs={6} >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    label="End Date"
                                                    value={selectedData['mostLeads']?.endDate !== undefined ? selectedData['mostLeads']?.endDate : null}
                                                    // onChange={(newValue) => handleDateMostLeadTrade(newValue, 'endDate')}
                                                    onChange={(newValue) => handleDateChange(newValue, 'endDate', 'mostLeads')}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Stack>
                                </Box>
                            </Grid>

                            <Box>
                                <Grid xs={12} sx={{ textAlign: 'left' }} >
                                    <Grid xs={12}>
                                        <Box >
                                            <Typography variant="h3" className='cardFont' sx={{ color: 'black', padding: '1vh', }}>
                                                Most Leads From
                                            </Typography>
                                            <Typography variant='h3' className='cardFont' sx={{ color: '#8C57FF', padding: '1vh', fontWeight: 'bold' }}>
                                                {bidData?.lead?.name !== undefined ? bidData?.lead?.name : "--------"}
                                            </Typography>
                                        </Box>

                                    </Grid>

                                    <Box sx={{ padding: '1vh' }}>
                                        <Stack direction="row"
                                            spacing={1}
                                            sx={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>
                                            <Grid xs={8} sx={{ textAlign: 'left' }}>
                                                <Typography variant='h5' className='cardFont' sx={{ marginBottom: '1vh', color: 'text.secondary' }}>
                                                    Value of {bidData?.lead?.name}Bids this Month</Typography>
                                                <Typography variant='h4' className='cardFont' sx={{ color: '#8C57FF', font: '24px', marginBottom: '1vh', fontWeight: 'bold' }}>
                                                    ${bidData?.lead?.totalCost !== undefined ? bidData?.lead?.totalCost : 0}
                                                </Typography>
                                                <Typography variant='h6' className='cardFont' sx={{ marginBottom: '1vh', color: 'text.secondary' }}>
                                                    Total Bid Value of this Month
                                                </Typography>
                                                <Typography variant='h4' className='cardFont' sx={{ color: '#8C57FF', font: '24px', fontWeight: 'bold' }}>
                                                    ${bidData?.totalCost !== undefined ? bidData?.totalCost : 0}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1vh' }} >
                                                <img src="https://demos.themeselection.com/materio-vuetify-nuxtjs-admin-template/demo-1/_nuxt/trophy.US4cyYMK.png" alt="Congratulations" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                            </Grid>
                                        </Stack>

                                    </Box>
                                    <Grid xs={12} ><Stack direction={'column'} sx={{
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        width: '100%', marginLeft: '2vh'
                                    }}>
                                        <Typography variant='h5' className='cardFont' sx={{ marginBottom: '1vh', color: 'text.secondary' }}>
                                            Total Bids  Value Should sell
                                        </Typography>
                                        <Typography variant='h4' className='cardFont' sx={{ color: '#8C57FF', font: '20px', fontWeight: 'bold' }}>
                                            $ 100000.00
                                        </Typography>
                                    </Stack></Grid>
                                </Grid>
                            </Box>
                        </Box>


                    </Card>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={4}>
                    {/* <Card className='salescardheight'>
                        <Grid container spacing={2}>
                            <Grid xs={8}>
                                <Item>
                                    <Box sx={{ display: 'grid', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography variant="h6" color="text.secondary" component="div">
                                                Most sold lead:
                                            </Typography>
                                            <Typography variant="h4" color="text.secondary" component="div">
                                                Return Customer 36
                                            </Typography>

                                            <Typography sx={{ mt: 2 }} variant="subtitle1" color="text.secondary" component="div">
                                                SOLD THIS YEAR
                                            </Typography>
                                            <Typography component="div" variant="h3" color="text.secondary" >
                                                $4524
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Item>
                            </Grid>

                            <Grid xs={4}>
                                <Item className='imagemargin'> <CardMedia
                                    component="img"

                                    className='salescardimage'
                                    image={piechart}
                                    alt="Live from space album cover"
                                />
                                </Item>
                            </Grid>
                            <Grid xs={12}>
                                <Box sx={{ mx: 2, mb: 1, color: "#fff", backgroundColor: "#656ee0bd", borderRadius: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ p: 1 }}>
                                        Occumalative goal : -$14528.00
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid xs={12} >
                                <Box sx={{ mx: 2, mb: 3, mt: -1, color: "#fff", backgroundColor: "#656ee0bd", borderRadius: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ p: 1 }}>
                                        over/under: $4528
                                    </Typography>        </Box>

                            </Grid>
                        </Grid>
                    </Card> */}
                    <Weathecard />
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={3}>
                    {/* <Card className='salescardheight'>
                        <Grid container spacing={2}>
                            <Grid xs={8}>
                                <Item>
                                    <Box sx={{ display: 'grid', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography variant="h6" color="text.secondary" component="div">
                                                Most TD Beacuse
                                            </Typography>
                                            <Typography variant="h4" color="text.secondary" component="div">
                                                Just want a Budget Number 22
                                            </Typography>

                                            <Typography sx={{ mt: 0 }} variant="subtitle1" color="text.secondary" component="div">
                                                BIDS THIS YEAR
                                            </Typography>
                                            <Typography component="div" variant="h3" color="text.secondary" >
                                                $-4524.00
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Item>
                            </Grid>

                            <Grid xs={4}>
                                <Item className='imagemargin'> <CardMedia
                                    component="img"

                                    className='salescardimage'
                                    image={dollar}
                                    alt="Live from space album cover"
                                />
                                </Item>
                            </Grid>
                            <Grid xs={12}>
                                <Box sx={{ mx: 2, mb: 1, color: "#fff", backgroundColor: "#656ee0bd", borderRadius: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ p: 1 }}>
                                        Still in followup : $14528
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid xs={12} >
                                <Box sx={{ mx: 2, mb: 3, mt: -2, color: "#fff", backgroundColor: "#656ee0bd", borderRadius: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ p: 1 }}>
                                        Over/Under: $4528
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card> */}
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card >
                        <Box sx={{ m: 1 }} >
                            <Chart
                                width="100%"

                                height="400px"
                                chartType="ColumnChart"
                                data={chartData}
                                options={options}
                            />
                        </Box>
                    </Card>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{ display: 'grid', height: 415, backgroundColor: "#fff" }}>
                        <Grid container spacing={2}>
                            <Grid xs={6}>
                                <FormControl sx={{ m: 5, width: '80%' }} size='small'>
                                    <InputLabel id="demo-simple-select-label">Select salesperson</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={salectedPerson}
                                        label="Select salesperson"
                                        onChange={(e) => getTarget(e)}
                                    >
                                        {salespersons.map((value: any) => {
                                            return (<MenuItem value={value}>{value.name}</MenuItem>)
                                        })}
                                        {/* <MenuItem >Ten</MenuItem>
                                <MenuItem >Twenty</MenuItem>
                                <MenuItem >Thirty</MenuItem> */}
                                    </Select>
                                </FormControl>
                                {/* <Item > <CardMedia
                                    component="img"
                                    className='salescardimage'
                                    image={chart}
                                    alt="Live from space album cover"
                                />
                                </Item> */}
                                <Box sx={{ width: '100%', ml: 5 }}>
                                    <PieChart
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        height={300}
                                        width={300}
                                        series={[
                                            {
                                                data: [
                                                    { value: seletedToggle === "month" ? (targetDetails?.targetAmount / 12 - targetDetails?.totalAmount).toFixed(0) : targetDetails?.targetAmount - targetDetails?.totalAmount, color: '#2b8be2' },
                                                    { value: targetDetails?.totalAmount, color: '#01cbb1' }],
                                                innerRadius: '50%',
                                                //arcLabel: (params) => params.label ?? '',
                                            },
                                        ]}
                                        skipAnimation={true}
                                    />
                                </Box>
                            </Grid>
                            <Grid xs={6} mt={5}>

                                {/* <Box justifyContent="center" mt={2}>
                                    <Grid xs={12} md={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Start Date"
                                                value={startDateTarget}
                                                // onChange={(newValue) => handleDateTargetChange(newValue, 'startDate')}
                                                onChange={(newValue) => handleDateChange(newValue, 'startDate', 'target')}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        size: 'small',
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="End Date"
                                                value={endDateTarget}
                                                // onChange={(newValue) => handleDateTargetChange(newValue, 'endDate')}
                                                onChange={(newValue) => handleDateChange(newValue, 'endDate', 'target')}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        size: 'small',
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Box> */}
                                <Typography variant="h4" color="green" component="div">
                                    {targetDetails !== undefined ? targetDetails.empName : ""} closing ratio forecast
                                </Typography>
                                <Stack direction={'row'}>
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={seletedToggle}
                                        exclusive
                                        onChange={(e) => handleChange(e)}
                                        aria-label="Platform"
                                        size='small'
                                        sx={{ mt: 5 }}
                                    >
                                        <ToggleButton value="month">Month</ToggleButton>
                                        <ToggleButton value="year">Year</ToggleButton>
                                    </ToggleButtonGroup>
                                    <Stack direction={'column'} mt={5} ml={1}>
                                        <Typography variant="h5">
                                            Target
                                        </Typography>
                                        <Typography variant="h4" color="green" component="div" >
                                            {targetDetails !== undefined && targetDetails.targetAmount != null ? "$" + (seletedToggle === "month" ? (targetDetails.targetAmount / 12).toFixed(0) : targetDetails.targetAmount).toString() : "$0"}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <List sx={{ width: '100%', maxWidth: 360 }}>
                                    <ListItem>
                                        <ListItemAvatar >
                                            <Avatar sx={{ background: '#2b8be2' }}>
                                                <AttachMoneyIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={caluculatePedningTarget(seletedToggle, targetDetails) < 0 ? "Target Achieved" : caluculatePedningTarget(seletedToggle, targetDetails).toFixed(0).toString()} secondary={caluculatePedningTarget(seletedToggle, targetDetails) > 0 && "Need to achieve"} />
                                    </ListItem>
                                </List>
                                <Divider variant="inset" sx={{ mx: 4 }} />
                                <List sx={{ width: '100%', maxWidth: 360, mt: 0 }}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ background: '#01cbb1' }}>
                                                <AttachMoneyIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={targetDetails !== undefined && targetDetails.totalAmount != null ? "$" + (targetDetails.totalAmount).toString() : "$0"} secondary="Achieved till now" />
                                    </ListItem>
                                </List>
                                <CardContent sx={{ flex: '1 0 auto' }}>


                                    {/* <Typography variant="h5" color="red" component="div">
                                        You have 3 more turndowns till next side
                                    </Typography>

                                    <Typography variant="h5" color="text.secondary" component="div">
                                        Your tenantive sale will be on: 12-10.2024
                                    </Typography> */}
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Salescard;
