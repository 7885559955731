/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1231     dec-11,2023     xyz buildGetResult function implementation
*
*
*/ 
import {rows}  from '../data/dataTable'
import { executeGetData, executePostData, executePutData } from './ApiBase';
import { ConfigUrl } from '../auth/OidcConfig';

export const getTableData = async () => {
  const url = rows;
  return url;
};
export const createCustomer = async (payload:any,tenantId :any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/personalInfo/createCustomer/tenantId/${tenantId}`;
  return await executePostData(url,payload,accessToken);
};

export const getCustomer = async (userId:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/personalInfo/getCustomer/userId/${userId}`;
  return await executeGetData(url,accessToken);
};

export const updateCustomer = async (payload:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/personalInfo/updateCustomer`;
  return await executePutData(url,payload,accessToken);
};

export const getCustomerUidByEmail = async (email:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/personalInfo/getCustomerUId/email/${email}`;
  return await executeGetData(url,accessToken);
};
