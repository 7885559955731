import { Card, Fab, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getResourceTypes } from '../../services/ResourceService';
import CloseIcon from '@mui/icons-material/Close';
import { resourceTypes, resourceGroups } from '../../utils/ResourceConstants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "./bidDetails.scss"



const ResourceForm: React.FC<any> = (props) => {
    // const [resourceType, setResourceType] = React.useState<any>();
    const [row, setRow] = React.useState<any>([0])
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuIndex, setMenuIndex] = React.useState<any>();
    const openMenu = Boolean(anchorEl);
    const { accessToken } = useOidcAccessToken();
    const handleChange = (event: any, index: any) => {
        // setResourceType(event.target.value as string);

        props.changeTaskData(event, index);

    };

    const addRow = (index: any) => {
        setAnchorEl(null);
        props.add();
    }

    // const getResourceType = async () => {
    //     // get resource type
    //     let result = await getResourceTypes(accessToken);
    //     setResourceType(result?.data);
    // }

    const handleClick = (event: any, index: any) => {
        setMenuIndex(index);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const removeRow = (index: any) => {
        setAnchorEl(null);
        props.remove(index);
    }
    React.useEffect(() => {
        // getResourceType();
    }, []);

    return (
        <Grid container className='alignCenter'>
            <Grid item xs={12}>
                <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, height: '34vh', overflow: 'auto' }}>
                    {props?.task?.resources?.map((resource: any, index: any) =>
                        <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, backgroundColor: '#F1F3FF' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-simple-select-label" error={resource?.type === '' && props?.count !== 0}>Resource Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={resource?.type}
                                            error={resource?.type === '' && props?.count !== 0}
                                            label="Resource Type"
                                            onChange={(e) => handleChange(e, index)}
                                            name='type'
                                        >
                                            {resourceTypes?.map((type: any, key: any) => {
                                                return (
                                                    <MenuItem key={key} value={type}>
                                                        {type}
                                                    </MenuItem>
                                                );
                                            }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-simple-select-label" error={resource?.group === '' && props?.count !== 0}>Resource Group</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={resource?.group}
                                            label="Resource Group"
                                            error={resource?.group === '' && props?.count !== 0}
                                            onChange={(e) => handleChange(e, index)}
                                            name='group'
                                        >
                                            {resourceGroups?.map((type: any, key: any) => {
                                                return (
                                                    <MenuItem key={key} value={type}>
                                                        {/* {(type)?.split(/(?=[A-Z])/).join("-").replace(/^./, type[0]?.toUpperCase())} */}
                                                        {type}
                                                    </MenuItem>
                                                );
                                            }
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="demo-helper-text-aligned"
                                            label="Count"
                                            name="count"
                                            size="small"
                                            fullWidth
                                            error={resource?.count === '' && props?.count !== 0}
                                            onChange={(e) => handleChange(e, index)}
                                            value={resource?.count}
                                            type="number" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        id="demo-positioned-button"
                                        aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openMenu ? 'true' : undefined}
                                        onClick={(e) => handleClick(e, index)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="demo-positioned-menu"
                                        aria-labelledby="demo-positioned-button"
                                        anchorEl={anchorEl}
                                        open={openMenu === true && menuIndex === index ? true : false}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem>
                                            <Stack direction={'row'} spacing={1}>
                                                <EditIcon fontSize='small'
                                                />
                                                <Typography>Edit</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem onClick={() => removeRow(index)}>
                                            <Stack direction={'row'} spacing={1}>
                                                <DeleteIcon fontSize='small' />
                                                <Typography>Delete</Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem onClick={() => addRow(index)}>
                                            <Stack direction={'row'} spacing={1}>
                                                <AddIcon fontSize='small' />
                                                <Typography>Add</Typography>
                                            </Stack>
                                        </MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Card>
                    )}
                </Card>
            </Grid >
        </Grid >
    );
}

export default ResourceForm;