/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 11-Dec-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    customer 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/

import React, { useCallback, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import { UserContext, getUserSessionDetails, isUserHasPermission } from '../../hooks/UserSession';
import GPS from '../../components/gps/Gps';
import { Backdrop, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, MenuItem, Paper, Select, Stack, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CompletedTaskFeed from '../../components/completedTaskFeed/CompletedTaskFeed';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CustomerTable from './CustomerTable';
import { importCustomers } from '../../services/ImportService';
import CustomDialog from '../Dialog';
import "../../../src/index.scss";
import CloseIcon from '@mui/icons-material/Close';
import { sampleVendorData } from '../../components/vendor/sampleData';
import FileSaver from 'file-saver';
import gmslogo from "../../assets/logo.gif";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import XLSX from '../../components/ganttchart/components/ReactGantchartDataOptionsToolBar.tsx';
import Papa from 'papaparse';
import { sampleCustomerData } from '../../components/vendor/sampleCustomerData';

const CustomerManagement: React.FC = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    // const [userSessionDetails, setUserDetails] = useState(getUserSessionDetails(accessToken));
    // const [success, setSuccess] = React.useState(false);
    // const [error, setError] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [dataa, setFormData] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    // const handleChange = useCallback(
    //     (event) => {
    //         const files = event.target.files;
    //         if (files && files[0]) {
    //             handleFileChange(files[0]);
    //             // setFile(files[0]);
    //         }
    //     },
    //     []
    // );

    let formData = new FormData();

    const handleFileChange = (file: File) => {
        setLoading(true);
        setFile(file);
        formData.append('file', file);
        console.log(file)
        setFormData(formData);
    };

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        if (success) {
            handleDialogClose();
        }
    }


    const steps = [
        'Upload',
        'Map Data',
        'Import',
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
    const [mappings, setMappings] = useState<{ [key: string]: string }>({});
    const [disabled, setDisabled] = React.useState(true);
    const [customerCount, setCustomerCount] = useState<number | undefined>(undefined);
    const [successCount, setsucessCount] = React.useState();

    
    const handleNext = () => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    const handleChange = useCallback(
        (event) => {
            const files = event.target.files;
            if (files && files[0]) {
                setDisabled(false);
                handleFileChange(files[0]);
                const file = files[0];  // Select the first file

                // Use Papa to parse the single CSV file
                Papa.parse(file, {
                    header: true,         // Read the first row as headers
                    skipEmptyLines: true,
                    transform: (value) => value === '' ? null : value, // Skip empty lines
                    complete: (results) => {
                        console.log(results);
                        const transformedData = results.data;
                    
                      setCustomerCount(results?.data?.length)
                        const data = results.data as object[];

                        if (data.length > 0) {
                            const headers = Object.keys(data[0]);
                            console.log(headers);
                            setCsvHeaders(headers); // Update state with extracted headers
                        }
                    },
                    error: (err) => {
                        console.error("Error while parsing:", err); // Handle parsing errors
                    },
                });

                // Optionally set the file if needed elsewhere
                setFile(file);
            }
        },
        [setCsvHeaders] // Ensure the correct dependency
    );

    const exportToCSV = (csvData: Object[], fileName: string) => {
        // Create a worksheet from the data
        const ws = XLSX.utils.json_to_sheet(csvData, { header: Object.keys(csvData[0]) });
    
        // Convert the worksheet to a CSV string (no BOM)
        const csvString = XLSX.utils.sheet_to_csv(ws);
    
        // Create a Blob object with the CSV data (no BOM)
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    
        // Save the Blob object as a CSV file
        FileSaver.saveAs(blob, `${fileName}.csv`);
    };

    const customerMap = {

        "firstName": "First Name",
        "lastName": "lastName",
        "middleName": "Middle Name",
        "mobileNo": "Phone",
        "email": "Email",
        "gender": "Gender",
    };
    const [errors, setErrors] = useState(
        Object.keys(customerMap).reduce((acc, key) => {
            acc[key] = true; // Initialize all fields to true (indicating error state)
            return acc;
        }, {})
    );
    console.log(errors);
    const hasErrors = Object.values(errors).some(error => error === true);
    const handleMappingChange = (csvHeader: string, vendorField: string,) => {
        console.log(csvHeader, vendorField);
        if (csvHeader) {
            setMappings(prev => ({
                ...prev,
                [csvHeader]: vendorField,
            }));

            // Clear the error for this field
            setErrors(prevErrors => ({
                ...prevErrors,
                [vendorField]: false
            }));
        } else {
            // Set an error for this field if csvHeader is empty
            setErrors(prevErrors => ({
                ...prevErrors,
                [vendorField]: true
            }));
        }
    };

    const handleSubmit = async () => {
        try {
            const mappingString = JSON.stringify(mappings);
            console.log(mappingString);
            const encodedMapping = encodeURIComponent(mappingString);
            const hasErrors = Object.values(errors).some(error => error === true);
            console.log(hasErrors);
            if (!hasErrors) {
                setIsLoading(true);
                // const res = await importVendors(dataa, encodedMapping, accessToken);
                const res = await importCustomers(dataa,  encodedMapping,accessToken);
                console.log("answer", res);
                setOpenDialog(false);
                setOpen(true);
                if (res?.data?.
                    successful === true) {
                        setIsLoading(false)
                    setsucessCount(res?.data?.successCount);
                    if (res?.data?.successCount >= 1) {
                        setSuccess(true);
                    }
                    else {
                        setError(true);
                    }

                } else {
                    setError(true);
                    setIsLoading(false)
                }
            }
            else {
                setError(true);
            }
        } catch (error) {
            // Handle error
            console.error(error);
            setError(true);
        }
    };

    React.useEffect(() => {
        if (activeStep === 0) {
            if (file) {
                setDisabled(false);
            }
            else {
                setDisabled(true)
            }
        }
        if (activeStep === 1) {
            if (!hasErrors) {
                setDisabled(false);
            }
            else {
                setDisabled(true)
            }
        }
    }, [customerMap]);

    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={9}>
                    <Grid item xs={12} className='alignEnd' sx={{ padding: '15px' }}><Button variant="contained" size="large" onClick={handleDialogOpen}>Import Customer</Button></Grid>
                    <Grid item xs={12}><CustomerTable /></Grid>
                </Grid>
                {/* <Grid item xs={3}>
                <Stack direction="column" spacing={2}>
                    {isUserHasPermission("GPS") && <Grid item xs={12}><GPS /></Grid>}
                    <Grid item xs={12} className='headerTable'>
                        <Stack direction="column" spacing={2}>
                            <InsertChartIcon />&nbsp; Completed Task Feed
                            <CompletedTaskFeed />
                        </Stack>
                    </Grid>
                    </Stack>
                  
                       
                    
                </Grid> */}
            </Grid>
            <Dialog
                open={openDialog}
                // onClose={handleDialogClose}
                fullWidth
                maxWidth="md"
            >
                 <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
        </Backdrop>
                  <DialogTitle id="responsive-dialog-title">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                // sx={{ px: 2, py: 1 }}
                >
                    <Typography variant="h3" align="center" >
                        Import Customers
                    </Typography>
                    <IconButton sx={{ color: 'red' }} edge="end" color="inherit" onClick={handleDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
                </DialogTitle>
                <Card sx={{ width: '100%', minWidth: 800, minHeight: 600, overflow: 'auto', padding: 3 }}>
                    {/* <Typography variant="h3" align="center" gutterBottom>
                            Import Vendors
                 </Typography> */}
                    <CardContent sx={{ height: '100%', overflowY: 'auto', padding: 2, }}>

                        <Box sx={{ width: '100%' }}>
                            <Card sx={{ border: '2px solid #3c5bfa', borderRadius: 4 }}>
                                <CardContent sx={{ borderColor: "blue" }}>
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            const labelProps: { optional?: React.ReactNode } = {};
                                            // if (isStepOptional(index)) {
                                            //     labelProps.optional = (
                                            //         <Typography variant="caption">Optional</Typography>
                                            //     );
                                            // }
                                         
                                            return (
                                                <Step key={label} {...stepProps} >
                                                    <StepLabel {...labelProps} sx={{
                                                        '& .MuiStepLabel-label': {
                                                            fontSize: '1.10rem',  // Adjust the font size as needed
                                                            // fontWeight: 'bold'    // Optional: Make the text bold
                                                        }
                                                    }}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </CardContent>
                            </Card>
                            <br />
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }} align="center">
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {/* <Button onClick={handleReset}>Reset</Button> */}
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {/* Content for each step */}
                                    <Card >
                                        <CardContent >
                                            {activeStep === 0 && (
                                                <Box padding={2}>
                                                    <DialogContent>
                                                        <Typography variant="h2" fontWeight="500" marginBottom={2}>
                                                            Upload Your Customer List
                                                        </Typography>
                                                        <Stack direction="row" spacing={2} alignContent={"center"} padding={2} >
                                                            <Typography component="div" variant='h4'  >
                                                                <ul>
                                                                    <li> Click the Button to download a sample CSV file</li>
                                                                    <br />
                                                                    <li>Open the downloaded CSV file and enter your customer information according to the provided template</li>
                                                                    <br />
                                                                    <li>Upload the Edited CSV File</li>
                                                                </ul>
                                                            </Typography>
                                                        </Stack>
                                                        <Stack direction="column" spacing={2} alignContent={"center"} padding={3}>
                                                            <Typography variant="h5" color="textSecondary" >
                                                                Select a CSV file to upload
                                                            </Typography>
                                                            <Stack direction="row" spacing={2} alignItems="center">
                                                                <div className="button_group_1">
                                                                    <label htmlFor="input2" >Choose  a file  &nbsp; <i className="fa fa-upload"></i></label>
                                                                    <input type="file" name="" id="input2" onChange={handleChange} />
                                                                </div>
                                                                {file && (
                                                                    <Typography variant="body2" color="textSecondary" mt={1}>
                                                                        {file.name}
                                                                    </Typography>
                                                                )}
                                                            </Stack>
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={() => exportToCSV(sampleCustomerData, 'sample-data customer')}
                                                            >
                                                                Download a Sample CSV file
                                                            </Button>

                                                        </Stack>
                                                    </DialogContent>
                                                </Box>
                                            )}

                                            {activeStep === 1 && (
                                                <Box padding={2}>
                                                    <Typography variant="h6" align="center">
                                                        Map your fields to Customer fields
                                                    </Typography>
                                                    <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell><strong>Customer Field</strong></TableCell>
                                                                    <TableCell><strong>Your Field</strong></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Object.keys(customerMap).map((customerField) => (
                                                                    <TableRow key={customerField}>
                                                                        <TableCell>{customerField.toUpperCase()}</TableCell>
                                                                        <TableCell>
                                                                            <FormControl
                                                                                sx={{ minWidth: 200 }}
                                                                                error={!!errors[customerField]} // Pass error state
                                                                            >
                                                                                <Select
                                                                                    value={
                                                                                        Object.keys(mappings).find((csvHeader) => mappings[csvHeader] === customerField) || '' // Check if vendorField is already mapped to a CSV header
                                                                                    }


                                                                                    onChange={(e) => handleMappingChange(e.target.value, customerField)}
                                                                                    displayEmpty
                                                                                    sx={{ minWidth: 200 }}
                                                                                >
                                                                                    <MenuItem value="">
                                                                                        <em>Select a CSV Header</em>
                                                                                    </MenuItem>
                                                                                    {csvHeaders.map((header) => (
                                                                                        <MenuItem key={header} value={header}>
                                                                                            {header}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                                {!errors[customerField] ? (
                                                                                    <Typography color="green" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                                                        <CheckCircleIcon sx={{ mr: 1 }} />
                                                                                        {errors[customerField]}
                                                                                    </Typography>
                                                                                )
                                                                                    :
                                                                                    (
                                                                                        <Typography color="red" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                                                            <ErrorIcon sx={{ mr: 1 }} />
                                                                                            {errors[customerField]}
                                                                                        </Typography>
                                                                                    )}
                                                                            </FormControl>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                    {/* Add mapping data component here */}
                                                </Box>
                                            )}

                                            {activeStep === 2 && (
                                                <Box>
                                                    <Typography variant='h3'>{customerCount} Customers are ready to be imported ! !</Typography>
                                                   
                                                </Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                    <br />
                                    <Card >
                                        <CardContent >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {/* {isStepOptional(activeStep) && (
                                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                Skip
                                            </Button>
                                        )} */}
                                                {/* <Button onClick={handleNext} className="SucessSolid" disabled={disabled} >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button> */}
                                                {activeStep === steps.length - 1 ?
                                                <Button onClick={handleSubmit}  className="SucessSolid" sx={{ marginTop: 2 }}>
                                                Import
                                            </Button>
                                                :
                                                <Button onClick={handleNext} className="SucessSolid" disabled={disabled} >
                                               Next
                                            </Button>
}
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </React.Fragment>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Dialog>
            <CustomDialog
                open={open}
                onClose={closeDialog}
                success={success}
                error={error}
                Content = { 
                    success ? `${successCount} of ${customerCount} customers successfully imported.`   : "Failed To Import"   }
            />
        </>

        // <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
        // <DialogContent >
        //     <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
        //         Upload
        //     </Typography>
        //     <Typography textAlign={'center'}>
        //         <form>
        //             <div className="input_container">
        //                 <input type="file" onChange={handleChange} />
        //             </div>
        //         </form>
        //     </Typography>
        // </DialogContent>
        // </Box>
        // <DialogActions sx={{ justifyContent: "center" }}>
        // <Button onClick={handleDialogClose} className="dangerButton">Cancel</Button>
        // <Button
        //     onClick={importCustomer}
        //     autoFocus className="primaryTonalButton">
        //     Upload
        // </Button>
        // </DialogActions>
    )
}

export default CustomerManagement;