import WipReport from "../components/wip/WipReport";

export const pagesPermission = [{
    // Dashboard: ['TUS.MAN','TUS.REA','TUS.WRI'],
    // SalesEstimate: ['SAL.REA', 'SAL.WRI', 'SAL.CRE', 'SAL.VER','TUS.MAN','TUS.REA','TUS.WRI','CUS.MAN','CUS.REA','CUS.WRI',],
    // SoldProduction: ['SAL.REA', 'SAL.WRI', 'SAL.CRE', 'SAL.VER','TUS.MAN','TUS.REA','TUS.WRI','CUS.MAN','CUS.REA','CUS.WRI',],
    // Finance:['SAL.REA', 'SAL.WRI', 'SAL.CRE', 'SAL.VER','TUS.MAN','TUS.REA','TUS.WRI','CUS.MAN','CUS.REA','CUS.WRI',],
    // Documents:['SAL.REA', 'SAL.WRI', 'SAL.CRE', 'SAL.VER','TUS.MAN','TUS.REA','TUS.WRI','CUS.MAN','CUS.REA','CUS.WRI',],
    UpcomingVerification:['PRC.CRE','PRC.VER','TAS.REA'],
    SalesProjectManangement:['PRC.CRE','PRC.VER'],
    CartRead:['BID.REA'],
    CreateBid:['BID.CRE','BID.REA'],
    ActiveProject:['PRO.REA'],
    ProjectStatus:['PRO.REA','PRC.REA'],
    Checkout:['BID.CHK'],
    SoldProduction:['PRO.REA','TIM.CRE','TIM.REA',],
    //SalesEstimate:['PRC.CRE','PRC.VER','TAS.REA'],
    CompanyDashBoard:['MAT.MOD','MAT.REA','RES.MOD','RES.REA','RSS.REA'],
    DeleteBid:["BID.DEl"],
    ModifyBid:["BID.MOD"],
    Finance:["TAS.REA"],
    Admin:["TOO.REA", "MAT.REA", "RES.REA"],
    GPS:["TIM.CRE", "TIM.REA"],
    Invoice:["INV.CRE", "INV.REA"],
    ReadInvoice :["INV.REA"],
    CreateInvoice:["INV.CRE"],
    Document:["DOC.REA"],
    Payment:["PAY.CRE", "PAY.REA"],
    PaymentRead:["PAY.REA"],
    OverView:["OVW.REA"],
    ProjectInfoTask:["PTA.REA"],
    ProjectInfoBom:["PRB.REA"],
    ProjectInfoPo:["POR.REA"],
    ProjectInfoDeliverySlip:["DLS.REA"],
    UploadDeliverySlip:["DLS.CRE"],
    ProjectInfoBill:["BIL.REA"],
    TimeSheet:["TMS.REA"],
    ProjectInfoJobLedger : ["JBL.REA"],
    PaymentCreate:["PAY.CRE"],
    Assets:["AST.CRE", "AST.REA", "AST.MOD", "AST.DEL"],
    chatBot:["CHT.REA"],
    WipReport:["WIP.REA"]
    // ListInvoice:["INV.Lis"]
   
    
    

    // Finanace:['MAT.MOD','MAT.REA','RES.MOD','RES.REA','RSS.REA','PRO.REA',],

  }];
  