import React from 'react';
import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { CodeList } from '../../../interface/SdmInterfaces';

interface Props {
    index: number;
    questions: any[]; // Replace with your specific type
    setQuestions: React.Dispatch<React.SetStateAction<any[]>>; // Replace with your specific type
    codeList: CodeList[];
    setCodeList: React.Dispatch<React.SetStateAction<CodeList[]>>;
}

const BooleanComponent: React.FC<Props> = ({ index, questions, setQuestions, codeList, setCodeList }) => {
    const handleBooleanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Update boolean value in questions
        const newQuestions = [...questions];
        newQuestions[index].booleanValue = e.target.value;
        setQuestions(newQuestions);
        console.log(newQuestions);

        // Update CodeList values for "Yes" and "No"
        const newCodeList = [...codeList];
        const codeListIndex = newCodeList.findIndex(cl => cl.OID === questions[index].CodeListRef?.CodeListOID);

        if (codeListIndex !== -1) {
            newCodeList[codeListIndex].CodeListItem[0].Decode.TranslatedText.text = "Yes";
            newCodeList[codeListIndex].CodeListItem[1].Decode.TranslatedText.text = "No";
            setCodeList(newCodeList);
        }
    };
    return (
        <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={12}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            value={questions[index]?.booleanValue || 'No'}
                            onChange={handleBooleanChange}
                        >
                            {/* {booleanCodeList.CodeListItem.map((item, itemIndex) => ( */}
                            <FormControl>
                                {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                    {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                                </RadioGroup>
                            </FormControl>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BooleanComponent;
