import { useEffect, useState } from "react";
import Topbar from "./Topbar";
import { getUserSessionDetails, isUserHasPermission, TaskContext, UserContext } from "../hooks/UserSession";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { Box, Stack, useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { getCollapseMode } from "../hooks/theme";
import PageRoutes from "../pages/Routes";
import FloatChatbot from "../pages/Chatbot/FloatChatbot";
import React from "react";
import ErrorBoundary from "../components/creashDetection/ErrorBoundary";
import HavingIssue from "../components/havingIssue/HavingIssue";
import FloatHavingIssue from "../components/FloatHavingIssue";

// import FloatChatbot from "../pages/Chatbot/Floatchatbot";
const MainLayout: React.FC = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [sidebarCollapseState, setCollapse] = useState(
    getCollapseMode(smScreen)
  );

  // var x = getUserSessionDetails(accessToken, accessTokenPayload.oid);
  //console.log("UserDetails App.ts", x);
  const [userSessionDetails, setUserDetails] = useState();
  // console.log(userSessionDetails);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false); 
 
const handleCompleted=()=>{
  setIsTaskCompleted(true);
  setTimeout(() => {
    setIsTaskCompleted(false);
  }, 3000);
}

React.useEffect(() => {
  const fetchData = async () => {
    try {
      const result = await getUserSessionDetails(accessToken);
      // console.log(result)
      setUserDetails(result);
      // console.log("mainlayout")
    } catch (error: any) {
      //console.log(error)
    }
  };
    fetchData();
    handleCompleted();
    
  }, []);

  


  // const [userSessionDetails, setUser] = useState("Jesse Hall");
  // console.log(userSessionDetails)
  return (
    <>
    <ErrorBoundary>

      <UserContext.Provider  value={{
        userSessionDetails,
        setUserDetails,
       
      }}>
            <TaskContext.Provider value={{ isTaskCompleted, handleCompleted }}>
        <Topbar />
        <main
          id="main"
          className={
            useMediaQuery(theme.breakpoints.down("md"))
              ? undefined
              : sidebarCollapseState.contentMargin
          }
        >
          <Box sx={{ flexGrow: 1, margin: "15px" }} className="">
            <Toolbar />
            <PageRoutes />


            {/* <Routes>
              <Route path="/salesestimate" element={<SalesEstimateProjectManangement />} />
            </Routes> */}
        <div style={{ display: 'block' }}>
          <Stack>  <FloatHavingIssue /></Stack>

          <Stack>{isUserHasPermission("chatBot") && <FloatChatbot />}</Stack>
</div>
          </Box>
        </main>
        </TaskContext.Provider>
      </UserContext.Provider>
      </ErrorBoundary>
    </>
  );
};

export default MainLayout;


