/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 08-Mar-2024
* @author      :Vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : material Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { executeGetData, executePostData } from './ApiBase';
import { ConfigUrl } from '../auth/OidcConfig';


export const getMaterialNames = async (accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/materials/getMaterialNames`;
    return await executeGetData(url, accessToken);
};

export const addMaterial = async (accessToken: any, materials: any) => {
    const url = `${ConfigUrl.baseURL}/materials/addMaterials`;
    return await executePostData(url, materials, accessToken);
  }


  export const getMaterialNamesByTrade = async (trade:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/materials/getMaterialName/tradeName/${trade}`;
    return await executeGetData(url, accessToken);
};

export const getMaterialsByNameAndGrade = async (materials:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/materials/getVendorMaterials`;
  return await executePostData(url, materials, accessToken);
};

export const getGradesByNameAndVendorId = async (materials:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/materials/getMaterialGrade`;
  return await executePostData(url, materials, accessToken);
};