import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import RoofingIcon from '@mui/icons-material/Roofing';
import TradeForm from '../../components/tenantconfiguration/tradeconfiguration/TradeForm';
import Questions from './components/Questions';
import Tradecontract from '../../components/tenantconfiguration/tradeconfiguration/tradecontract/Tradecontract';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getTenantTradeData, getTrades } from '../../services/TradeConfigurationService';
import { UserContext } from '../../hooks/UserSession';
import TradeSetUp from './TradeSetUp';
import HavingIssue from '../../components/havingIssue/HavingIssue';
import BugReportIcon from '@mui/icons-material/BugReport';
import AddQuestion from '../../assets/addQuestion/AddQuestion';
import CloseIcon from '@mui/icons-material/Close';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const PermanentDrawerLeft: React.FC = () => {
    // const items = [
    //     { name: 'Roofing', subItems: ['Flat Roof', 'Slope Roof', 'Complex Roofing'] },
    //     { name: 'Siding', subItems: [] },
    //     { name: 'Guttering', subItems: [] },
    // ];
    const [value, setValue] = React.useState(0);
    const [showQuestionBuilder, setShowQuestionBuilder] = React.useState(false);
    const [openItem, setOpenItem] = React.useState<string | null>(null);
    const [selectedSubItem, setSelectedSubItem] = React.useState<number | null>(null);
    const [open, setOpen] = React.useState(false);
    const [tradeType, setTradeType] = React.useState<any>('');
    const [tradeName, setTradeName] = React.useState<any>('');
    const { accessToken } = useOidcAccessToken();
    const [tradeData, setTradeData] = React.useState<any>([]);
    const { userSessionDetails, setUserDetails } = React.useContext(UserContext);
    const [issueOpen, setIssueOpen] = React.useState(false);

    const [role, setRole] = React.useState<any>([] as any[]);
    // Fetch the trade list when the component mounts
    React.useEffect(() => {
        getTradeList();
    }, []); // Empty dependency array means this runs once on mount
    // React.useEffect(() => {
    //     getTradeList();
    // }, [openItem]);
    // Fetch trade list and set data

    const getTradeList = async () => {
        let tradenames: string[] = []; // Specify the type for tradenames
        try {
            let result = await getTrades(accessToken);
            let tenantTradeData: { data: { name: string }[] } = await getTenantTradeData(accessToken); // Specify the type for tenantTradeData

            if (tenantTradeData.data.length > 0) {
                setTradeData(tenantTradeData.data);
                tenantTradeData.data.forEach((data: { name: string }) => { // Explicitly define data type
                    tradenames.push(data.name);
                });
            } 
            // else {
            //     setTradeData(result.data);
            //     result.data.forEach((data: { name: string }) => { // Explicitly define data type
            //         tradenames.push(data.name);
            //     });
            // }

            // Set the first item as open item
            if (tradenames.length > 0) {
                setOpenItem(tradenames[0]);
                setTradeName(tradenames[0]);
            }
        } catch (error) {
            console.error("Error fetching trade data:", error);
        }
    };
    const handleClose = () => {
        setIssueOpen(false);
    };
    // Handle closing the trade form
    const closeTradeForm = async () => {
        setOpen(false);
        setShowQuestionBuilder(false);
        await getTradeList(); // Fetch updated trade list
    };

    // Update role when userSessionDetails change
    React.useEffect(() => {
        if (userSessionDetails.role) {
            setRole(userSessionDetails.role.name);
        }
    }, [userSessionDetails.role]); // Watch for changes in user session role

    const tabhandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleShowQuestionTemplate = () => {
        setShowQuestionBuilder(true);
        console.log(showQuestionBuilder);
    };

    const handleClick = (itemName: string) => {
        setShowQuestionBuilder(false);
        setOpenItem(openItem === itemName ? null : itemName);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleIssueDialog = () => {
        setIssueOpen(true);
    }

    return (
        <Box sx={{
            display: 'flex', border: '1px solid #e7e7e7' // Stack elements vertically
        }}>
            <Box sx={{ minWidth: 280, height: '100vh', borderRight: '1px solid #e7e7e7' }}>
                <Box className='tradeDescription boxShadow' sx={{ backgroundColor: '#e7e7e7', padding: 2, color: 'color' }}>
                    <Typography variant="h6">Trade Lists</Typography>
                </Box>
                <Divider />

<List sx={{ padding: 0, margin: 0 }}>
{tradeData.length === 0 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
      }}
    >
      <Typography sx={{ marginBottom: '8px' }}>Your trades are empty</Typography>
      <Button
        variant="contained"
        sx={{ display: 'block' }}
        onClick={handleOpen}
      >
        Trade SetUp
      </Button>
    </Box>
  ) : (
    tradeData.map((item) => (
      <React.Fragment key={item.name}>
        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            pl: 0,
            backgroundColor: openItem === item.name ? '#3c5bfa' : 'inherit',
            '&:hover': {
              backgroundColor: '#d0d0d0',
            },
            color: openItem === item.name ? '#fff' : 'inherit',
          }}
        >
          <ListItemButton onClick={() => handleClick(item.name)}>
            <ListItemIcon>
              <RoofingIcon
                sx={{
                  padding: 0,
                  margin: 0,
                  pl: 0,
                  backgroundColor: openItem === item.name ? '#3c5bfa' : 'inherit',
                  '&:hover': {
                    backgroundColor: '#d0d0d0',
                  },
                  color: openItem === item.name ? '#fff' : 'inherit',
                }}
              />
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItemButton>
        </ListItem>
        <Divider /> {/* Separator */}
      </React.Fragment>
    ))
  )}
</List>


            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #e7e7e7'
                }}
            >
                {/* Header */}

                <>   <Grid
                    container
                    sx={{ p: 0 }}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12} md={12} className='m-2'>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ width: '100%' }} // Ensure Stack takes full width
                        >
                            <Typography
                                variant="h5" // Adjust the variant as needed
                                sx={{ flexGrow: 1, textAlign: 'center' }} // Center text
                            >
{
  openItem 
    ? <>Trade Description: {openItem}</> 
    : <>Your trades are empty, please select your trades (note that trades having questions will be available for bid creation)</>
}

                            </Typography>

                            <Box>
                                <Button variant="contained" onClick={handleOpen}>
                                    Trade SetUp
                                </Button>
                                {role !== 'admin' && (
                                    <Button variant="contained" onClick={handleOpen}>
                                        Add New Trade
                                    </Button>
                                )}
                            </Box>


                        </Stack>
                    </Grid>
                </Grid>
                    <Divider />
                    <>
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                borderBottom: '1px solid #e7e7e7',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center', // Center vertically if needed
                                //padding: '0 16px', // Optional: Add padding for better spacing
                                paddingRight:'5px'
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={tabhandleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab
                                    className='tradeTab'
                                    sx={{
                                        textTransform: 'none',
                                        color: value === 0 ? 'white' : '#3c5bfa',
                                        bgcolor: value === 0 ? '#3c5bfa' : 'white',
                                        '&.Mui-selected': {
                                            color: 'white',
                                            bgcolor: '#3c5bfa !important',
                                        },
                                        '&:hover': {
                                            bgcolor: value !== 0 ? '#f0f0f0' : 'inherit',
                                        },
                                    }}
                                    label="Question Builder"
                                />
                                <Tab
                                    className='tradeTab'
                                    sx={{
                                        textTransform: 'none',
                                        color: value === 1 ? 'white' : '#3c5bfa',
                                        bgcolor: value === 1 ? '#3c5bfa' : 'white',
                                        '&.Mui-selected': {
                                            color: 'white',
                                            bgcolor: '#3c5bfa !important',
                                        },
                                        '&:hover': {
                                            bgcolor: value !== 1 ? '#f0f0f0' : 'inherit',
                                        },
                                    }}
                                    label="Contract Settings"
                                />
                            </Tabs>

                            <Button
                                className="createBidBtn"
                                // sx={{ marginLeft: '10px !important' }}
                                variant="contained"
                                color="primary"
                                onClick={handleIssueDialog}
                            >
                                {/* <BugReportIcon className='bugReportBtn' sx={{ color: ' -webkit-linear-gradient(#7f00ffd4, #3c5bfa) !important' }} /> */}
                                &nbsp; Add Question
                            </Button>
                        </Box>

                    </>

                    <Typography>{showQuestionBuilder}</Typography>
                    {issueOpen && (
                            <React.Fragment>
                            <Dialog
                                open={issueOpen}
                                onClose={()=>setIssueOpen(false)}
                                maxWidth="sm"
                                fullWidth
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <span>What do you want to report?</span>
                                        <IconButton sx={{ color: 'red' }} edge="end" onClick={handleClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                </DialogTitle>
                                <Divider />
                                <DialogContent>
                                    <DialogContentText>
                                        <AddQuestion // Pass a function that sets the state to false
                    //  selectedTrade={openItem}
                    //  selectedItem={'Add Question'}

                    // 
                    onClose={()=>setIssueOpen(false)}
                    />
                            </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    {!selectedIssue && (
                        <Button onClick={handleClose} autoFocus>
                            Close
                        </Button>
                    )}
                </DialogActions> */}
            </Dialog>
        </React.Fragment>
                    )}
                    {open && <TradeSetUp open={open} function={closeTradeForm} handleShowQuestionTemplate={handleShowQuestionTemplate} setTradeName={setTradeName} setTradeType={setTradeType} />}
                    {showQuestionBuilder && (
                        <Questions templateQuestionData={tradeName} tradeTypeName={tradeType} />
                    )}
                    <>
                        {!showQuestionBuilder && (
                            <>

                                <>
                                    <Grid
                                        container
                                        sx={{ p: 0 }}
                                        spacing={{ xs: 2, md: 3 }}
                                        columns={{ xs: 4, sm: 8, md: 12 }}
                                    >
                                        <Grid item xs={12} md={12} >
                                            <CustomTabPanel value={value} index={0}>
                                                <Box>
                                                    {(openItem !== undefined && openItem !== null) && (
                                                        <Questions templateQuestionData={openItem} />
                                                    )}
                                                </Box>
                                            </CustomTabPanel>
                                        </Grid>
                                        <Grid item xs={12} md={12} >
                                            <CustomTabPanel value={value} index={1}>
                                                <Tradecontract />
                                            </CustomTabPanel>
                                        </Grid>
                                    </Grid>
                                </>
                                {/* <Tradeconfiguration /> */}
                            </>
                        )}
                    </>

                </>
            </Box>
        </Box>
    );
};

export default PermanentDrawerLeft;
