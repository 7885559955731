export const sampleCustomerData = [
    {
        "First Name": "Nandha",
        "Last Name": "Saji",
        "Middle Name": "Gms",
        "Phone": 4353633245,
        "Email": "nandha.saji@gms.com",
        "Gender": "Male"
      },
      {
        "First Name": "Priya",
        "Last Name": "Raj",
        "Middle Name": "S",
        "Phone": 9876543210,
        "Email": "priya.raj@example.com",
        "Gender": "Female"
      },
      {
        "First Name": "John",
        "Last Name": "Doe",
        "Middle Name": "A",
        "Phone": 1234567890,
        "Email": "john.doe@example.com",
        "Gender": "Male"
      },
      {
        "First Name": "Maya",
        "Last Name": "Patel",
        "Middle Name": "R",
        "Phone": 9988776655,
        "Email": "maya.patel@example.com",
        "Gender": "Female"
      },
      {
        "First Name": "Ajay",
        "Last Name": "Kumar",
        "Middle Name": "B",
        "Phone": 1122334455,
        "Email": "ajay.kumar@example.com",
        "Gender": "Male"
      }
   ]