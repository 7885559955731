/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 
* @author      : 
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1514          |  25/3/24    |   Nandha      |   File upload dilaog implements and corrections
* 1842          | 08-05-2024  | Vanitha       |   dialog box closing implementation
*
*
*/

// components/FileUpload.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import axios from "axios";
import { Paper, IconButton, Typography, CircularProgress, Grid, Dialog, DialogContent, DialogActions, Button, DialogTitle, Divider, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './FileUploader.scss'
import CloseIcon from '@mui/icons-material/Close';
import { uploadPaymentReceipt } from "../../services/InvoiceServices";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { getJobLedgerDetails } from "../../services/TransactionService";
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CustomDialog from "../../pages/Dialog";


const FileUpload = ({ open, props, onClose, onFileUpload }) => {
  const [dragOver, setDragOver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [selectedData, setFormData] = useState<FormData | null>(null);

  const [jlDetails, setJlDetails] = useState<any>();
  const [referenceNo, setReferenceNo] = useState('');
  const [error, setError] = useState('');
  console.log(props)
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [isDialogFailed, setIsDialogFailed] = useState(false);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [validate, setValidate] = React.useState(false);
  const [createError, setCreateError] = React.useState(false);
  // const [imagePreview, setImagePreview] = useState(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      const files = event.dataTransfer.files;
      if (files && files[0]) {
        handleFileChange(files[0]);
        // const selectedFile = event.target.files?.[0];
        // if (selectedFile) {
        //   setFile(selectedFile);
        // }
      }
    },
    []
  );

  const handleDialogClose = () => {
    setOpenDialogs(false);
    onClose();
    window.location.reload();// Close the dialog content
  };

  useEffect(() => {
    const handleGetJLDetails = async () => {

      try {

        const response = await getJobLedgerDetails(props?.id, accessToken);
        console.log(response);
        setJlDetails(response?.data[0]?.referenceNumber);
      } catch (error) {
        console.error('Error fetching user session:', error);
      }
    };
    handleGetJLDetails();

  }, []);
  let receipt;


  let formData = new FormData();

  const handleFileChange = (file: File) => {
    setLoading(true);
    formData.append('file', file);
    console.log(file)
    receipt = file;
    setFile(file);
    setFormData(formData)
    const reader = new FileReader();
    reader.onloadend = () => {
      setLoading(false);
      if (reader.result === null) {
        // Handle the case where reader.result is null
        setImagePreview(null);
      } else {
        // Handle the case where reader.result is a string
        setImagePreview(reader.result as string);
      }
    };
    reader.readAsDataURL(file);
    // setFile(file)
    // Pass the original file to the onFileUpload function
    console.log(file)
    reader.onload = (event) => {
      if (event.target?.result) {
        // Access the result property of the FileReader object to obtain the file data
        const fileData = event.target.result;
        // You can now use fileData as an object or process it further
        console.log('File data:', fileData);
      }
    }

    console.log(typeof file)
    onFileUpload(file);
  };



  const handleClose = () => {
    onClose();
    // window.location.reload();
  }
  const handleFailClose = () => {
    onClose();
    window.location.reload();
  }
  // const formDataResult1 = getFormData(file);
  // console.log(formDataResult1,file)


  const handleUploadChange = async () => {
    validateReferenceNo(referenceNo);
    setOpenDialogs(true);
    try {


      if (file !== null && file instanceof File && validate) {

        // Create FormData and append file
        const formData = new FormData();
        formData.append('file', file);
        console.log(formData, file);
        // Call the upload function
        const data = await uploadPaymentReceipt(formData, props?.id, accessToken);
        console.log(data.status == 200)
        if (data?.status == 200) {
          console.log("Upload successful:", data);
          // setIsDialogOpen(true);
          setCreateSuccess(true);
          // Additional logic for successful upload
        } else {
          // setIsDialogFailed(true)
          setCreateError(true);
          console.error("Upload failed:", data);
          // Handle the case where the upload failed
        }
      }
      else {
        console.error("Invalid file:", file);
        setCreateError(true);
      }
    }
    catch (error) {
      console.error("Error uploading payment receipt:", error);
      // Handle other errors
    }
  };
  const validateReferenceNo = (value) => {
    if (value === jlDetails) {
      setValidate(true);
      setError('');

    } else {
      setValidate(false);
      setError('Reference number does not match the voucher no/cheque no ');
    }
  };

  const handleChange1 = (event) => {
    setReferenceNo(event.target.value);
    validateReferenceNo(event.target.value);
  };



  const handleChange = useCallback(
    (event) => {
      const files = event.target.files;
      if (files && files[0]) {
        handleFileChange(files[0]);
        // setFile(files[0]);
      }
    },
    []
  );

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        // onClose={handleClose}
        //onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown

      >
        <DialogTitle className="verification" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Verification
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "red" }} />
            </IconButton>
          </Box>
        </DialogTitle>

        <Divider />
        <DialogContent>
          <Typography mb={2}><Grid container spacing={1}><Grid item xs={7}>You are Uploading against this reference Number </Grid><Grid item xs={5}><span className="referenceNo">

            <TextField
              id="outlined-multiline-static"
              label="Reference no"
              size="small"
              fullWidth
              value={referenceNo}
              onChange={handleChange1}
              helperText={error}
              error={Boolean(error)}

            /></span></Grid></Grid></Typography>
          <Box>
            <Paper
              variant="outlined"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{
                border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
                padding: 20,
                textAlign: 'center',
                cursor: 'pointer',
                background: dragOver ? '#eee' : '#fafafa',
                position: 'relative',
              }}
            >
              <form id="yourFormId">
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleChange}
                />
              </form>
              <label htmlFor="raised-button-file">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <CloudUploadIcon style={{ fontSize: 60 }} />
                  </IconButton>
                  <Typography>Upload Receipt</Typography>
                  <Typography>Drag and drop files here or click to select files</Typography>
                </Box>
              </label>
              {loading && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Paper>
            {imagePreview && (
              <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    component="img"
                    src={imagePreview}
                    alt="Image Preview"
                    sx={{ width: '100%', height: 'auto' }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions><Button onClick={handleUploadChange}>Verify</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={isDialogOpen}
      >
        <DialogContent>
        <Typography textAlign={'center'}><DoneIcon sx={{ fontSize: "80px", color: "#0ea347", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>Receipt Uploaded succesfully</Typography>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>

      </Dialog> */}
      <CustomDialog
        open={openDialogs}
        // onClose={() => setOpenDialogs(false)}
        onClose={handleDialogClose}
        success={createSuccess}
        error={createError}
        Content={createSuccess ? "Receipt Uploaded succesfully" : " Receipt Upload Failed "} />
      {/* <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={isDialogFailed}
      >
        <DialogContent>
        <Typography textAlign={'center'}><ErrorOutlineIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>Receipt Upload Failed</Typography>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleFailClose}>Ok</Button>
        </DialogActions>

      </Dialog> */}
    </>
  );
}

export default FileUpload;

