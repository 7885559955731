import { Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { ItemData, ItemDef } from "../../../interface/SdmInterfaces";

interface Props {
    itemDef: ItemDef;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
}

const FloatText: React.FC<any> = ({ itemDef, measureDef, onItemchange, itemDatas, error }) => {

    ////console.log(itemDef);

    const [itemData, setItemData] = useState<ItemData>((itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0] : []);

    const inputchange = (e: any) => {
        if (itemDatas.length > 0) {
            setItemData({ ...itemDatas[0], Value: e.target.value });
            onItemchange({ ...itemDatas[0], Value: e.target.value });
        } else {
            let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: e.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
            setItemData(tempItemData);
            onItemchange(tempItemData);
        }
    }

    return (
        <>
            <Stack direction="row" spacing={2}>
                <TextField
                    error={(error?.OID === itemDef.OID) ? true : false}
                    id="demo-helper-text-aligned"
                    label=""
                    name="name"
                    size="small"
                    sx={{ m: 1 }}
                    type="number"
                    fullWidth
                    value={itemData?.Value}
                    inputProps={{ step: "0.1" }}
                    required
                    helperText={(error?.OID === itemDef.OID) ? 'Please fill this field' : ''}
                    onChange={inputchange}
                />
                <Typography sx={{ textAlign: 'center' }}>{measureDef?.Symbol?.TranslatedText?.text}</Typography>
            </Stack>
        </>
    )
}

export default FloatText;