/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 06-Sep-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Confirm dialog to show message after reference project selection
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorWarning: React.FC<any> = (props) => {
    const handleClose = () => {
        props.onClose();
    };
    return (
        <React.Fragment>

            <Dialog
                open={props?.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px' }}>
                    Error
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {props?.isGroupTask === false ?
               <><DialogContent dividers>
                        <Typography gutterBottom marginBottom={1}>
                            This Task List Cannot Be Submitted.
                        </Typography>
                        <DialogContentText gutterBottom>
                            The Following Task are required to Proceed.
                        </DialogContentText>
                        <DialogContentText gutterBottom>
                            {props?.taskNames?.map((task: any, index: any) => {
                                return (
                                    <Stack direction={'row'} spacing={1}>
                                        <Typography>{index + 1}.</Typography>
                                        <Typography> {task}</Typography>
                                    </Stack>
                                );
                            })}
                        </DialogContentText>       
                    </DialogContent>
                    </>
                    :
                    <>
                    <DialogContent dividers>
                            <DialogContentText gutterBottom marginBottom={1}>
                                This Task List and associated group tasks have one or more error in them.
                            </DialogContentText>
                            <DialogContentText gutterBottom>
                                Please fix them to continue.
                            </DialogContentText>
                        </DialogContent>
                        </>
                }<DialogActions>
                            <Button onClick={handleClose}>Ok</Button>
                        </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ErrorWarning;