import React, { useContext, useState } from 'react';
import { FormControl, Grid, TextField, Card, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, IconButton, Checkbox, ListItemText } from '@mui/material';
import { CodeList, ConditionDef, ItemDef, ItemGroupDef } from '../../../interface/SdmInterfaces';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { UserContext } from '../../../hooks/UserSession';

interface Props {
    conditionalDef: ConditionDef[]; // Replace with your specific type
    setConditionalDef: React.Dispatch<React.SetStateAction<ConditionDef[]>>; // Replace with your specific type
    itemRef: any;
    itemDefs: ItemDef[];
    codeLists: CodeList[];
    itemGroupOID: any;
    questionOID: any;
    itemGroupDefs: any;
    setItemGroupDefs: React.Dispatch<React.SetStateAction<ItemGroupDef[]>>;
    setConditionOid:any;
}

const ConditionalDef: React.FC<Props> = ({ conditionalDef, setConditionalDef, itemRef, itemDefs, codeLists, itemGroupOID, questionOID, itemGroupDefs, setItemGroupDefs ,setConditionOid}) => {
    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    const [role, setRole] = React.useState<any>([] as any[]);

    ////console.log(itemDefs)
    //operand  changes 
    const operandOptions = [
        { value: '==', label: '==' },
        { value: '!=', label: '!=' },
        { value: '<=', label: '<=' },
        { value: '>=', label: '>=' },
    ];


    const [conditional, setConditional] = useState<any>(''); // To handle conditional ref
    const [conditionalCodeList, setConditionalCodeList] = useState<CodeList[]>([]);
    const [codedVale, setCodedValue] = useState<any>('' || []); // State to hold selected operand
    const [dataType, setDataType] = useState<any>(); // To handle conditional ref
    const [operand, setOperand] = useState<string>(''); // State to hold selected operand
    const [multiSelect, setMultiSelect] = useState<any>([]); // State to hold selected operand

    //handleQuestions selecting for conditional Rendering
    const handleConditionalItemChange = (event: any) => {
        ////console.log(event.target.value);
        const itemDef = itemDefs.find(item => item.OID === event.target.value)
        setConditional(itemDef?.OID);
        const questionItemDef = itemDefs?.filter((q) => q.OID === event.target.value);
        questionItemDef.map((itemData) => {
            ////console.log(itemData.DataType, itemData.CodeListRef)
            setDataType(itemData.DataType);
            const updatedCodeLists = codeLists.filter(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
            if (updatedCodeLists) {
                ////console.log("updated code list", updatedCodeLists)
                // to get the coded values 
                setConditionalCodeList(updatedCodeLists);
            }
        })
    }
    const handleDefaultItemChange = (selectedValue: any) => {
        ////console.log(selectedValue);

        const itemDef = itemDefs.find(item => item.OID === selectedValue);
        if (itemDef) {
            setConditional(itemDef.OID);
            ////console.log('Conditional:', itemDef.Question.TranslatedText.text);
        }

        const questionItemDef = itemDefs.filter(q => q.OID === selectedValue);
        questionItemDef.forEach(itemData => {
            ////console.log('DataType:', itemData.DataType, 'CodeListRef:', itemData.CodeListRef);
            setDataType(itemData.DataType);

            const updatedCodeLists = codeLists.filter(cl => cl.OID === itemData.CodeListRef?.CodeListOID);
            if (updatedCodeLists.length > 0) {
                ////console.log('Updated Code List:', updatedCodeLists);
                setConditionalCodeList(updatedCodeLists);
            }
        });
    };

    React.useEffect(()=>{
        setRole(userSessionDetails.role.name);
    }, [role])
    React.useEffect(() => {
        handleConditionExpression();
        //console.log(codedVale, operand, conditional)

    }, [itemRef.CollectionExceptionConditionOID]);// Empty dependency array ensures it runs only once on component mount

    // Function to handle condition expression
    const handleConditionExpression = () => {
        const conditionDef = conditionalDef.find(condition => condition.OID === itemRef.CollectionExceptionConditionOID);
        ////console.log('CollectionExceptionConditionOID', conditionDef, itemRef.CollectionExceptionConditionOID);

        const FormalExpression = conditionDef?.FormalExpression.text;
        const matches = FormalExpression?.match(/([^\s()]+)\s*([!<>=]+)\s*"([^"]+)"/);
        ////console.log(matches);

        if (matches) {
            const itemFilteredDef = itemDefs.find(id => id.OID === matches[1]);

            if (itemFilteredDef) {
                //console.log(itemFilteredDef?.CodeListRef, itemFilteredDef);
                setDataType(itemFilteredDef.DataType);
                if (itemFilteredDef?.CodeListRef) {
                    const codeList = codeLists.find(codeList => codeList.OID === itemFilteredDef.CodeListRef?.CodeListOID);
                    if (codeList) {
                        if (itemFilteredDef.DataType === 'multiselect' || itemFilteredDef.DataType === 'multiSelectComboBox') {
                            const selectedValues = matches[3] ? matches[3].split(',') : [];
                            // setMultiSelect(selectedValues);
                            setCodedValue(selectedValues);
                            setOperand(matches[2]);
                            // Object.assign(conditional,itemFilteredDef.OID);
                            setConditional(itemFilteredDef.OID || '')
                            handleDefaultItemChange(itemFilteredDef.OID);
                        }
                        else {
                            const codeListItem = codeList.CodeListItem.find(codedValues => codedValues.CodedValue === matches[3]);

                            setCodedValue(codeListItem?.CodedValue || '');  // Assuming codedVale is your state variable
                            setOperand(matches[2]);
                            // Object.assign(conditional,itemFilteredDef.OID);
                            setConditional(itemFilteredDef.OID || '')
                            handleDefaultItemChange(itemFilteredDef.OID);
                        }

                    }
                } else {
                    setCodedValue(matches[3] || '')
                    setOperand(matches[2]);
                    setConditional(itemFilteredDef.OID || '')

                    // Object.assign(conditional, itemFilteredDef.OID);
                    //console.log(matches[3], matches[2], conditional)
                }
            }
        }
    };

    // createConditionalDefFormalExpression
    const createConditionalDefFormalExpression = (itemGroupConditionOid, itemConditionOid, conditionalCodeList, operand, codedVale) => {
        // Check if ItemGroupOID already exists in itemGroupDefs
        const existingItemGroupIndex = itemGroupDefs.findIndex(itemGroup => itemGroup.OID === itemGroupConditionOid);
        //console.log(existingItemGroupIndex)
        if (existingItemGroupIndex !== -1) {
            // If ItemGroupOID exists, find the ItemRef
            const existingItemGroup = { ...itemGroupDefs[existingItemGroupIndex] };
            const existingItemRef = existingItemGroup.ItemRef.find(itemRef => itemRef.ItemOID === itemConditionOid);

            if (existingItemRef?.CollectionExceptionConditionOID) {
                const existingConditionDefIndex = conditionalDef.findIndex(condition => condition.OID === existingItemRef.CollectionExceptionConditionOID);

                if (existingConditionDefIndex !== -1) {
                    const updatedConditionDef = { ...conditionalDef[existingConditionDefIndex] };
                    // ////console.log("CollectionExceptionConditionOID", updatedConditionDef, existingItemRef);

                    // Update the FormalExpression of the existing ConditionDef
                    updatedConditionDef.FormalExpression = {
                        Context: "OpenEDC",
                        text: `!(${conditionalCodeList} ${operand} "${codedVale}")`
                    };
                    //console.log(updatedConditionDef.FormalExpression);
                    // Update the conditionalDef array with the updated condition
                    const updatedConditionalDefArray = [
                        ...conditionalDef.slice(0, existingConditionDefIndex),
                        updatedConditionDef,
                        ...conditionalDef.slice(existingConditionDefIndex + 1)
                    ];

                    // Set the updated conditionalDef array
                    setConditionalDef(updatedConditionalDefArray);
                    setConditionOid(prev => {
                        const newSet = new Set(prev);
                        newSet.add(existingItemRef?.CollectionExceptionConditionOID);
                        return newSet;
                      });
                }
       
            }
        }
    };

    // for creating ConditionalDefFormalExpression
    const handleItemValue = (event: any, itemGroupConditionOid, itemOid) => {
        ////console.log(event.target.value)

        const itemDef = itemDefs.find(itemData => itemData.OID === itemOid || itemData.DataType === 'multiSelectComboBox');
        setCodedValue(event.target.value);
        if (conditional !== null && operand !== null && event.target.value !== null) {
            createConditionalDefFormalExpression(itemGroupConditionOid, itemOid, conditional, operand, event.target.value);

        }

    }

    //to set the operand and perform create ConditionalDefFormalExpression
    const handleOperandChange = (event: React.ChangeEvent<{ value: unknown }>, itemGroupConditionOid, itemOid) => {
        setOperand(event.target.value as string); // Update selected operand
        if (conditional !== null && operand !== null && event.target.value !== null) {
            createConditionalDefFormalExpression(itemGroupConditionOid, itemOid, conditional, event.target.value as string, codedVale);

        }

    };



    // Function to delete a condition
    const handleDeleteCondition = (conditionOID: string, itemGroupOid: string) => {
        // Find the index of the condition to delete
        const index = conditionalDef.findIndex(cd => cd.OID === conditionOID);
        if (index === -1) {
            return; // Condition not found, handle accordingly
        }

        // Remove condition from conditionalDef state
        const updatedConditionalDef = [...conditionalDef];
        const deletedConditionalDef = updatedConditionalDef.splice(index, 1)[0];
        setConditionalDef(updatedConditionalDef);

        // Update itemGroupDefs to set CollectionExceptionConditionOID to null for the specified ItemGroupDef
        const updatedItemGroupDefs = itemGroupDefs.map((itemGroup) => {
            if (itemGroup.OID === itemGroupOid) {
                return {
                    ...itemGroup,
                    ItemRef: itemGroup.ItemRef.map((itemRef) => {
                        if (itemRef.CollectionExceptionConditionOID === deletedConditionalDef.OID) {
                            // Use destructuring to create a new object without the CollectionExceptionConditionOID property
                            const { CollectionExceptionConditionOID, ...rest } = itemRef;
                            return rest;
                        }
                        return itemRef;
                    }),
                };
            }
            return itemGroup;
        });
        // Update state with the modified itemGroupDefs
        setItemGroupDefs(updatedItemGroupDefs);
    };

    // Filter out items with OID === questionOID
    const filteredItemDefs = itemDefs.filter((option) => option.OID !== questionOID);
    return (

        <>

            <Card className="questionBuilderCard conditionalBorder">
                <Grid container alignItems="center">
                    <Grid item xs={11}>
                        {/* Conditional Rendering */}
                        Conditional Rendering
                    </Grid>
                    <Grid item xs={1} mt={0} display="flex" justifyContent="flex-end">
                        {role !=='admin' && (
                        <IconButton
                            aria-label="delete"
                            onClick={() => handleDeleteCondition(itemRef.CollectionExceptionConditionOID, itemGroupOID)}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Stack direction="row">
                    <Grid container spacing={2} m={1}>

                        <Grid item xs={12} md={5} mt={1}>
                            <FormControl fullWidth>
                                <InputLabel id={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}>Question</InputLabel>
                                <Select
                                    labelId={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}
                                    id={`demo-select-small-${itemRef.CollectionExceptionConditionOID}`}
                                    value={conditional}
                                    size='small'
                                    label="Question"
                                    onChange={(e: any) => handleConditionalItemChange(e)}
                                    fullWidth
                                    readOnly ={role==='admin'}

                                >
                                    {filteredItemDefs.map((option) => (
                                        <MenuItem key={option.OID} value={option.OID}>
                                            {option.Question?.TranslatedText?.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>

                        {/* //select box for showing operand */}
                        <Grid item xs={12} md={2} mt={1}>
                            <FormControl fullWidth>
                                <InputLabel id={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}>Operand</InputLabel>
                                <Select
                                    labelId={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}
                                    id={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}
                                    value={operand}
                                    label="Operand"
                                    onChange={(e: any) => {
                                        handleOperandChange(e, itemGroupOID, questionOID);
                                    }}
                                    fullWidth
                                    size='small'
                                    readOnly = {role==='admin'} 

                                >
                                    {operandOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {/* //select box for showing value of itemDef */}
                        <Grid item xs={12} md={5} mt={1}>

                            {dataType === 'boolean' ? (
                                <FormControl fullWidth>
                                    <InputLabel id={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}>Value</InputLabel>
                                    <Select
                                        labelId="operand-select-label"
                                        id="operand-select"
                                        value={codedVale}
                                        label="Value"
                                        onChange={(event: SelectChangeEvent<any>) => {
                                            handleItemValue(event, itemGroupOID, questionOID);
                                        }}
                                        fullWidth
                                        size="small"
                                        readOnly ={role==='admin'}
                                    >
                                        <MenuItem key="1" value="Yes">Yes</MenuItem>
                                        <MenuItem key="2" value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                            ) : dataType === 'text' || dataType === 'multiLineText' ? (
                                <TextField
                                    label="Text Field"
                                    fullWidth
                                    size="small"
                                    value={codedVale.toString()}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleItemValue(event, itemGroupOID, questionOID);
                                    }}
                                    disabled={role==='admin'}

                                />
                            ) : dataType === 'integer' ? (
                                <TextField
                                    id="integer-input"
                                    label="Integer Input"
                                    fullWidth
                                    size='small'
                                    value={codedVale}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleItemValue(event, itemGroupOID, questionOID);
                                    }}
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restrict input to numbers

                                />
                            ) : dataType === 'datetime' ? (
                                <TextField
                                    id="datetime-input"
                                    label="Date Time"
                                    fullWidth
                                    size='small'
                                    type='datetime-local' // Set the input type to 'datetime-local'
                                    value={codedVale}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleItemValue(event, itemGroupOID, questionOID);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            ) :dataType === 'date' ? (
                                <TextField
                                    id="date-input"
                                    label="Date"
                                    fullWidth
                                    size='small'
                                    type='date' // Set the input type to 'date'
                                    value={codedVale}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleItemValue(event, itemGroupOID, questionOID);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                        
                                    }}
                                    InputProps={{
                                        readOnly:(role ==='admin'),
                                      }}
                                />
                            ) : dataType === 'multiSelectComboBox' || dataType === 'multiselect' ? (

                                <FormControl fullWidth size="small">
                                    <InputLabel id={`demo-select-small-label-${itemGroupOID}`}>Value</InputLabel>
                                    <Select
                                        labelId={`demo-select-small-label-${itemGroupOID}`}
                                        id={`operand-select-${itemGroupOID}`}
                                        multiple
                                        label='value'
                                        value={codedVale}
                                        onChange={(e) => handleItemValue(e, itemGroupOID, questionOID)}
                                        readOnly ={role==='admin'}     
                                                                           renderValue={(selected) => (
                                            <div>
                                                {selected.map((value) => {
                                                    const codeListItem = conditionalCodeList.flatMap(option => option.CodeListItem)
                                                        .find(item => item.CodedValue === value);
                                                    return (
                                                        <span key={value} style={{ marginRight: 8 }}>
                                                            {codeListItem?.Decode.TranslatedText.text}
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        size='small'
                                    >
                                        {conditionalCodeList.flatMap((option) =>
                                            option.CodeListItem.map((codeListItem) => (
                                                <MenuItem key={codeListItem.CodedValue} value={codeListItem.CodedValue}>
                                                    <Checkbox checked={codedVale.indexOf(codeListItem.CodedValue) > -1} />
                                                    <ListItemText primary={codeListItem.Decode.TranslatedText.text} />
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            ) : (
                                <FormControl fullWidth>
                                    <InputLabel id={`demo-select-small-label-${itemRef.CollectionExceptionConditionOID}`}>Value</InputLabel>
                                    <Select
                                        labelId="operand-select-label"
                                        id="operand-select"
                                        value={codedVale}
                                        label="Value"
                                        onChange={(e: any) => {
                                            handleItemValue(e, itemGroupOID, questionOID);
                                        }}
                                        fullWidth
                                        size='small'
                                        readOnly ={role==='admin'}
                                    >
                                        {conditionalCodeList.flatMap((option) =>
                                            option.CodeListItem.map((codeListItem) => (
                                                <MenuItem key={codeListItem.CodedValue} value={codeListItem.CodedValue}>
                                                    {codeListItem.Decode.TranslatedText.text}
                                                </MenuItem>
                                            ))
                                        )}

                                    </Select>
                                </FormControl>
                            )
                            }
                        </Grid>
                    </Grid>
                </Stack>
            </Card>


        </>
    );
};

export default ConditionalDef;
