import { LocalizationProvider, DatePicker, DateTimeField, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { CodeList, ConditionDef, ItemData, ItemDef } from '../../../interface/SdmInterfaces';
import { useState } from 'react';
import dayjs from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro';

interface DateProps {
    itemDef: ItemDef;
    conditionDef: ConditionDef[];
    codeList: CodeList[];
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
    showStatus: React.Dispatch<React.SetStateAction<any>>;
}

const DateTime: React.FC<any> = ({ itemDef, conditionDef, codeList, onItemchange, itemDatas }) => {

    const [itemData, setItemData] = useState<ItemData>();

    const handleChange = (event: any) => {
        //console.log(dayjs(event.$d));
        let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: dayjs(event.$d).format('YYYY-MM-DD') + "T00:00:00.000Z", CodeList: [], MetaKey: itemDef.MetaKey };
        setItemData(tempItemData);
        onItemchange(tempItemData);
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']} >
                    <DateTimePicker label="Date" slotProps={{ textField: { fullWidth: true, size: 'small' } }} onChange={handleChange} />
                    {/* <DateRangePicker label="Date Range" slotProps={{ textField: { fullWidth: true, size: 'small' } }} onChange={handleChange} /> */}
                </DemoContainer>
            </LocalizationProvider>
        </>
    )
}

export default DateTime;