/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Common Dashboard
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';


import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Rating, Theme, Typography, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';

import Select, { SelectChangeEvent } from '@mui/material/Select';



import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';







/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 17-apr-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for adding employee details
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import "../Employment.scss";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getUserSession, UserContext } from '../../../../hooks/UserSession';
import { getTradeNames, validQuestionTrade } from '../../../../services/TradeConfigurationService';
import { createResource, getResourceByEmail, updateResource } from '../../../../services/ResourceService';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import CustomDialog from '../../../Dialog';
import { getRoles } from '../../../../services/RoleSevice';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../../../services/EmployeeService';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



const Employementdetails: React.FC<any> = (props: any) => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const theme = useTheme();
    const [checked, setChecked] = React.useState(false);
    const [successType, setSuccessType] = React.useState('');
    const navigate = useNavigate();
    const [userDetails, setUserDeatils] = React.useState<any>({});

    // dayjs(props.selectedDate).format('YYYY-MM-DDTHH:mm:ss.SSS').toString() + "Z"
    console.log(props);
    // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const inputValue = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
    //     const trimmedValue = inputValue.slice(0, 5); // Trim the value to 5 characters
    //     // Update the state or perform any other action with the trimmed value
    //     // For example, you can set it to state using setState or use it to perform validation
    //     //console.log(trimmedValue);
    // };

    const [Status, setStatus] = React.useState('');
    const HandlestatusChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
    };
    const { userSessionDetails } = React.useContext(UserContext);
    const [skill, setSkill] = React.useState<any[]>([]); // Initialize as an empty array

    const [Payschedule, setPayschedule] = React.useState('');
    const HandlePayscheduleChange = (event: SelectChangeEvent) => {
        setPayschedule(event.target.value as string);
    };
    const [roles, setRoles] = React.useState<any[]>([]);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [updated, setUpdated] = React.useState(false);

    const [count, setCount] = React.useState(0);
    const [noChange, setNoChange] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [userData, setUserData] = React.useState<any>({
        status: 'Active',
        employeeId: null,
        designation: '',
        cost: '',
        performance: '5',
        skills: [], // Change this to an empty array
        hireDate: dayjs(''),
        canOverallocate: false,
        name: props.firstname

    });
    const [oldUserData, setOldUserData] = React.useState<any>({
        employeeId: null,
        designation: '',
        cost: '',
        performance: '5',
        skills: [], // Change this to an empty array
        hireDate: dayjs(''),

    });
    const [message, setMessage] = React.useState<any>({
        cost: '',
        performance: '0',
        skills: [], // Change this to an empty array
        hireDate: '',


    });
    let employeeData = props.rows
    const [content, setContent] = React.useState<any>('');
    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const month = date.getMonth().toString();
        return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T00:00:00.000Z";
    }
    const onclose = () => {
        handleProfileData();
        setUpdated(true);

        setOpenDialogs(false);
        // window.location.reload();
    }

    const handleTrades = () => {
        validQuestionTrade(accessToken)
            .then(res => {
                //console.log(res.data)
                setSkill(res.data)
            })

    }

    const validation = () => {
        let validated: any = true;

        if (userData.skills.length === 0) {
            // setMessage({...message,state:'Enter Your State'});
            message.skills = 'Enter Your skills';
            validated = false;

        }

        if (userData.hireDate?.$d?.toString() === "Invalid Date") {
            //setMessage({...message,country:'Enter Your Country'});
            message.hireDate = 'Enter Your hiredate';
            validated = false;

        }

        if (userData.performance === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.performance = 'Enter Your performance';
            validated = false;

        }
        if (userData.cost === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.cost = 'Enter Your COST';
            validated = false;

        }

        return validated;

    }
    const handleDateChange = (event: any) => {
        //console.log(event.$d);

        const formattedDate = dayjs(event.$d);
        setUserData({
            ...userData,
            hireDate: formattedDate,
        });
    }
    const onCancel = () => {
        navigate('/admin');

    }


    const handleInputChange = (event: any) => {
        //console.log(event);
        const { name, value } = event.target;

        // If the field being changed is 'hiredate', format it accordingly
        if (name === 'skills' && Array.isArray(value)) {
            // If the field name is 'skills' and the value is an array, convert it to a comma-separated string
            setUserData({
                ...userData,
                skills: value.join(', ') // Convert array to string with comma and space
            });
        } else {
            // For other fields, proceed with default behavior
            setUserData({
                ...userData,
                [name]: value
            });
        }
    };
    const handleSelectChange = (fieldName: string) => (event: SelectChangeEvent<string>) => {
        setUserData((prevUser) => ({
            ...prevUser,
            designation: event.target.value,
        }));
    };
    const formatCost = (cost) => parseFloat(cost).toFixed(2);

    const createResources = (e) => {
        const dataToSend = {
            ...userData,
            skills: JSON.stringify(userData.skills) // Stringify the skills array
        };
        setOpenDialogs(true)
        //console.log(userData)
        setCount(count + 1);
        let formData = new FormData();
        // formData.append("file", image);
        let validated = validation();

        if (validated === true) {
            try {
                const response = createResource(props.email, dataToSend, accessToken)
                    .then(res => {
                        //console.log(res);

                        if (res.ok === true) {
                            setCreateSuccess(true);
                            setContent('Employee details added successfully');
                        }
                        // Additional handling if needed
                    });
            } catch (error) {
                setCreateError(true)
                console.error('Error:', error);
            }

        }
        else {
            console.log("not valifdated")
        }


    }
    const employeeRoles = async () => {
        try {
            const response = await getRoles(accessToken);
            //console.log(response.data);
            setRoles(response.data)

        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };
    const updateResources = (e) => {

        setCount(count + 1);
        let validated = validation();
        let formData = new FormData();
        // formData.append("file", image); 
        // Convert props?.rows?.skills to ["Siding","Gutter"] and then stringify
        const olduserSkills = JSON.stringify(Array.isArray(oldUserData.skills) ? oldUserData.skills : []);        // let skillData = JSON?.parse(props?.rows?.skills)

        // Convert userData.skills to ["Siding","Gutter"] and then stringify
        const userSkillsString = JSON.stringify(Array.isArray(userData.skills) ? userData.skills : []);
        console.log(olduserSkills)
        const areCostsEqual = formatCost(oldUserData.cost) === formatCost(userData.cost);

        console.log(oldUserData.cost, userData.cost,areCostsEqual
            , oldUserData.performance, userData.performance, dayjs(oldUserData.hireDate).toISOString(), dayjs(userData.hireDate).toISOString(), olduserSkills, userSkillsString, oldUserData.designation, userData.designation)
        console.log(areCostsEqual
            , oldUserData.performance === userData.performance, dayjs(oldUserData.hireDate).toISOString() === dayjs(userData.hireDate).toISOString(), olduserSkills === userSkillsString, oldUserData.designation === userData.designation)
        // console.log(props?.rows?.skills, userSkillsString);
        if (areCostsEqual
            && oldUserData.performance === userData.performance && dayjs(oldUserData.hireDate).toISOString() === dayjs(userData.hireDate).toISOString() && olduserSkills === userSkillsString && oldUserData.designation === userData.designation
        ) {
            // handleClick('No valued Changed');
            setNoChange(true);

        }
        else {
            if (validated === true) {
                const dataToSend = {
                    ...userData,
                    skills: JSON.stringify(userData.skills) // Stringify the skills array
                };


                //console.log(userData)

                updateResource(dataToSend, oldUserData?.employeeId, accessToken)
                    .then(res => {
                        setOpenDialogs(true);

                        //console.log(res);
                        try {
                            if (res.ok === true) {

                                setCreateSuccess(true);
                                setSuccessType('update');
                                setContent('Employee details Updated successfully');

                            }
                        }
                        catch (error) {
                            setCreateError(true)

                            console.error('Error:', error);
                        }
                        // Additional handling if needed
                    });
            }
            else {

            }
        }

    }
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }

    const handleChangeTrade = (event: SelectChangeEvent) => {
        //console.log(event.target.value);
        //console.log(event.target.value)

        setUserData({
            ...userData,
            skills: "Roofing",


        });

    }

    const handleProfileData = async () => {
        console.log(props)
        const response = await getUserDetails(userData?.employeeId, accessToken);
        console.log(response);

        if (response.status === 200 || response.status === 201) {
            getResourceByEmail(response?.data?.email, accessToken)
                .then(res => {
                    console.log(res);
                    if (res.data != null) {
                        setOldUserData({
                            ...oldUserData,
                            role: res.data.designation || '',
                            employeeId: res.data.user || '',
                            hireDate: res.data.hireDate || '',
                            skills: res.data?.skills ? JSON.parse(res.data.skills) : [],
                            cost: res.data.cost || '',
                            designation: res.data.designation

                        });
                    }
                });
            // Your logic here, for example:
            setUpdated(true);

            //   setRowData(response.data);
            // setUserData({
            //         ...userData,
            //         role: response.data.role || '',
            //         employeeId: response.data..user || '',
            //         hireDate: props.rows.hireDate || '',
            //         skills: props?.rows?.skills ? JSON.parse(props.rows.skills) : [],
            //         cost: props.rows.cost || '',
            //         designation: props.rows.designation

            //     });

        } else {
            console.error('Error fetching user details:', response.status);
            // Optionally handle errors here, such as showing an error message or retrying
        }
        // if (response) {
        //   setRowData(response.data);
        // }
    };
    // getTradeNames
    React.useEffect(() => {
        //getLocation();


        handleTrades();
        if (props.rows != null) {
            //             const parsedArray = JSON.parse(props.rows.skills); // Parse the string into an array
            // const transformedObject = { skills: parsedArray }; 
            // //console.log(transformedObject.skills)
            setUserData({
                ...userData,
                role: props.rows.designation || '',
                employeeId: props.rows.user || '',
                hireDate: props.rows.hireDate || '',
                skills: props?.rows?.skills ? JSON.parse(props.rows.skills) : [],
                cost: props.rows.cost || '',
                designation: props.rows.designation

            });
            setOldUserData({
                ...oldUserData,
                role: props.rows.designation || '',
                employeeId: props.employeeId || '',
                hireDate: props.rows.hireDate || '',
                skills: props?.rows?.skills ? JSON.parse(props.rows.skills) : [],
                cost: props.rows.cost || '',
                designation: props.rows.designation

            });
        }
        else if (props != null) {
            setUserData({
                ...userData,
                designation: props?.role,
                employeeId: props.employeeId


            });
            setOldUserData({
                ...oldUserData,
                designation: props?.role,
                employeeId: props.employeeId


            });
        }
        employeeRoles();


    }, []);
    const handleChange = (event: SelectChangeEvent<typeof skill>) => {
        //console.log(event)
        const {
            target: { value },
        } = event;
        setUserData({
            ...userData,
            // On autofill we get a stringified value.
            skills: typeof value === 'string' ? value.split(',') : value,
        });
    };

    const [Worklocation, setWorklocation] = React.useState('');
    const HandleWorklocationChange = (event: SelectChangeEvent) => {
        setWorklocation(event.target.value as string);
    };

    const [Manager, setManager] = React.useState('');
    const HandleManagerChange = (event: SelectChangeEvent) => {
        setManager(event.target.value as string);
    };


    const [Department, setDepartment] = React.useState('');
    const HandleDepartmentChange = (event: SelectChangeEvent) => {
        setDepartment(event.target.value as string);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >
                <Grid xs={12} md={12}>
                    <Typography variant="h3" component="h2" align='left' sx={{ mt: 1, mx: 1, py: 1 }}>
                        Employment details
                    </Typography>
                </Grid>
                <Card sx={{ minWidth: '100%', p: 3 }} className='contractCard'>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                    >
                        <Grid item xs={12} md={12}>
                            <Typography variant='h6'>
                                Let's get down to Employee job specifics
                            </Typography>
                        </Grid>
                        {/* 
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" >Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={Status}
                                    label="Status"
                                    onChange={HandlestatusChange}
                                >
                                    <MenuItem value={10}>Active</MenuItem>
                                    <MenuItem value={20}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" >Work location*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={Worklocation}
                                    label="Worklocation"
                                    onChange={HandleWorklocationChange}
                                >
                                    <MenuItem value={10}>821 E Washington Ave (WI)|</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" >Manager</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={Manager}
                                    label="Manager"
                                    onChange={HandleManagerChange}
                                    placeholder='Select A Manager'
                                >
                                    <MenuItem value={10}></MenuItem>
                                    <MenuItem value={10}>Select A Manager</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" >Department</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={Department}
                                    label="Department"
                                    onChange={HandleDepartmentChange}
                                    placeholder='Department'
                                >
                                    <MenuItem value={10}></MenuItem>
                                    <MenuItem value={10}>Select A Department</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid> */}


                        {/* <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Job Title"
                                size="small"
                                fullWidth
                                defaultValue=""
                                name="jobtitle"
                                onChange={handleInputChange}
                            />
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Employee ID"
                                size="small"
                                fullWidth
                                defaultValue=""
                                name="employeeId"
                                value={userData.employeeId}
                                onChange={handleInputChange}
                                InputProps={{ readOnly: true }} // Make the TextField read-only

                            />
                        </Grid>

                        {/* <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Cost Rate"
                                size="small"
                                fullWidth
                                defaultValue=""
                                name="costrate"
                                onChange={handleInputChange}
                            />
                        </Grid> */}

                        <Grid item xs={12} md={6}>
                            {/* <TextField
                                id="outlined-multiline-static"
                                label="Hire Date"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true, required: true }}
                                value={dayjs(userData.hireDate)}
                                defaultValue=""
                                type="date"
                                name="hireDate"
                                onChange={handleInputChange}
                            /> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DatePicker']}  >
                                    {props.rows !== null && props.rows !== undefined ? (
                                        <DatePicker label="Hire Date" value={dayjs(userData ? userData.hireDate : '')}
                                            slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                                            name='hireDate'
                                            onChange={(e: any) => handleDateChange(e)}
                                            disabled

                                        />) :
                                        (<DatePicker label="Hire Date" value={userData.hireDate}
                                            slotProps={{ textField: { fullWidth: true, size: 'small', error: (userData.hireDate?.$d?.toString() === 'Invalid Date' && count !== 0), } }}
                                            name='hireDate'
                                            onChange={(e: any) => handleDateChange(e)}


                                        />)}
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>



                        <Grid item xs={12} md={6}>
                            {/* <Grid item xs={12} sm={6} mt={1}> */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={userData.designation}
                                    label="Role"
                                    onChange={handleSelectChange('roles')}
                                    size='small'
                                >
                                    {roles?.filter(role => role.name !== 'customer' && role.name !== 'admin') // Filter out 'labour' and 'admin' roles
                                        .map(role => (
                                            <MenuItem key={role.id} value={role.name}>
                                                {role.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            {/* </Grid> */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Cost"
                                size="small"
                                fullWidth
                                defaultValue=""
                                value={userData.cost}
                                required
                                name="cost"
                                onChange={handleInputChange}
                                error={(userData?.cost === '' && count !== 0)}
                                helperText={(userData?.cost === '' && count !== 0) ? message.cost : null}


                            />
                        </Grid>




                        {/* <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Designation"
                                size="small"
                                fullWidth
                                defaultValue=""
                                value={userData.role}
                                name="role"
                                onChange={handleInputChange}
                            />
                        </Grid> */}


                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-multiple-name-label">Skills</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={userData?.skills || []} // Ensure value is always an array
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Skills" />}
                                    MenuProps={MenuProps}
                                    name="skills"
                                    required
                                    error={(userData?.skills.length === 0 && count !== 0)}

                                >
                                    {skill?.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, userData.skills, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Typography variant="body1">Performance</Typography>
                            <Stack spacing={1}>
                                <Rating name="performance" value={userData.performance} size="medium" onChange={(e: any) => handleInputChange(e)} aria-required />
                                {/* <Rating name="size-large" defaultValue={2} size="large" /> */}

                            </Stack>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value={userData.canOverallocate} 
                                    control={<Checkbox checked={checked} name="canOverallocate" value={userData.canOverallocate} onChange={handleInputChange} />}
                                    label="Can employee overallocate"
                                    name='canOverallocate'
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </FormControl>
                        </Grid> */}


                        <Grid container spacing={2} justifyContent="flex-end " mt={2}>
                            {(props.rows !== null && props.rows !== undefined) || updated === true ? (
                                <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={updateResources} >
                                        Update
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={createResources} >
                                        Save
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button className='dangerButton' variant="contained" size="medium" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>


                </Card>

                <CustomDialog
                    open={openDialogs}
                    onClose={() => onclose()}
                    success={createSuccess}
                    error={createError}
                    Content={createSuccess ? content : " Error Couldnot create"} />


                <Dialog open={noChange} >
                    <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                            {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                        </Box>
                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                        <DialogContent >
                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

            </Grid>

        </Box>

    )
};

export default Employementdetails;
