/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 
* @author      : Ananthakumar,Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Resource service
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*  2174           |  14/06/24   |   Vanitha    | Resource  Management Pagination Implementation

*
*/
import { executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const getResources = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources`;
  return await executeGetData(url, accessToken);
};

export const getResourceByUid = async (resourceId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/resourceUid/${resourceId}`;
  return await executeGetData(url, accessToken);
};

export const searchResources = async(accessToken: any, materials: any, page: any, size:any) => {
  const url = `${ConfigUrl.baseURL}/resources/search?page=${page}&size=${size}`;
  return await executePostData(url, materials, accessToken);
};

export const getResourcesCount = async(accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/count`;
  return await executeGetData(url, accessToken);
}

export const editResources = async (payload: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/updateResourceCost`;
  return await executePutData(url, payload, accessToken);
};

export const addResourceSchedule = async (
  payload: any,
  date: any,
  accessToken: any
) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/addResourceSchedule/date/${date}`;
  return await executePostData(url, payload, accessToken);
};

export const createResource = async (
  email: any,
  payload: any,
  accessToken: any
) => {
  const url = `${ConfigUrl.baseURL}/resources/createResourceDetails/email/${email}`;
  return await executePostData(url, payload, accessToken);
};
export const getResourceByEmail = async (email: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/getResourceDetails/email/${email}`;
  return await executeGetData(url, accessToken);
};
export const updateResource = async (
  payload: any,
  resourceId: any,
  accessToken: any
) => {
  const url = `${ConfigUrl.baseURL}/resources/updateResources/resourceId/${resourceId}`;
  return await executePutData(url, payload, accessToken);
};

export const getResourceByDateRange = async (startTime: any, endTime:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/getResourceByDateRange/startTime/${startTime}/endTime/${endTime}`;
  return await executeGetData(url, accessToken);
};

export const getAllLeaves = async ( type: any, accessToken: any, page: any, size: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/getAllLeaves/type/${type}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
}

export const getResourceId = async ( accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/getResourceId`;
  return await executeGetData(url, accessToken);
}

export const getResourcesByType = async ( type:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/getResource/resourceType/${type}`;
  return await executeGetData(url, accessToken);
}

export const rejectLeave = async (resourceScheduleId:any, status: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/rejectLeave/resourceScheduleId/${resourceScheduleId}/status/${status}`;
  return await executePutData(url,null, accessToken);
}
export const getCountOfAllLeaves = async ( type: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/getCountOfAllLeaves/type/${type}`;
  return await executeGetData(url, accessToken);
}

export const getResourceTypes = async(accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resources/getResourceType`;
  return await executeGetData(url, accessToken);
}

export const getBlockedDates = async (startDate: any, labourCount: any, projectDuration:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/resourceSchedules/blockedDates/startDate/${startDate}/labourCount/${labourCount}/projectDuration/${projectDuration}`;
  return await executeGetData(url, accessToken);
};