/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 06-Sep-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Confirm dialog to show message after reference project selection
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, DialogContentText, IconButton, Typography } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CloseIcon from '@mui/icons-material/Close';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import { completeProject } from '../../services/ProjectService';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const CompleteWarningDialog: React.FC<any> = (props) => {
    const { accessToken } = useOidcAccessToken();
    const [isLoading, setIsLoading] = React.useState(false);
    const [openCompleteDialog, setOpenCompleteDialog] = React.useState(false);
    const [completeSuccess, setCompleteSuccess] = React.useState(false);
    const [completeError, setCompleteError] = React.useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        props.onClose();
    };

    const handleCompleteProject = async () => {
        setIsLoading(true);
        let result = await completeProject(props?.projectId, accessToken);
        if (result.status === 200 && result.data !== null && result.data !== "") {
            setIsLoading(false);
            handleClose();
            // setOpenCompleteDialog(true);
            // setCompleteSuccess(true);
            onNextPage();
        }
        else {
            setIsLoading(false);
            handleClose();
            setOpenCompleteDialog(true);
            setCompleteError(true);
        }
    }
    const onNextPage = () => {
        navigate("/soldproduction");
    }

    return (
        <React.Fragment>

            <Dialog
                open={props?.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <img src={gmslogo} alt="gms" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogTitle className='warning'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "40px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'18px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom marginBottom={2} sx ={{color:"red", fontSize:'16px'}}>
                        This action will remove all resources and processes attached to this project effective immediately.
                    </Typography>
                    <DialogContentText gutterBottom  marginBottom={2}>
                        Any payment pending will remain as is.
                    </DialogContentText>
                    <Typography >
                        Do you confirm and wish to proceed?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='contained'>Go Back</Button>
                    <Button
                        onClick={handleCompleteProject}
                        autoFocus
                        variant='contained'
                        color='success'>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openCompleteDialog}
                onClose={() => setOpenCompleteDialog(false)}
                success={completeSuccess}
                error={completeError}
                Content={completeSuccess ? "Project Updated Successfully" : "Error"}
            />
        </React.Fragment>
    );
}

export default CompleteWarningDialog;