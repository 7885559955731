import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';

const DateTimeComponent: React.FC= () => {

//   const handleDateTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     // onChange(e.target.value);
//   };

  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={12}>
            <TextField
              id="datetime-input"
              label="Date Time"
              fullWidth
              size='small'
              type='datetime-local' // Set the input type to 'datetime-local'
            //   value={value}
            //   onChange={handleDateTimeChange}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DateTimeComponent;
