import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';

const DateComponent: React.FC = () => {
    return (
        <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="Date" slotProps={{ textField: { fullWidth: true, size: 'small' } }} disabled/>
                        </DemoContainer>
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Box>
    );
}
export default DateComponent;
