/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 26-Aug-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Confirm dialog to show message after reference project selection
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, DialogContentText, IconButton, Typography } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CloseIcon from '@mui/icons-material/Close';
import gmslogo from "../../assets/logo.gif";
import { saveSelectedReferenceProjectTasks } from '../../services/TaskService';
import CustomDialog from '../../pages/Dialog';

const ReferenceProjectWarningDialog: React.FC<any> = (props) => {
  const { accessToken } = useOidcAccessToken();
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClose = () => {
    props.onClose();
  };

  const closeDialog = () => {
    setSuccess(false);
    setError(false);
    setOpenDialog(false);
  }

  const saveSelectedProject = async () => {
    setIsLoading(true);
    let result = await saveSelectedReferenceProjectTasks(props?.projectId, props?.referenceId, accessToken);
    if (result.status === 200 && result.data !== null && result.data !== "") {
      setIsLoading(false);
      handleClose();
      props.closeReferenceProject();
      props.handleChangeTab();
    }
    else {
      setIsLoading(false);
      setError(true);
    }
  }

  return (
    <React.Fragment>

      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
        </Backdrop>
        <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px' }}>
          Confirmation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom marginBottom={1}>
            Your new project will be customized based off this reference project.<br></br>
            Are you sure your want to proceed with it?
          </Typography>
          <DialogContentText gutterBottom >
            Note that reference project once chosen cannot be modified.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Go Back</Button>
          <Button onClick={saveSelectedProject} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomDialog
        open={openDialog}
        onClose={closeDialog}
        success={success}
        error={error}
        Content={success ? "" : "Error"} />
    </React.Fragment>
  );
}

export default ReferenceProjectWarningDialog;