import React, { useCallback, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import {  getUserSessionDetails } from '../../hooks/UserSession';
import GPS from '../../components/gps/Gps';
import { Backdrop, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, Paper, Select, Stack, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CompletedTaskFeed from '../../components/completedTaskFeed/CompletedTaskFeed';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { error } from 'console';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import VendorList from './VendorList';
import { importVendors } from '../../services/ImportService';
import CustomDialog from '../../pages/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import FileSaver from 'file-saver';
import XLSX from '../ganttchart/components/ReactGantchartDataOptionsToolBar.tsx';
import { sampleVendorData } from './sampleData';
import { blue } from '@mui/material/colors';
import Papa from 'papaparse';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import gmslogo from "../../assets/logo.gif";

const VendorManagement: React.FC = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    // const [userSessionDetails, setUserDetails] = useState(getUserSessionDetails(accessToken));
    // const [success, setSuccess] = React.useState(false);
    // const [error, setError] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [dataa, setFormData] = useState<any>();
    console.log(dataa);
    const [open, setOpen] = useState<boolean>(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
    const [mappings, setMappings] = useState<{ [key: string]: string }>({});
    const [disabled, setDisabled] = React.useState(true);
    const [vendorCount, setVendorCount] = useState<number | undefined>(undefined);
    const[successCount,setsucessCount]=React.useState();
    console.log(mappings);
    console.log(csvHeaders);
    const handleChange = useCallback(
        (event) => {
            const files = event.target.files;
            if (files && files[0]) {
                setDisabled(false);
                handleFileChange(files[0]);
                const file = files[0];  // Select the first file

                // Use Papa to parse the single CSV file
                Papa.parse(file, {
                    header: true,         // Read the first row as headers
                    skipEmptyLines: true,
                    transform: (value) => value === '' ? null : value, // Skip empty lines
                    complete: (results) => {
                        console.log(results);
                        const transformedData = results.data;
                    
                      setVendorCount(results?.data?.length)
                        const data = results.data as object[];

                        if (data.length > 0) {
                            const headers = Object.keys(data[0]);
                            console.log(headers);
                            setCsvHeaders(headers); // Update state with extracted headers
                        }
                    },
                    error: (err) => {
                        console.error("Error while parsing:", err); // Handle parsing errors
                    },
                });

                // Optionally set the file if needed elsewhere
                setFile(file);
            }
        },
        [setCsvHeaders] // Ensure the correct dependency
    );

    let formData = new FormData();

    const handleFileChange = (file: any) => {
        setLoading(true);
        setFile(file);
        formData.append('file', file);
        console.log(file);
        console.log(formData);
        setFormData(formData);
    };

    const importVendor = async () => {
        // const res = await importVendors(dataa, accessToken);
        // console.log(res);
        // setOpenDialog(false);
        // setOpen(true);
        // if (res.ok === true) {
        //     setSuccess(true);

        // } else {
        //     setError(true);
        // }
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        if (success) {
            handleDialogClose();
        }
    }




    const steps = [
        'Upload',
        'Map Data',
        'Import',
    ];

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    // const sampleData = [
    //     { Name: "John Doe", Age: 30, Email: "john.doe@example.com" },
    //     { Name: "Jane Smith", Age: 25, Email: "jane.smith@example.com" }
    //   ];
    //  const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const fileExtension = ".xlsx";
    //   const exportToCSV = (csvData: Object[], fileName: string) => {
    //     const ws = XLSX.utils.json_to_sheet(csvData);
    //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    //     const data = new Blob([excelBuffer], { type: fileType });
    //     console.log(data);

    //     FileSaver.saveAs(data, fileName + fileExtension);
    //   };


    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";

    // // Function to convert JSON data to CSV and download
    // const exportToCSV = (csvData: Object[], fileName: string) => {
    //     // Convert JSON data to a CSV string
    //     const ws = XLSX.utils.json_to_sheet(csvData, { header: Object.keys(csvData[0]) });
    //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    //     // Write the workbook to CSV format
    //     const csvBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });

    //     // Create a Blob object with the CSV data
    //     const data = new Blob([csvBuffer], { type: fileType });

    //     // Save the Blob object as a CSV file
    //     FileSaver.saveAs(data, fileName + fileExtension);
    // };
    const exportToCSV = (csvData: Object[], fileName: string) => {
        // Create a worksheet from the data
        const ws = XLSX.utils.json_to_sheet(csvData, { header: Object.keys(csvData[0]) });
    
        // Convert the worksheet to a CSV string (no BOM)
        const csvString = XLSX.utils.sheet_to_csv(ws);
    
        // Create a Blob object with the CSV data (no BOM)
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    
        // Save the Blob object as a CSV file
        FileSaver.saveAs(blob, `${fileName}.csv`);
    };

    const vendorMap = {

        "displayName": "Vendor Display Name",
        "primaryMobileNumber": "Mobile Number",
        "companyName": "Company",
        "accountNo": "Account",
        "address": "Street",
        "city": "City",
        "country": "Country",
        "state": "State",
        "zipCode": "ZIP",
        "email": "Email",
        "checkName": "Vendor Display Name",
        "createdBy": "Created By",
        "leadTime": "Lead Time",
        "services": "Services",
        // "updatedAt": "Last modified date",
        "payTerms": "Terms"

    };
    const [errors, setErrors] = useState(
        Object.keys(vendorMap).reduce((acc, key) => {
          acc[key] = true; // Initialize all fields to true (indicating error state)
          return acc;
        }, {})
      );
      console.log(errors);
      const hasErrors = Object.values(errors).some(error => error === true);
    const handleMappingChange = (csvHeader: string, vendorField: string,) => {
        console.log(csvHeader, vendorField);
        if (csvHeader) {
            setMappings(prev => ({
                ...prev,
                [csvHeader]: vendorField,
            }));
    
            // Clear the error for this field
            setErrors(prevErrors => ({
                ...prevErrors,
                [vendorField]: false
            }));
        } else {
            // Set an error for this field if csvHeader is empty
            setErrors(prevErrors => ({
                ...prevErrors,
                [vendorField]: true
            }));
        }
    };

    const handleSubmit = async () => {
        try {
            const mappingString = JSON.stringify(mappings);
            console.log(mappingString);
            const encodedMapping = encodeURIComponent(mappingString);
            const hasErrors = Object.values(errors).some(error => error === true);
            console.log(hasErrors);
            if (!hasErrors) {
                setIsLoading(true);
                console.log("dataa,", dataa)
            const res = await importVendors(dataa, encodedMapping, accessToken);
            console.log("answer",res);
            setOpenDialog(false);
            setOpen(true);
            if (res?.data?.
                successful === true) {
                    setIsLoading(false)
                    setsucessCount(res?.data?.successCount);
                    if(res?.data?.successCount>=1){
                setSuccess(true);
                    }
                    else{
                        setError(true);
                    }

            } else {
                setError(true);
                setIsLoading(false)
            }
        }
        else{
            setError(true);
        }
            // await axios.post(`/vendors/import/${encodeURIComponent(mappingString)}`, {
            //     // Include other data if necessary, e.g., file upload
            // });
            // Handle success
        } catch (error) {
            // Handle error
            console.error(error);
            setError(true);
        }
    };

   React. useEffect(() => {
    if(activeStep === 0){
        if(file){
            setDisabled(false);
        }
        else{
            setDisabled(true)
        }
    }
    if(activeStep === 1){
       if(!hasErrors){
        setDisabled(false);
       }
       else{
        setDisabled(true)
       }
    }
    }, [vendorMap]);
    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={9}>
                    <Grid item xs={12} className='alignEnd' sx={{ padding: '15px' }}><Button variant="contained" size="large" onClick={handleDialogOpen}>Import Vendor</Button></Grid>
                    <Grid item xs={12}><VendorList /></Grid>
                </Grid>
                {/* <Grid item xs={3}>
                <Stack direction="column" spacing={2}>
                    {isUserHasPermission("GPS") && <Grid item xs={12}><GPS /></Grid>}
                    <Grid item xs={12} className='headerTable'>
                        <Stack direction="column" spacing={2}>
                            <InsertChartIcon />&nbsp; Completed Task Feed
                            <CompletedTaskFeed />
                        </Stack>
                    </Grid>
                    </Stack>
                  
                       
                    
                </Grid> */}
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="md" // Controls the maximum width of the dialog
            >
                 <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
        </Backdrop>
                <DialogTitle id="responsive-dialog-title">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    // sx={{ px: 2, py: 1 }}
                    >
                        <Typography variant="h3" align="center" >
                            Import Vendors
                        </Typography>
                        <IconButton sx={{ color: 'red' }} edge="end" color="inherit" onClick={handleDialogClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Card sx={{ width: '100%', minWidth: 800, minHeight: 600, overflow: 'auto', padding: 3 }}>
                    {/* <Typography variant="h3" align="center" gutterBottom>
                            Import Vendors
                 </Typography> */}
                    <CardContent sx={{ height: '100%', overflowY: 'auto', padding: 2, }}>

                        <Box sx={{ width: '100%' }}>
                            <Card sx={{ border: '2px solid #3c5bfa', borderRadius: 4 }}>
                                <CardContent sx={{ borderColor: "blue" }}>
                                    <Stepper activeStep={activeStep} alternativeLabel>
                                        {steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            const labelProps: { optional?: React.ReactNode } = {};
                                            // if (isStepOptional(index)) {
                                            //     labelProps.optional = (
                                            //         <Typography variant="caption">Optional</Typography>
                                            //     );
                                            // }
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps} >
                                                    <StepLabel {...labelProps} sx={{
                                                        '& .MuiStepLabel-label': {
                                                            fontSize: '1.10rem',  // Adjust the font size as needed
                                                            // fontWeight: 'bold'    // Optional: Make the text bold
                                                        }
                                                    }}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </CardContent>
                            </Card>
                            <br />
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }} align="center">
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {/* Content for each step */}
                                    <Card >
                                        <CardContent >
                                            {activeStep === 0 && (
                                                <Box padding={2}>
                                                    <DialogContent>
                                                        <Typography variant="h2" fontWeight="500" marginBottom={2}>
                                                            Upload Your Vendor List
                                                        </Typography>
                                                        <Stack direction="row" spacing={2} alignContent={"center"}  padding={2} >
                                                        <Typography component="div" variant='h4'  >
  <ul>
    <li> Click the Button to download a sample CSV file</li>
    <br/>
    <li>Open the downloaded CSV file and enter your vendor information according to the provided template</li>
    <br/>
    <li>Upload the Edited CSV File</li>
  </ul>
</Typography>
</Stack>
                                                        <Stack direction="column" spacing={2} alignContent={"center"}  padding={3}>
                                                            <Typography variant="h5" color="textSecondary" >
                                                                Select a CSV file to upload
                                                            </Typography>
                                                            <Stack direction="row" spacing={2} alignItems="center">
                                                                {/* <Typography variant="h5" color="textSecondary" sx={{fontStyle:"bold"}}>
          Select a CSV file to upload
        </Typography> */}

                                                                {/* <label className="file">
        <input
          type="file"
          id="file"
          aria-label="File browser example"
          onChange={handleChange}
        />
        <span className="file-custom"></span>
      </label> */}

                                                                <div className="button_group_1">
                                                                    <label htmlFor="input2" >Choose  a file  &nbsp; <i className="fa fa-upload"></i></label>
                                                                    <input type="file" name="" id="input2" onChange={handleChange} />
                                                                </div>
                                                                {file && (
                                                                    <Typography variant="body2" color="textSecondary" mt={1}>
                                                                        {file.name}
                                                                    </Typography>
                                                                )}
                                                            </Stack>
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={() => exportToCSV(sampleVendorData, 'sample-data vendor')}
                                                            >
                                                                Download a Sample CSV file
                                                            </Button>

                                                        </Stack>
                                                        {/* <Box marginTop={1} marginLeft={1} >
                                                            <Button
                                                                variant="outlined"
                                                                color="secondary"
                                                                onClick={() => exportToCSV(sampleVendorData, 'sample-data')}
                                                            >
                                                                Download a Sample CSV file
                                                            </Button>
                                                        </Box> */}
                                                    </DialogContent>
                                                    {/* <DialogActions sx={{ justifyContent: "end" }}>
                                                        <Button onClick={handleDialogClose} className="dangerButton">
                                                            Cancel
                                                        </Button>
                                                        <Button onClick={importVendor} autoFocus className="primaryTonalButton">
                                                            Upload
                                                        </Button>
                                                    </DialogActions> */}
                                                </Box>
                                            )}

                                            {activeStep === 1 && (
                                                <Box padding={2}>
                                                    <Typography variant="h6" align="center">
                                                        Map your fields to vendor fields
                                                    </Typography>
                                                    <TableContainer component={Paper} sx={{ marginTop: 3 }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell><strong>Vendor Field</strong></TableCell>
                                                                    <TableCell><strong>Your Field</strong></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Object.keys(vendorMap).map((vendorField) => (
                                                                    <TableRow key={vendorField}>
                                                                        <TableCell>{vendorField.toUpperCase()}</TableCell>
                                                                        <TableCell>
                                                                        <FormControl
                            sx={{ minWidth: 200 }}
                            error={!!errors[vendorField] } // Pass error state
                        >
                                                                            <Select
                                                                                value={
                                                                                    Object.keys(mappings).find((csvHeader) => mappings[csvHeader] === vendorField ) || '' // Check if vendorField is already mapped to a CSV header
                                                                                }
                                                                            
                                                                                
                                                                                onChange={(e) => handleMappingChange(e.target.value, vendorField)}
                                                                                displayEmpty
                                                                                sx={{ minWidth: 200 }}
                                                                            >
                                                                                <MenuItem value="">
                                                                                    <em>Select a CSV Header</em>
                                                                                </MenuItem>
                                                                                {csvHeaders.map((header) => (
                                                                                    <MenuItem key={header} value={header}>
                                                                                        {header}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                            {!errors[vendorField] ? (
        <Typography color="green" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <CheckCircleIcon sx={{ mr: 1 }} />
          {errors[vendorField]}
        </Typography>
      )
    :
    (
        <Typography color="red" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        <ErrorIcon sx={{ mr: 1 }} />
        {errors[vendorField]}
      </Typography> 
    )}
                                                                            </FormControl>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                   
                                                    {/* Add mapping data component here */}
                                                </Box>
                                            )}

                                            {activeStep === 2 && (
                                                <Box>
                                                <Typography variant='h3'>{vendorCount} vendors are ready to be imported ! !</Typography>
                                                {/* <Box padding={2}>
                                                    <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ marginTop: 2 }}>
                                                       Import
                                                    </Button>
                                                 
                                                </Box> */}
                                                </Box>
                                            )}
                                        </CardContent>
                                    </Card>
                                    <br />
                                    <Card >
                                        <CardContent >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {/* {isStepOptional(activeStep) && (
                                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                Skip
                                            </Button>
                                        )} */}
                                                {/* <Button onClick={handleNext} className="SucessSolid" disabled={disabled} >
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button> */}
                                                    {activeStep === steps.length - 1 ?
                                                        <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ marginTop: 2 }}>
                                                            Import
                                                        </Button>
                                                        :
                                                        <Button onClick={handleNext} className="SucessSolid" disabled={disabled} >
                                                            Next
                                                        </Button>
                                                    }
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </React.Fragment>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Dialog>

            <CustomDialog
                open={open}
                onClose={closeDialog}
                success={success}
                error={error}
                Content = { 
                    success ? `${successCount} of ${vendorCount} vendors successfully imported.`   : "Failed To Import"   }
                
            />
        </>
    )
}

export default VendorManagement;