import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useContext, useEffect, useState } from "react";
import { UserContext, getUserSession } from "../hooks/UserSession";
import SalesEstimate from "./SalesEstimate/SalesEstimate";
import Dashboard from "./Dashboard";
import SalesProduction from "./SalesProduction/SalesProduction";
import React from "react";
import { getAdminInfo, getcustomerInfo, getpersonalInfo } from "../services/EmployeeService";
import Personalinfo from "./Admin templates/Employment/Employer/Personalinfo";
import Customer from "./Admin templates/Employment/Customer";
import gmslogo from "../assets/logo.gif";
import { Backdrop, Grid, Typography } from "@mui/material";
import AdminOnBoarding from "../components/adminOnBoarding/AdminOnBoarding";

const CommonDashboard = () => {
    const { accessToken } = useOidcAccessToken();
    const { userSessionDetails } = useContext(UserContext);
    const [hasEmail, setHasEmail] = useState(false);
    const [hasAdminEmail, setHasAdminEmail] = useState(false);

    const [hasCustomerEmail, setHasCustomerEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    
//   const handleClose = () => {
//     onClose();
//   };

    useEffect(() => {
        // getUserSession(accessToken)
        //     .then(response => {
            Promise.all([
                getpersonalInfo(userSessionDetails?.email, accessToken),
                getcustomerInfo(userSessionDetails?.email, accessToken),
                getAdminInfo(userSessionDetails?.email, accessToken)
              ]).then(([personalInfoRes, customerInfoRes,adminInfo]) => {
                setHasEmail(personalInfoRes.data);
                setHasCustomerEmail(customerInfoRes.data);
                setHasAdminEmail(adminInfo.data)
                setIsLoading(false);
              }).catch(error => {
                console.error("Error fetching data:", error);
                setIsLoading(false);
              });
            // })
            // .catch(error => {
            //     console.error("Error fetching user session:", error);
            //     setIsLoading(false);
            // });
    }, [accessToken,userSessionDetails]);

    if (isLoading) {
        return <div>

<Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            // onClick={handleClose}
          >
            {/* <CircularProgress color="inherit" /> */}
            <Grid container alignItems="center" justifyContent="center">
                <Grid item md={12} style={{ textAlign: 'center' }}>
                  <img src={gmslogo} alt="Image" className="topBarLogo" />
                </Grid>
                <Grid item md={12} style={{ textAlign: 'center' }}>
              
                <Typography className="loading" variant='h4' color="blue">Please Wait . . .</Typography>
                
                </Grid>
                </Grid>
          </Backdrop>
        </div>;
    }

    if (userSessionDetails?.role) {
        const { name } = userSessionDetails.role;
        switch (name) {
            case "customer":
                return !hasCustomerEmail ? <Customer /> : <SalesEstimate />;
            case "sales":
              return !hasEmail ? < Personalinfo/> : <SalesEstimate />;
            case "foreman":
            case "labour":
            case "purchaseOfficer":
                return !hasEmail ?   <Personalinfo /> : <SalesProduction />;
            case "admin":
                return !hasAdminEmail ?   <AdminOnBoarding /> : <Dashboard />;
            default:
                return !hasEmail ?  <Personalinfo /> : <Dashboard /> ;
        }
    }

    return null; 
};

export default CommonDashboard;




// import { useOidcAccessToken } from "@axa-fr/react-oidc";
// import { useContext, useEffect } from "react";
// import { UserContext, getUserSession } from "../hooks/UserSession";
// import SalesEstimate from "./SalesEstimate/SalesEstimate";
// import Dashboard from "./Dashboard";
// import SalesProduction from "./SalesProduction/SalesProduction";
// import React from "react";
// import { getcustomerInfo, getpersonalInfo } from "../services/EmployeeService";
// import Personalinfo from "./Admin templates/Employment/Employer/Personalinfo";
// import Customer from "./Admin templates/Employment/Customer";

// const CommonDashboard = () => {
//     const { accessToken, accessTokenPayload } = useOidcAccessToken();
//     const { userSessionDetails, setUserDetails } = useContext(UserContext);
//     const [hasEmail, setHasEmail] = React.useState(false);
//     const [hasCustomerEmail, setHasCustomerEmail] = React.useState(false);

//     var userRolePermissions: any;
//     var userRole: any;
//     let userPermissions: Array<any> = [];

//     userRolePermissions = userSessionDetails;
//     if (userRolePermissions != null && userRolePermissions != undefined) {
//         userRole = userRolePermissions.role;
//         if (userRole != null && userRole.userRolePermissions != null) {
//             userPermissions = userRole.userRolePermissions;
//         }
//     }

//     useEffect(() => {
//         //getLocation();
//         getUserSession(accessToken)
//             .then(response => {
//                 console.log(response,accessToken);
//                 getpersonalInfo(response.email, accessToken).then(res => {
//                     console.log(res)
//                     setHasEmail(res.data);
//                 })
//                 getcustomerInfo(response.email, accessToken).then(res => {
//                     //console.log(res)
//                     setHasCustomerEmail(res.data);
//                 })
//                 //    setHasEmail(response);

//             })
//     }, []);
//     if (userRole != undefined && userRole.name == "customer") {
   
//             if (hasCustomerEmail === false) {

//                 return (
//                     <Customer/>
//                 );
//             }
//             else{
//                 return (
//                 <SalesEstimate></SalesEstimate>
//                 );

//             }
       
//     } else if (userRole !== undefined && (userRole.name === "foreman" || userRole.name === "labour")) {
//         if (hasEmail === false) {

//             return (
//                 <Personalinfo />
//             );
//         }
//         else {
//             return (
//                 <SalesProduction />
//             );
//         }
//     }
//     else {
//         if (hasEmail === false) {

//             return (
//                 <Personalinfo />
//             );
//         }
//         else {
//             return (
//                 <Dashboard></Dashboard>
//             )
//         }
//     }

// };

// export default CommonDashboard;
