/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 11-Dec-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    employee 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*  2196           |  06/06/24   | Vanitha       |  Pagination implementation for Employee Management
*  2196           |  14/06/24   | Vanitha       |  count implementation for Employee Management

*
*
*/
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { visuallyHidden } from '@mui/utils';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { Alert, Avatar, Badge, Button, Checkbox, Dialog, DialogActions, DialogContent, Grid, IconButton, InputBase, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Box from '@mui/system/Box/Box';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { deleteUser, getCustomerDetails, getCustomerDetailsCount, getEmployeeAll, getEmployeeCount, getEmployeeDetails } from '../../services/UserService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteVendor, getVendors } from '../../services/VendorService';
import Header from '../../components/Header';
import CustomDialog from '../Dialog';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { getUserDetails } from '../../services/EmployeeService';
import Createuser from '../Createuser';
import SearchIcon from "@mui/icons-material/Search";

interface Data {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  mobileNo: number;
  roles: string;
  status: string
}

function createData(
  id: number,
  firstname: string,
  lastname: string,
  email: string,
  mobileNo: number,
  roles: string,
  status: string
): Data {
  return {
    id,
    firstname,
    lastname,
    email,
    mobileNo,
    roles,
    status
  };
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'firstname',
    numeric: true,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'lastname',
    numeric: true,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'mobileNo',
    numeric: true,
    disablePadding: false,
    label: 'Mobile No',
  },
  {
    id: 'roles',
    numeric: true,
    disablePadding: false,
    label: 'Roles',
  }, {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead >
      <TableRow className='tableHead'>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel

              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}
const Employeetable = () => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortDirection, setSortDirection] = useState('ASC');
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [rows, setRows] = React.useState<any>([]);
  const [count, setCount] = React.useState(Number);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [vendorId, setVendorId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const VENDORACTIVESTATUS = "Active";
  const VENDORINACTIVESTATUS = "Inactive";
  const [searchParams, setSearchParams] = React.useState('null');
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);
  const [showAnotherComponent, setShowAnotherComponent] = useState(false);
  const [rowData, setRowData] = useState();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const closeConfirmation = () => {
    setShowAnotherComponent(false);
    fetchData(searchParams);
  }
  const handleEditCustomer = (row: any) => {
    setRowData(row)
    setShowAnotherComponent(true);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onDeleteVendor = async (vendorId: any) => {
    await setOpenDialog(false);
    setOpenDialogs(true);
    let result = await deleteVendor(vendorId, VENDORINACTIVESTATUS, accessToken);
    if (result.status === 204 || result.status === 200) {
      // handleToastClick("Deleted");
      // getVendorList();
      setDeleteSuccess(true);
      fetchData(searchParams);
    }
    else {
      // handleToastClick("Not Deleted");
      setDeleteError(true);
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
};

const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  const CustomCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
      width: '1em', // Customize the width of the checkbox
      height: '1em',
      fontWeight: '300',
      color: '#ccc'// Customize the height of the checkbox
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      fill: '#007bff', // Customize the fill color when checked
    },
  });


  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const inputchange = async (e: any) => {
    var value = e.target.value === "" ? null : e.target.value;
    if(e.target.value.length >= 3){
      setSearchParams(value);
      fetchData(value);
    }
    if(e.target.value.length === 0){
      setSearchParams(value);
      fetchData(value);
    }
  };

  const fetchData = async (searchParam:any) => {
      const res = await getEmployeeCount(searchParam, accessToken);       
      setCount(res.data)
      const response = await getEmployeeDetails(searchParam, page,rowsPerPage,accessToken);
      setRows(response.data);
  };

  const handleEdit = (rowsData: any) => {
    console.log(rowsData.id);
    navigate("/createuser",
      { state: { rowsData } }
    );
  }
  useEffect(() => {
    fetchData(null);
  }, [page,rowsPerPage]);

  const onEdit = async (id: any, rolestatus: any) => {
    console.log(id);
    try {
      const response = await getUserDetails(id, accessToken);
      console.log(response); // Log the response if the promise resolves successfully
      navigate("/admin/employment",
        { state: { response, rolestatus,id } }
      );
    } catch (error) {
      console.error(error); // Log any errors that occur during the request
    }
  };

  let navigate = useNavigate();
  const addEmployee = () => {
setShowAnotherComponent(true)
  };
  const GoBack = () => {
    navigate("/admin"); // Navigate to the asset list view
  };


  return (
    // <div >
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
        <Alert onClose={handleClose} security='success' sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
      >
        <Header title="Employers List" subtitle="" />
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item md={12}>
          <Box sx={{ width: '100%', }}>
            <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
              <div className='searchBar'>
                <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                  <Grid item xs={6} > <Paper
                    variant="outlined"
                    component="form"
                    className='searchField'
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", maxWidth: 300 }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search"
                      inputProps={{ "aria-label": "search google maps" }}
                      error={true}
                      onChange={(e) => inputchange(e)}
                    />
                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  </Grid>
                  <Grid item xs={6} className='alignEnd' sx={{ padding: '15px' }}>
                    <Button
                      variant="contained" size="large"
                      onClick={addEmployee}
                    >
                      <AddOutlinedIcon className='personIcon' />&nbsp; Invite Employee
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <TableContainer sx={{ marginTop: 3 }}>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  {rows?.length === 0 ? <Typography textAlign={'center'}>No Employees</Typography> :
                    <TableBody>
                      {rows?.map((row, index) => {
                        if (row.roles === 'customer') {
                          return null; // Skip rendering this row
                        }
                        const isItemSelected = isSelected(Number(row?.id));
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, Number(row?.id))}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell padding="checkbox" size='small'>
                              <CustomCheckbox
                                className='check'
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              size='small'
                              // className='tablerows'
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row?.id}
                            </TableCell>
                            <TableCell size='small' style={{ textAlign: 'center' }}>
                              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                                <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                >
                                  <Avatar src={`${row.avatar}`} />
                                </StyledBadge>&nbsp;
                                {row?.firstname}
                              </Stack>

                            </TableCell>
                            <TableCell size='small'>{row?.lastname}</TableCell>
                            <TableCell size='small'>{row?.email}</TableCell>
                            <TableCell size='small'>{row?.mobileNo}</TableCell>
                            <TableCell size='small'>{row?.roles}</TableCell>
                            <TableCell size='small'>{row?.status}</TableCell>
                            <TableCell size='small' align='right'>
                              <Stack direction={'row'}>



                                {(row.status === 'Invited' || !row.status) && (
                                  <Tooltip title="Edit">
                                    <IconButton onClick={() => handleEdit(row)} disabled>
                                      <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {(row.status === 'Registered' || row.status === 'Active')&& (
                                  <Tooltip title="Edit">
                                    <IconButton onClick={() => onEdit(row?.id, row?.roles)}>
                                      <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
              <TablePagination
                sx={{ border: 'none', }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}

      >
        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
          <DialogContent >
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              Are you sure?
            </Typography>
            <Typography textAlign={'center'}>
              You won't be able to revert this!
            </Typography>
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
          <Button
            onClick={() => onDeleteVendor(vendorId)}
            autoFocus className="primaryTonalButton">
            Yes, Delete it!
          </Button>
        </DialogActions>

      </Dialog>
      <CustomDialog
        open={openDialogs}
        onClose={() => setOpenDialogs(false)}
        success={deleteSuccess}
        error={deleteError}
        Content={deleteSuccess ? "Deleted Successfully" : "Not Deleted Successfully"}
      />
      {showAnotherComponent && (<Createuser open={showAnotherComponent} onClose={() => closeConfirmation()} setRows={setRows} rowDatas={rowData} />)}
    </>
  )
}

export default Employeetable;
