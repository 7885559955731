/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : VijayaKumar.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Bid Finalize Card For Create Bid
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/

import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { Box, Button, CardContent, Divider, Rating, TextField, Typography, useTheme, Grid, Card, Stack } from '@mui/material';
import '../CreateBid.scss';
import CloseIcon from '@mui/icons-material/Close';
import img from '../../../assets/imgs/roofingasphalt03.jpg'
import { tokens } from '../../../hooks/theme';
import StarIcon from '@mui/icons-material/Star';
import { getAssetsByAssetUid } from '../../../services/BidService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';


const ShoppingCart: React.FC<any> = (props) => {
    // console.log(props)
    const [value, setValue] = React.useState('female');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedAsset, setSelectedAsset] = React.useState<any>({});
    const [address, setAddress] = React.useState();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    React.useEffect(() => {
        // var array = (selectedAsset.address).split(",");
        // setAddress(array[array.length - 1]);
        (async () => {
            await getAssetsByAssetUid(accessToken, props?.bid?.assetUid).then((res: any) => {
                if (res?.data !== null && res?.data !== undefined && res?.data !== '') {
                    console.log(res.data)
                    setSelectedAsset(res.data);
                }
            })
        })();
    }, [])


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };


    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
    }

    return (
        <>

            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Card variant="outlined" className='offerCard'>
                                    <CardContent className='offerCardContent'>
                                        <Stack direction="row" spacing={1} justifyContent='space-between'>
                                            <Box>
                                                <Typography>Available Offers</Typography>
                                            </Box>
                                            <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                                <Box>
                                                    <Typography><CloseIcon fontSize='small' /></Typography>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        <Typography className='offerCardSubContent'>Any Offers applied will be shown here x1</Typography>
                                        <Typography className='offerCardSubContent'>Any Offers applied will be shown here x2</Typography>
                                    </CardContent>

                                </Card>
                            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card variant="outlined" className='shoppingCard'>
                    <Typography className='shoppingCardHeader' variant='h1' color={"white"} sx={{ backgroundColor: "#3c5bfa" }} >Finalize Bid</Typography>
                    <Divider />
                    <CardContent>


                        {/* <Stack direction="row" justifyContent={"flex-end"}>
                                                <Box>
                                                    <Typography><CloseIcon fontSize='small' /></Typography>
                                                </Box>
                                            </Stack>
                                       */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} sm={12} lg={4} xl={4}>
                                <Typography sx={{ paddingBottom: 1, color: "rgb(60 60 60 / 87%)" }} variant='h4' >{props?.bid?.trade}</Typography>
                                <img src={img} width="100%" height="150px" alt='roofing' />
                            </Grid>
                            <Grid item xs={12} md={5} sm={12} lg={5} xl={5} className='alignCenter'>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h5' color="rgb(60 60 60 / 87%)">{selectedAsset.name} - {address}, {selectedAsset.city}, {selectedAsset.state}, {selectedAsset.zipCode}</Typography>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} md={2} sm={12} lg={2} xl={2} className='alignCenter'>
                                                <Stack direction={'column'}>
                                                <Typography className='formSubTitle'>Grade {props.bid.grade.toUpperCase()}</Typography>
                                                <Typography
                                                    sx={{
                                                        width: 200,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Rating
                                                        name="text-feedback"
                                                        value={props.bid.grade === 'a' ? 3 : props.bid.grade === 'b' ? 2 : 1}
                                                        readOnly
                                                        precision={0.5}
                                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                        max={3}
                                                    />
                                                </Typography>
                                                </Stack>
                                            </Grid> */}
                            <Grid item xs={12} md={3} sm={12} lg={3} xl={3} className='alignCenter'>
                                <Stack direction={'column'}>
                                    <Typography className='formSubTitle'>Estimated Start Date</Typography>
                                    <Typography className='formSubTitle'>{dateFormatter(props?.bid?.startDate)}</Typography>
                                </Stack>
                                {/* <Typography className='shoppingCardPrice' sx={{ color: colors.primary[500] }}>${props.bid.totalCost}</Typography> */}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className='alignCenter' sx={{ fontSize: '18px', fontWeight: 'bold' }}>Sales Associates will get back to you once estimate is ready</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3} sm={12} lg={4} xl={4} className='alignCenter'>
                                        <Stack direction={'column'}>
                                            {/* <Typography className='formSubTitle'>{props.bid.materialType} - {props.bid.manufacturer}</Typography>
                                            <Typography className='formSubTitle'>{props.bid.stories} Storey Building - {props.bid.measurement} sq.</Typography> */}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} lg={3} xl={3} className='alignCenter'>

                                        {/* <Stack direction={'column'}>
                                            <Typography className='formSubTitle'>Estimated Start Date</Typography>
                                            <Typography className='formSubTitle'>{dateFormatter(props.bid.startDate)}</Typography>
                                        </Stack> */}
                                    </Grid>
                                    <Grid item xs={12} md={3} sm={12} lg={3} xl={3} className='alignCenter'>
                                        <Stack direction={'column'}>
                                            {/* <Typography className='formSubTitle'>Estimated End Date</Typography>
                                            <Typography className='formSubTitle'>{dateFormatter(props.bid.jobEnddate)}</Typography> */}
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={1} sm={12} lg={2} xl={2} >
                                        {/* <Stack direction={'column'} className='alignCenter'>
                                            <Typography className='formSubTitle' >Grade {props.bid.materialGrade.toUpperCase()}</Typography>
                                            <Box
                                                sx={{
                                                    width: 60,
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                }}
                                            >
                                                <Rating
                                                    name="text-feedback"
                                                    value={props.bid.materialGrade === 'a' ? 3 : props.bid.materialGrade === 'b' ? 2 : 1}
                                                    readOnly
                                                    precision={0.5}
                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                    max={3}
                                                />
                                            </Box>
                                        </Stack> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            {/* <Grid item xs={12} md={3} sx={{ borderLeft: '2px solid lightgrey' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} mt={2}>
                                <Typography>Offer:</Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Stack direction="row" spacing={1} justifyContent='space-between'>
                                    <Box>
                                        <TextField
                                            id="demo-helper-text-aligned"
                                            label="Offer Code"
                                            size="small"
                                            fullWidth
                                            helperText=""
                                        />
                                    </Box>
                                    <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                        <Box pr={1} pt={0.5}>
                                            <Button variant="contained" className='offerButton' sx={{backgroundColor:colors.primary[500]}} size='small'>Apply</Button>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ borderTop: '2px solid lightgrey' }} mt={3}>
                                <Typography className='cartTotalHeader'>Cart Total</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} pb={9}>
                                <Stack direction="row" spacing={1} justifyContent='space-between'>
                                    <Box>
                                        <Typography className='cartTotalSubContent'>Cart Total</Typography>
                                    </Box>
                                    <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                        <Box>
                                            <Typography className='cartTotalContent' sx={{color:colors.primary[500]}}>{props.bid.estimate}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent='space-between'>
                                    <Box>
                                        <Typography className='cartTotalSubContent'>Coupon Discount</Typography>
                                    </Box>
                                    <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                        <Box>
                                            <Typography className='cartTotalContent' sx={{color:colors.primary[500]}}>NA</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent='space-between'>
                                    <Box>
                                        <Typography className='cartTotalSubContent'>Order Total</Typography>
                                    </Box>
                                    <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                        <Box>
                                            <Typography className='cartTotalContent' sx={{color:colors.primary[500]}}>{props.bid.estimate}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent='space-between'>
                                    <Box>
                                        <Typography className='cartTotalSubContent'>Tax</Typography>
                                    </Box>
                                    <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                        <Box>
                                            <Typography className='cartTotalContent' sx={{color:colors.primary[500]}}>NA</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ borderTop: '2px solid lightgrey' }}>
                            </Grid>
                            <Grid item xs={12} md={12} mt={-1}>
                                <Stack direction="row" spacing={1} justifyContent='space-between'>
                                    <Box>
                                        <Typography className='cartTotalSubContent'>Total</Typography>
                                    </Box>
                                    <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                        <Box>
                                            <Typography className='shoppingCardPrice' sx={{ paddingRight: 3,color:colors.primary[500] }}>${props.bid.estimate}</Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </Grid>

                        </Grid>
                    </Grid> */}

        </>
    );
}

export default ShoppingCart;