
/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Child component for tenant selected trade
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { getTenantTradeData, getTrades } from '../../services/TradeConfigurationService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

function not(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => !b.includes(value));
}

function intersection(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.includes(value));
}

function union(a: readonly number[], b: readonly number[]) {
    return [...a, ...not(b, a)];
}
interface SelectAllTransferListProps {
    onLeftChange: (left: number[]) => void;
    onRightChange: (right: number[]) => void;
  }
  
  const SelectAllTransferList: React.FC<SelectAllTransferListProps> = ({
    onLeftChange,
    onRightChange,
  }) => {
    const { accessToken } = useOidcAccessToken();
    const [checked, setChecked] = React.useState<readonly number[]>([]);
    const [left, setLeft] = React.useState<readonly number[]>([]);
    const [right, setRight] = React.useState<readonly number[]>([]);
    const [tradeData, setTradeData] = React.useState<any[]>([]);
  
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
  
    const handleToggle = (value: number) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const numberOfChecked = (items: readonly number[]) =>
      intersection(checked, items).length;
  
    const handleToggleAll = (items: readonly number[]) => () => {
      if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };
  
    const handleCheckedRight = () => {
      const updatedRight = right.concat(leftChecked);
      const updatedLeft = not(left, leftChecked);
  
      setRight(updatedRight);
      setLeft(updatedLeft);
      setChecked(not(checked, leftChecked));
  
      // Notify parent about the changes:
      onRightChange(updatedRight);
      onLeftChange(updatedLeft);
    };
  
    const handleCheckedLeft = () => {
      const updatedLeft = left.concat(rightChecked);
      const updatedRight = not(right, rightChecked);
  
      setLeft(updatedLeft);
      setRight(updatedRight);
      setChecked(not(checked, rightChecked));
  
      // Notify parent about the changes:
      onRightChange(updatedRight);
      onLeftChange(updatedLeft);
    };
  
    const getTradeList = async () => {
      const result = await getTrades(accessToken);
      const tenantTradeResult = await getTenantTradeData(accessToken);
      
      console.log(tenantTradeResult);
      
      // Initialize trade data
      setTradeData(result.data || []);
      
      // Extract trade IDs from the result
      const tradeIds = result.data.map((trade) => trade.id);
      
      if (tenantTradeResult.data.length > 0) {
          console.log('hii');
          
          // Get the trade IDs from tenantTradeResult
          const tenantTradeIds = tenantTradeResult.data.map((tenantTrade) => tenantTrade.id);
          
          // Filter out the tenant trade IDs from tradeIds
          const filteredTradeIds = tradeIds.filter(tradeId => !tenantTradeIds.includes(tradeId));
          
          console.log(filteredTradeIds);
          
          // Set the filtered trade IDs for left
          setLeft(filteredTradeIds);
          onLeftChange(filteredTradeIds); // Update this to use filteredTradeIds

          // Set the tenant trade IDs for right
          setRight(tenantTradeIds); // Set the right item as tenant trade IDs
      } else {
          // If no tenant trades, set all trade IDs to the left
          setLeft(tradeIds);
          onLeftChange(tradeIds); // Update this to use filteredTradeIds

      }
  
      // Call onLeftChange with the current left trade IDs
  };
  
  
    React.useEffect(() => {
      getTradeList();
    }, []);
  
    const customList = (title: string, items: readonly number[]) => (
        <Card>
          <CardHeader
            sx={{ px: 2, py: 1, backgroundColor: '#a4b3f4', fontSize: '17px' }}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={
                  numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                  'aria-label': 'all items selected',
                }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
          />
          <Divider />
          <List
            sx={{
              width: 300,
              height: 190,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
          >
            {items.map((tradeId: number) => {
              // Find the trade object using the ID
              const trade = tradeData.find((t) => t.id === tradeId);
              const labelId = `transfer-list-all-item-${tradeId}-label`;
      
              return (
                <React.Fragment key={tradeId}>
                  <ListItemButton role="listitem" onClick={handleToggle(tradeId)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.includes(tradeId)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={trade?.name || `Trade ${tradeId}`} // Display trade name or fallback text
                    />
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </Card>
      );
      
  
    return (
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <Grid item>{customList('List of Trades', left)}</Grid>
        <Grid item>
          <Grid container direction="column" sx={{ alignItems: 'center' }}>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList('Selected Trades', right)}</Grid>
      </Grid>
    );
  };
  
  export default SelectAllTransferList;
  