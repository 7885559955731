import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';



import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Personalinfo from "./Employer/Personalinfo"
import Employementdetails from "./Employer/Employementdetails"
import Paydetails from "./Employer/Paydetails"
import { useLocation } from 'react-router-dom';
import { getResourceByEmail } from '../../../services/ResourceService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getUserSession } from '../../../hooks/UserSession';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    width: '100%',
    padding: 5,
    display: 'flexbox',

}));




interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Employment() {
    const [value, setValue] = React.useState(0);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [userDetails, setUserData] = React.useState<any>({});
    const [update, setUpdate] = React.useState<any>(false);
    // console.log(userDetails)
    const location = useLocation();
    const rowData = location.state.response.data;
    let rolestatus = location.state.rolestatus;
    let employeeId = location.state.id
    //console.log(location.state.response);
    //console.log(rowData)
    rolestatus = rowData.role;
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        // console.log("change")
        setUpdate(true)
        setValue(newValue);
    };
    const [role, setRole] = React.useState('');
    const [userId, setUserId] = React.useState<any>();

    const [loading, setLoading] = React.useState(true);
    const [bidList, setBidList] = React.useState(undefined);
    const fetchRoleInform = async () => {
        try {
            const response = await getUserSession(accessToken);

            setRole(response.role.name);
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            //console.lerror('Error fetching user session:', error);
            setLoading(false); // Set loading to false in case of error
        }
    };
    const handleResourceByEmail = () => {
        console.log(rowData?.email)
        if (rowData?.email) {
            getResourceByEmail(rowData?.email, accessToken)
                .then(res => {
                    console.log(res);
                    if (res != null) {
                        setUserData(res?.data);
                    }
                });
        }
    }
    React.useEffect(() => {
        //getLocation();
        // console.log("rerender")
        handleResourceByEmail();
        fetchRoleInform();

        //    setHasEmail(response);
        setUpdate(false)

    }, [update]);


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Personal info" {...a11yProps(0)} />
                    {(role === 'admin' && rolestatus !== "admin") && <Tab label="Employement details" {...a11yProps(1)} />}
                    {/* <Tab label="Paydetails" {...a11yProps(2)} /> */}
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <Personalinfo rows={rowData} />
            </CustomTabPanel>
            {role === 'admin' && rolestatus !== "admin" &&
                <CustomTabPanel value={value} index={1}>
                    <Employementdetails employeeId={employeeId} role={rowData?.role} email={rowData?.email} firstname={rowData?.firstname} rows={userDetails} />
                </CustomTabPanel>
            }

            {/* <CustomTabPanel value={value} index={2}>
            <Paydetails />
        </CustomTabPanel> */}

        </Box>
    );
}