import { ConfigUrl } from "../auth/OidcConfig";
import { executeGetData, executePostData } from "./ApiBase";

export const getClientAccessUrl = async (groupname: any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/notification/getClientAccessUrl/${groupname}`;
    return await executeGetData(url, accessToken);
  };

  export const decryptNotificationMessage = async (message: any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/notification/decrypt`;
    return await executePostData(url,message, accessToken);
  };
 

  // export const createPayment = async (paymentDetails: any, accessToken:any) => {
  //   const url = `${ConfigUrl.baseURL}/paymentReceives/createPayment`;
  //   return await executePostData(url, paymentDetails, accessToken);
  // };