export const gpsTaskTypes = ["Manual Task", "Create BOM Task", "Approve PO Task", "Confirm PO Task", "Receive Task",
    "Remove Task", "Install Task", "Verify Task", "Thank You Card Task" 
]

export const poTaskGroup = ["Create BOM Task", "Create PO Task", "Approve PO Task", "Confirm PO Task", 
    "Receive PO Task", "Verify PO Task"
]

export const poTaskGroupwithOutBom = ["Approve PO Task", "Confirm PO Task", "Create PO Task",
    "Receive PO Task", "Verify PO Task"
]

export const invoiceTaskGroup = ["Create Invoice Task", "Approve Invoice Task", "Payment Task", "Remainder Invoice Task"
]