import { Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ItemData, ItemDef } from "../../../interface/SdmInterfaces";
import { error } from "console";
import { set } from "date-fns";

interface Props {
    itemDef: ItemDef;
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
    mandatory: string;
}

const FreeText: React.FC<any> = ({ itemDef, measureDef, onItemchange, itemDatas, error }) => {

    // console.log(itemDatas);

    const [itemData, setItemData] = useState<ItemData>((itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0] : []);

    const inputchange = (e: any) => {

        if (itemDatas.length > 0) {
            setItemData({ ...itemDatas[0], Value: e.target.value });
            onItemchange({ ...itemDatas[0], Value: e.target.value });
        } else {
            let tempItemData: ItemData = { ItemOID: itemDef.OID, Value: e.target.value, CodeList: [], MetaKey: itemDef.MetaKey };
            setItemData(tempItemData);
            onItemchange(tempItemData);
        }
    }

    return (
        <>
            <Stack direction="row" spacing={2}>
                <TextField
                    // error={(error?.OID === itemDef.OID) ? ((itemDatas.length === 0 || itemDatas[0]?.Value === '') ? true : false) : false}
                    error={(error?.OID === itemDef.OID) ? true : false}
                    id={itemDef?.OID}
                    label=""
                    name="Value"
                    size="small"
                    sx={{ m: 1 }}
                    fullWidth
                    value={itemData?.Value}
                    required
                    //key={itemDef?.OID}
                    helperText={(error?.OID === itemDef.OID) ? 'Please fill this field' : ''}
                    // helperText={(error?.OID === itemDef.OID) ? ((itemDatas.length === 0 || itemDatas[0]?.Value === '') ? 'Please fill this field' : '') : ''}
                    onChange={(e) => inputchange(e)}
                />
                <Typography sx={{ textAlign: 'center' }}>{measureDef?.Symbol?.TranslatedText?.text}</Typography>
            </Stack>
        </>
    )
}

export default FreeText;