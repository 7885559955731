/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 4-Apr-2024
* @author      : Nandhakumar.R
* @since       : 0.0.1
* Requirement# :
* Purpose      : About page design
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1546          |  4/4/24    |   Nandha      |  About page ui design implementations
* 
*
*/

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Link, Slide, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './about.scss'
import { TransitionProps } from '@mui/material/transitions';
import logo from "../../assets/logo.gif";
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/system/Stack';
import { getVersionDetails } from '../../services/TenantService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import InfoIcon from '@mui/icons-material/Info';



export interface SimpleDialogProps {
    open: boolean;
    onClose: () => void;

}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const About: React.FC<any> = (props: SimpleDialogProps) => {
    const { onClose, open } = props;
    const { accessToken } = useOidcAccessToken();
    const [versionDetails, setVeresionDetails] = useState<any>();
    // //console.log(versionDetails)
    const handleClose = () => {
        onClose();
    };


    const getVersionDetail = async () => {
        const response = await getVersionDetails(accessToken);
        setVeresionDetails(response.data);
        //console.log(response);
    }
    useEffect(() => {
        getVersionDetail();
    }, [open])
    return (

        <Box paddingLeft={8} paddingRight={8}>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                // keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                disableEscapeKeyDown
            >
                <DialogTitle > <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >
                        <Stack direction={"row"} spacing={1}>
                         
                        {/* <Typography className='title' >About us</Typography> */}
                        </Stack>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon sx={{
                                '&:hover': {
                                    color: 'red',
                                },
                            }} />
                        </IconButton>
                    </Box>
                </Box></DialogTitle>
                <Box paddingLeft={8} paddingRight={8} >
                    <Typography textAlign={'center'}>
                        <img src={logo} alt="Image" className="topBarLogo" />
                    </Typography>
                    <Typography textAlign={'center'} fontSize={'20px'} fontWeight={'400'} >
                        Graceful Management Sysytem
                    </Typography>
                </Box>

                <DialogContent >
                    <DialogContentText >
                        <Stack direction="column" spacing={2}>
                            <Typography textAlign="start"><strong>Product Name:</strong> Gms Provider App</Typography>
                            <Typography textAlign="start"><strong>GMS Cloud:</strong>0.5.3</Typography>
                            <Typography textAlign="start"><strong>Tenant Api Version:</strong>{versionDetails?.tenantApiVersion}</Typography>
                            <Typography textAlign="start"><strong>Tenant Build date:</strong> {versionDetails?.tenantApiBuildDate}</Typography>
                            <Typography textAlign="start"><strong>ASE Version:</strong> {versionDetails?.aseVersion}</Typography>
                            <Typography textAlign="start"><strong>ASE Build date:</strong> {versionDetails?.aseBuildDate}</Typography>
                            <Typography textAlign="start">
                                <strong>For Support & Queries:</strong> {' '}
                                <Link href="mailto:info@gracefulmanagement.com">info@gracefulmanagement.com</Link>
                            </Typography>
                            <Typography textAlign="start"><strong>Phone:</strong>262-649-4663</Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <Divider/>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {'Copyright ©  2024 '}
                        <Link color="inherit" href="http://www.gracefulmanagement.com">
                            GMS Inc - All Rights Reserved
                        </Link>


                    </Typography>
                </DialogActions>
            </Dialog>
        </Box >

    )
}

export default About;
