import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Button, FormControl, InputLabel, OutlinedInput, Grid, InputAdornment, Link, List, ListItem, ListItemText } from "@mui/material";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box } from "@mui/system";
import { useState } from "react";

const ResetPasswordDialog: React.FC<any> = (props) => {

    const [showAlert, setShowAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentField, setCurrentField] = useState<any>("");

    let passwordPolicy = [{condition:/(?=.{8,})/, message:"Password must be at least 8 characters long."}, {condition:/(?=.*?[A-Z])/, message:"Password must contain at least one uppercase letter."},
        {condition:/(?=.*?[a-z])/, message:"Password must contain at least one lowercase letter."}, {condition:/(?=.*?[0-9])/, message:"Password must contain at least one number."},
        {condition:/(?=.*?[#?!@$ %^&*-])/, message:"Password must contain at least one special character (!@#$%^&*)."}]

    const [errorMessages, setErrorMessages] = useState<any>([]);

    const closeAlter = () =>{
        setShowAlert(false);
    }

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const validatePassword = () =>{
        console.log(password);
        let messages:any = []
        let validPassword = true;
        if(password !== ""){
            passwordPolicy.map((policy:any)=>{
                var valid =  new RegExp(policy.condition).test(password);
                console.log(valid, policy.condition);
                if(!valid){
                    if(!messages.includes(policy.message)){
                        messages.push(policy.message);
                    }
                    validPassword = false;
                }
            })
        }
        console.log(confirmPassword, validPassword);
        if(!validPassword || password === ""){
            setIsValidPassword(false);
        }else{
            setIsValidPassword(true);
            if(confirmPassword !== ""){
                console.log(password, confirmPassword)
                if(password !== confirmPassword){
                    setIsValidConfirmPassword(false);
                    messages.push("Passwords must match.");

                }else{
                    setIsValidConfirmPassword(true);
                }
            }else{
                setIsValidConfirmPassword(false);
                messages.push("confirmPassword not valid.");
            }
        }
        setErrorMessages(messages);
        console.log(errorMessages);
    }

    const onClose = () => {
        setPassword('');
        setConfirmPassword('');
        setCurrentField('');
        setShowPassword(false);
        setIsValidPassword(true);
        setIsValidConfirmPassword(true);
        setErrorMessages([]);
        props.handleClose();
    }

    const handleClickShowPassword = (name:String) => {
        setShowPassword((show) => !show);
        setCurrentField(name);
    };
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const handleChange = (event:any) =>{
        
        if(event.target.name === "password"){
            setPassword(event.target.value);
        }else{
            setConfirmPassword(event.target.value);
        }

        const atLeastMinimumLength = (password: string) => new RegExp(/(?=.{8,})/).test(password);
        const atLeastOneUppercaseLetter = (password: string) => new RegExp(/(?=.*?[A-Z])/).test(password);
        const atLeastOneLowercaseLetter = (password: string) => new RegExp(/(?=.*?[a-z])/).test(password);
        const atLeastOneNumber = (password: string) => new RegExp(/(?=.*?[0-9])/).test(password);
        const atLeastOneSpecialChar = (password: string) => new RegExp(/(?=.*?[#?!@$ %^&*-])/).test(password); 

        console.log(atLeastMinimumLength(event.target.value), atLeastOneUppercaseLetter(event.target.value), atLeastOneLowercaseLetter(event.target.value),
         atLeastOneNumber(event.target.value), atLeastOneSpecialChar(event.target.value))
    }

return(
    <Dialog
    fullWidth={true}
    maxWidth={'sm'}
    open={props.open}
    // onClose={()=>onClose()}
    
>
    <DialogTitle>
        <Box display="flex" alignItems="center">
            <Box flexGrow={1} >
                <Typography className='cartheader'>Reset Password</Typography>
            </Box>
            <Box>
                <IconButton onClick={()=>onClose()}>
                    <CloseIcon sx={{ color: "red" }} />
                </IconButton>
            </Box>
        </Box>
    </DialogTitle>
    <DialogContent sx={{ backgroundColor: "#f8f7fa" }}>
                <Grid container direction="column" alignItems={"center"}>
                    <Grid item xs={4}>
                    <FormControl sx={{ m: 1,  width: 300 }} variant="outlined" size='small' error={!isValidPassword}>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={(showPassword && currentField === "password") ? 'text' : 'password'}
            name="password"
            value={password}
            onChange={(e:any) => handleChange(e)}
            autoComplete='new-password'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("password")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {(showPassword && currentField === "password") ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                    
                <FormControl sx={{ m: 1,  width: 300 }} variant="outlined" size='small' error={!isValidConfirmPassword}>
          <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={(showPassword && currentField === "confirmPassword") ? 'text' : 'password'}
            name='confirmPassword'
            value={confirmPassword}
            onChange={(e:any) => handleChange(e)}
            autoComplete='new-password'
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("confirmPassword")}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {(showPassword && currentField === "confirmPassword") ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
          />
        </FormControl>
                </Grid>
                {errorMessages.length === 1 ? 
                    <Typography sx = {{color:"red", fontSize:"12px"}}>{errorMessages[0]}</Typography>
                :
                    <List sx = {{ listStyleType : 'disc' }} > 
                        {errorMessages.map((msg:any)=>{
                            return (<ListItem disablePadding sx = {{ display: 'list-item' }}> 
                                <ListItemText secondary={msg} secondaryTypographyProps={{sx:{color:"red", fontSize:"12px"}}}></ListItemText>
                            </ListItem>)
                        })}
                    </List>}

                            
                </Grid>
                
        {/* onClose={handleCloseAlert} */}
        <Dialog open={showAlert}
        >
           <DialogTitle className='warning'>
            <Box paddingLeft={8} paddingRight={8}>
                    <Typography textAlign={'center'}>
                        <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                    <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                       Warning
                    </Typography>
                </Box>
                </DialogTitle>
             <Box paddingLeft={8} paddingRight={8}>
            <DialogActions style={{  justifyContent: "center" }}>
            <Button onClick={()=>closeAlter()} variant='contained' sx={{backgroundColor:"#df3333ed"}}>Close</Button>
                <Button onClick={handleCloseAlert}  variant='contained' color="primary">
                    Ok
                </Button>
            </DialogActions>
            </Box>
        </Dialog>
    </DialogContent>
    
    <DialogActions>
        <Button onClick={()=>onClose()} color="error">Close</Button>
        <Button onClick={() => validatePassword()} color="primary">Reset</Button>
    </DialogActions>
</Dialog>
);

}


export default ResetPasswordDialog;