import React, { useEffect, useState, useContext } from 'react';

import { decryptNotificationMessage, getClientAccessUrl } from '../../services/Notification';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getUserSession, UserContext } from '../../hooks/UserSession';
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Alert, AlertColor, Badge, Box, Button, Card, ClickAwayListener, Dialog, DialogContent, DialogTitle, Divider, Fade, FormControlLabel, FormGroup, IconButton, Paper, Popper, Slide, Snackbar, Stack, Switch, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { WebPubSubClient } from '@azure/web-pubsub-client';
import ClearIcon from '@mui/icons-material/Clear';
import ThreePIcon from '@mui/icons-material/ThreeP';

// import { clear } from 'console';

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  onCount: () => void;
  anchor: HTMLElement | null;
}

const Notification: React.FC<any> = (props: SimpleDialogProps) => {
  const { onClose, open, onCount, anchor } = props;
  const { accessToken } = useOidcAccessToken();
  const [userdata, setUserData] = useState<any>({});
  //("usedtaaa", userdata);
  const [messages, setMessages] = useState<any[]>([]);
 //.log("notification", messages);
  const [isOpen, setIsOpen] = useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [anchorPoper, setAnchorPoper] = React.useState<null | HTMLElement>(null);
  const [count, setCount] = React.useState<any>(0);
 //.log("count", count);
  const [isConnected, setIsConnected] = React.useState(false);
 //.log("isConnected",isConnected)
  const[openSnackBar,setOpenSnackBar] =React.useState(false);
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleNotificationOpen = (event) => {
    if (openNotification) {
      // If it's already open, close it
      handleNotificationClose();
    } else {
      // If it's closed, open it
      setAnchorPoper(event.currentTarget); // Set the anchor to the IconButton
      setOpenNotification(true);
    }
  };

  const handleNotificationClose = () => {
    setAnchorPoper(null);
    setOpenNotification(false);
  };
  function TransitionSlide(props: any) {
    return <Slide {...props} direction="down" />;
  }

 //.log(userdata);
  // const userSessionDetails = useContext(UserContext);
  // const [messages, setMessages] = useState<string[]>([userSessionDetails, "group2y"]);
  // const fetchRoleInform = async () => {
  //   try {
  //    //.log("hiiiiiiiiiiiiiiiii")
  //     const response = await getUserSession(accessToken);
  //    //.log(response)
  //     Object.assign(userdata, response);
  //    //.log("response",response)

  //   } catch (error) {
  //    //.error('Error fetching user session:', error);
  //   }
  // };


  // const handleClose = () => {
  //   onClose();
  //   setMessages([]);
  // };
  const clearAll = () => {
    setMessages([]);
    Object.assign(messages, []);
    setCount(0);
  }

  // const messages = [
  //   "You have a meeting at 10:00 AM.",
  //   "New comment on your post.",
  //   "Your package has been delivered.",
  //   "Your profile has been updated successfully."
  // ];
  const connectToPubSub = async () => {
    if (isConnected) {
     //.log("Already connected to Web PubSub, skipping re-connection.");
      return ; // Exit if already connected
    }
  
   //.log("userSessionDetails", userdata);
   //.log("userData", userdata?.objectId);
    const response = await getUserSession(accessToken);
   //.log(response?.objectId);
    setUserData(response);
    try {
      const clientAccessTokenUrl = await getClientAccessUrl(response?.objectId, accessToken);
     //.log("clientAccessTokenUrl", clientAccessTokenUrl)
      if (clientAccessTokenUrl) {
        const client = new WebPubSubClient(clientAccessTokenUrl.data);
       //.log("y", client)
        // Starts the client connection with your Web PubSub resource
        client.on("connected", (e) => {
         //.log(`Connection ${e.connectionId} is connected.`);
          setIsConnected(true);
        });
        await client.start();

        let groupName = response?.objectId;
       //.log("groupName", groupName);

        // Join the group and set up listeners
        await client.joinGroup(groupName);

        client.on("group-message", async (e) => {
          const encryptedMessage = e.message.data;
         //.log('Encrypted message received:', encryptedMessage);

          // Send the encrypted message to the backend for decryption
          const message = await decryptNotificationMessage(encryptedMessage, accessToken);
         //.log(message)
          if (message.data && message.data.messageContent) {
            setMessages((prevMessages) => [...prevMessages, message.data.messageContent]);

            // Optionally, update count based on new length
            setCount((prevCount) => prevCount + 1);
            setOpenSnackBar(true);
            
          } else {
           //.warn('Message content is missing or empty:', message.data);
          }



          // setMessages((prevMessages) => [...prevMessages, JSON.stringify(message?.data?.messageContent.content)]);

         //.log("length", messages?.length);

         //.log("Decrypted message:", message);
         //.log("Decrypted message:", message?.data?.messageContent);

        });

        // client.on("connected", (e) => {
        //  //.log(`Connection ${e.connectionId} is connected.`);
        // });
      }
    } catch (error) {
     //.error("Error connecting to Web PubSub:", error);
    }
  };

  React.useEffect(() => {
   //.log("Notification started")
    // fetchRoleInform();
    connectToPubSub();
    setIsOpen(open);
   //.log(messages.length)
    setCount(messages.length);
   //.log(count)
  
    // setAnchorEl()
  }, []);

  return (
    <div>
      {/* <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={open}
        // onClose={handleClose}
        //onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown
      > */}
      <IconButton size="large" color="inherit" onClick={handleNotificationOpen}>
        <Badge badgeContent={count} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={8000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={TransitionSlide}
        onClose={() => setOpenSnackBar(false)} 
        message={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <ThreePIcon style={{ marginRight: '8px' ,color:"blue"}} />
            {`Dear ${userdata?.firstname}, You have a new notification`}
          </span>
        }
      />
      <Popper open={openNotification} anchorEl={anchorPoper} placement='bottom-end' >
        <Box sx={{ display: 'flex', alignItems: 'center', width: 300, mt: 2 }}>
          <Box flexGrow={1}>
            <Card sx={{ padding: 2, boxShadow: 3 }}>
              <Stack direction="row" alignItems="center" >
                <NotificationsIcon sx={{ color: "#3c5bfa" }} />
                <Typography variant="h6" className="cartheader" color={"#3c5bfa"}>
                  Notification Center
                </Typography>
                {/* Add any icons or action buttons here, e.g., close or settings */}
              </Stack>



              {/* Notification content goes here */}
              <Box mt={2}>
                <Table>
                  <TableBody>
                    {messages.length > 0 ? (
                      messages.map((message, index) => (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell>
                            <Typography variant="h6" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center',color:"#3c5bfa" }} >
  <ThreePIcon />&nbsp;Dear {userdata?.firstname}!!
</Typography>

                              <Typography
                                variant="body2"
                                marginTop={2}
                                sx={{
                                 
                                  fontSize: '1rem',
                                  color: '#333',
                                  lineHeight: 1.5,
                                  whiteSpace: 'pre-wrap', 
                                  
                                }}
                              >
                                {message?.content}
                              </Typography>

                            </TableCell>
                          </TableRow>
                          {index < messages.length - 1 && <Divider />} {/* Divider between messages */}
                        </React.Fragment>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2">No new notifications.</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

              </Box>
              <Box mt={2}>
              <Button variant="outlined" onClick={clearAll} startIcon={<ClearIcon />}>
                Clear All
              </Button>
              </Box>
            </Card>
          </Box>
        </Box>

        {/* <FormGroup sx={{ color: "#fff" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        onChange={toggleFilter}
                        checked={showUnreadOnly}
                      />
                    }
                    label="Show unread only"
                  />
                </FormGroup> */}


      </Popper>
      {/* </Dialog> */}
    </div>
  );
};

export default Notification;
{/* <DialogTitle>
<Box display="flex" alignItems="center">
  <Box flexGrow={1} >
    <Stack direction="row" >
      {/* <ShoppingCartIcon /> */}
//       <Typography className='cartheader'>Notification</Typography>
//     </Stack>
//   </Box>
//   <Box>
//     <IconButton onClick={handleClose}>
//       <CloseIcon sx={{
//         '&:hover': {
//           color: 'red',
//         },
//       }} />
//     </IconButton>
//   </Box>
// </Box>
// </DialogTitle>
// <DialogContent>
// <Typography>{JSON.stringify(message)}</Typography>
// {/* <Typography>hiiiiiiiiiiiiiii</Typography> */}
// </DialogContent> */}