import React from 'react'

import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';

const TextComponent: React.FC = () => {

    return (
        <div>
            <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
                <Grid>
                    <Grid container spacing={2} mt={1} >
                        <Grid item xs={12} md={12}>
                            <TextField
                                id="demo-helper-text-misaligned"
                                label="Short answer text"
                                fullWidth
                                size='small'
                                InputProps={{
                                    readOnly: true,
                                  }}
                            />
                            {/* <TextField id="demo-helper-text-misaligned-no-helper" label="Name" /> */}
                        </Grid>

                    </Grid>
                </Grid></Box>
        </div>
    )
}

export default TextComponent;