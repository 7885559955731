import { Alert, Box, Button, Card, CardActionArea, Dialog, DialogActions, DialogContent, Grid, IconButton, Snackbar, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Header from "../Header";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router";
import { GoogleMap, MarkerF, useJsApiLoader, useLoadScript } from "@react-google-maps/api";
import React, { useContext } from "react";
import { tokens } from "../../hooks/theme";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import { getAsset, deleteAssetDocument, deleteAsset } from "../../services/AssetService";
import { ImportImage } from "../../services/AssetDocumentService";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CustomDialog from "../../pages/Dialog";
import { useLocation } from "react-router-dom";
import { UserContext, getUserSession } from "../../hooks/UserSession";

const containerStyle = {
    width: '100%',
    height: '180px'
};

const libraries: any = ['places'];
const ListAsset: React.FC = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    let navigate = useNavigate();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries: libraries
    });
    const componentData = React.useRef<any>({ lat: 12.95765817834193, lng: 80.20837068557739 });
    const [map, setMap] = React.useState(null);
    const [assetData, setAssetData] = React.useState([] as any);
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');
    const [imgData, setImgData] = React.useState<any>(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [assetId, setAssetId] = React.useState<any>();
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState(false);
    const [role, setRole] = React.useState('');

    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    // console.log(userSessionDetails.role.name)
  

    const location = useLocation();
    const handleDialogOpen = (assetId: any) => {
        setAssetId(assetId);
        setOpenDialog(true);

    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const onNextPage = () => {
        navigate('/asset');
    }
    const onLoad = React.useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(assetData.componentData);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const getAssets = async () => {
        if (location.state === null || location.state === undefined) {
            let assetDatas = await getAsset(accessTokenPayload.oid, accessToken);
            setAssetData(assetDatas.data);
            setLoading(false);
        }
        else {
            let assetDatas = await getAsset(location.state.res.data, accessToken);
            setAssetData(assetDatas.data);
            setLoading(false);
        }

    }
    // const fetchRoleInform = async () => {
    //     try {
    //         const response = await getUserSession(accessToken);

    //         setRole(response.role.name);
    //         setLoading(false); // Set loading to false when data is fetched
    //     } catch (error) {
    //         //console.lerror('Error fetching user session:', error);
    //         setLoading(false); // Set loading to false in case of error
    //     }
    // };
    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st?.[0]);
        locationObject['lng'] = Number(st?.[1]);
        return locationObject;
    }

    const getImage = async () => {
        let imageData = await ImportImage(accessToken);
        setImgData(imageData.data.Blob);
    }

    React.useEffect(() => {
        setRole(userSessionDetails?.role?.name);
        getAssets();
        // fetchRoleInform();
        // getImage();
    }, [])

    const onDeleteAsset = async (id: any) => {
        await setOpenDialog(false);
        setOpenDialogs(true);
        let result = await deleteAsset(id, accessToken);
        // let deleteAssetDoc = await deleteAssetDocument(id, accessToken);
        if (result.status === 204) {
            // handleClick("Deleted");
            setDeleteSuccess(true);
            getAssets();

        }
        else {
            // handleClick("Not Deleted");
            setDeleteError(true);
            // setOpenDialog(false);
        }

    }

    const onEditPage = (assetObjectData: any) => {
        navigate("/asset",
            { state: { assetObjectData } }
        );
    }
    const handleClick = (message: any) => {
        setOpen(true);
        setToastMessage(message);
    };


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <>

            <Grid container p={3}>

                <Grid item xl={6} md={6}>
                    <Header title="My Assets" subtitle={""} />
                </Grid>
                <Grid item xl={6} md={6} sx={{ textAlign: "end" }}>
                    {role !== 'Admin' && role !== 'admin' && (
                        <Button
                            className='primaryTonalButton'
                            variant="contained"
                            size="medium"
                            onClick={onNextPage}
                        >
                            <AddOutlinedIcon className='personIcon' />&nbsp; Add New Assets
                        </Button>
                    )}

                </Grid>

            </Grid>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                <Alert onClose={handleClose} security='success' sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            {loading ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box> :

                <Grid container rowSpacing={3} p={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4 }} >
                    {/* <Grid item xl={1} sx={{ backgroundColor: "yellow" }}></Grid> */}
                    {assetData?.map((data: any) => {

                        return (<>
                            <Grid item xs={12} sm={6} md={6} lg={12} xl={6}  >
                                <Box sx={{ width: '100%', }}>

                                    <Card variant="outlined" >
                                        <CardActionArea>
                                            <Grid container spacing={2} >
                                                <Grid item xs={4} sm={4} md={4} lg={3} xl={4} className="assetContent" marginLeft={3} onClick={() => onEditPage(data)} >
                                                    {/* <img src={require("../../assets/images/OIP.jpg")} alt='asset' className="assetImage" /> */}
                                                    {isLoaded ? (
                                                        <GoogleMap
                                                            mapContainerStyle={containerStyle}
                                                            center={convertLocation(data.location)}
                                                            zoom={20}
                                                            onUnmount={onUnmount}
                                                            options={{
                                                                zoomControl: false,
                                                                streetViewControl: false,
                                                                mapTypeControl: false,
                                                                fullscreenControl: false,
                                                                keyboardShortcuts: false
                                                            }}
                                                        >
                                                            <MarkerF
                                                                position={convertLocation(data.location)}

                                                            />
                                                            <></>
                                                        </GoogleMap>
                                                    ) : <></>}
                                                </Grid>
                                                <Grid item xs={4} sm={6} md={6} lg={6} xl={6} onClick={() => onEditPage(data)}>
                                                    <Box
                                                        flexDirection="column"
                                                        mt={2} mb={2}>
                                                        <Typography className="assetSubName" sx={{ color: "#3c5bfa" }}>{data.name}</Typography>
                                                        <Typography sx={{ color: "#4d4d4d", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{data.address}</Typography>
                                                        <Typography sx={{ color: "#4d4d4d" }}   >{data.city} , {data.state}</Typography>
                                                        <Typography sx={{ color: "#4d4d4d" }}>{data.country} , {data.zipCode}</Typography>
                                                        &nbsp;
                                                        <Typography color="#737171">Number of stories : {data.stories}</Typography>
                                                        <Typography color="#737171">Area in square : {data.area}</Typography>
                                                        &nbsp;
                                                        <Stack direction={'row'} spacing={2} >
                                                            <Button>Replace Roof</Button>
                                                            <Button>Replace Siding</Button>
                                                            {/* <Button>Replace Gutter</Button>
                                                    <Button>More</Button> */}
                                                        </Stack>

                                                    </Box>
                                                </Grid>

                                                <Grid item xs={4} sm={3} md={3} lg={3} xl={1} >
                                                    <Grid container direction={"column"} sx={{ height: "100%" }}>
                                                        <Grid item xs={2} >
                                                            <Box >
                                                                <Stack direction="row">
                                                                    <Tooltip title="Edit">
                                                                        <IconButton onClick={() => onEditPage(data)}>
                                                                            <EditIcon fontSize="medium" sx={{
                                                                                '&:hover': {
                                                                                    color: 'green',
                                                                                },
                                                                            }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Delete">
                                                                        <IconButton onClick={() => handleDialogOpen(data.id)}>
                                                                            <DeleteIcon fontSize="medium" sx={{
                                                                                '&:hover': {
                                                                                    color: 'red',
                                                                                },
                                                                            }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Stack>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={10} onClick={() => onEditPage(data)}>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Box mt={2} mb={2}>
                                                    <Stack direction="row">
                                                        <Tooltip title="Edit">
                                                            <IconButton onClick={() => onEditPage(data)}>
                                                                <EditIcon fontSize="medium" sx={{
                                                                    '&:hover': {
                                                                        color: 'green',
                                                                    },
                                                                }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => handleDialogOpen(data.id)}>
                                                                <DeleteIcon fontSize="medium" sx={{
                                                                    '&:hover': {
                                                                        color: 'red',
                                                                    },
                                                                }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Box> */}

                                                    {/* {isLoaded ? (
                                                <GoogleMap
                                                    mapContainerStyle={containerStyle}
                                                    center={data.location}
                                                    // zoom={10}
                                                    onLoad={onLoad}
                                                    onUnmount={onUnmount}
                                                    options={{
                                                        zoomControl: false,
                                                        streetViewControl: false,
                                                        mapTypeControl: false,
                                                        fullscreenControl: false,
                                                    }}

                                                >
                                                    <MarkerF
                                                        position={data.location}

                                                    >
                                                    </MarkerF>
                                                    <></>
                                                </GoogleMap>
                                            ) : <></>} */}
                                                </Grid>
                                            </Grid>
                                        </ CardActionArea>
                                    </Card>
                                </Box>

                            </Grid>
                        </>
                        )
                    })}
                    {/* <Grid item xs={1} sx={{backgroundColor:"yellow"}}></Grid> */}

                    {/* <Grid item xs={1} sx={{backgroundColor:"yellow"}}></Grid> */}
                </Grid>
            }
            {assetData.length === 0 &&
                <Box justifyContent={'center'} textAlign={'center'} >
                    <Typography sx={{ fontSize: "20px" }}>No Assets, Please Create One</Typography>
                </Box>
            }
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}

            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Are you sure?
                        </Typography>
                        <Typography textAlign={'center'}>
                            You won't be able to revert this!
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
                    <Button onClick={() => onDeleteAsset(assetId)} autoFocus className="primaryTonalButton">
                        Yes, Delete it!
                    </Button>


                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openDialogs}
                onClose={() => setOpenDialogs(false)}
                success={deleteSuccess}
                error={deleteError}
                Content={deleteSuccess ? "Deleted Successfully" : "Not Deleted Successfully"}
            />
        </>
    );
}

export default ListAsset;