import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import { CodeList, CodeListItem, ConditionDef, ItemData, ItemDef } from "../../../interface/SdmInterfaces";
import { useEffect, useState } from "react";

interface ComboProps {
    itemDef: ItemDef;
    conditionDef: ConditionDef[];
    codeList: CodeList[];
    onItemchange: React.Dispatch<React.SetStateAction<any>>;
    showStatus: React.Dispatch<React.SetStateAction<any>>;
}

const Combo: React.FC<any> = ({ itemDef, conditionDef, codeList, onItemchange, showStatus, itemDatas, error }) => {
    
    //console.log(itemDatas);

    const [itemData, setItemData] = useState<ItemData>();
    const [selected, setSelected] = useState('');

    const handleChange = (event: any) => {
        let tempItemData: ItemData = { ItemOID: itemDef?.OID, Value: event.target.value, CodeList: [], MetaKey: itemDef?.MetaKey };
        setItemData(tempItemData);
        onItemchange(tempItemData);
    }


    return (
        <>
            <FormControl variant="outlined" fullWidth size="small" sx={{ m: 1 }} >
                {/* <InputLabel id="demo-select-small-label">Customers</InputLabel> */}
                <Select
                    // error={selectedData.customer === undefined && formValidator === true}
                    value={(itemDef?.OID === itemDatas[0]?.ItemOID) ? itemDatas[0]?.Value : itemData?.Value}
                    //label="Customers"
                    onChange={(e) => handleChange(e)}
                    name='customer'
                >
                    {/* <MenuItem value="">
                        <em>None</em>
                    </MenuItem> */}
                    {codeList?.map((item: any) => {
                        return (
                            item?.CodeListItem?.map((i: any, index: any) => {
                                return (
                                    <MenuItem value={i?.Decode?.TranslatedText?.text}>
                                        {/* <Checkbox value={i?.CodedValue} checked={i.IsEnabled} />
                                        <ListItemText primary={i?.Decode?.TranslatedText?.text} /> */}

                                        <MenuItem value={i?.Decode?.TranslatedText?.text}>{i?.Decode?.TranslatedText?.text}</MenuItem>
                                    </MenuItem>
                                )
                            })
                        )
                    })}
                </Select>
            </FormControl>
        </>
    )
}

export default Combo;