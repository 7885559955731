/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-Oct-2024
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Uploading Delivery Slip from Desktop
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useCallback } from "react";
import {
    DialogContent, DialogActions,
    DialogTitle, Dialog, Box, Typography, Card, CardContent, Stack, Grid, Button, Divider, IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Paper,
    CircularProgress,
} from '@mui/material';
import '../paymentTypes/unPaidList/MakePaymentList'
import CloseIcon from '@mui/icons-material/Close';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useEffect, useState } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../pages/Dialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadReceipt } from "../../services/DeliverySlipService";


const UploadSlip: React.FC<any> = (props) => {
    const navigate = useNavigate();

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [bill, setBill] = useState<any[]>([]); // Initialize with an empty array
    const [type, setType] = useState('');
    const [pOId, setPOId] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(true);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);


    const handleOpenWithTimeout = () => {
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, 3000); // Adjust the delay (in milliseconds) as needed
    };

    const onClose = () => {
        props.handleClose();
        window.location.reload();
    }

    useEffect(() => {
        setBill(props.billDetails);
        props?.billDetails?.map((b) => {
            Object.entries(b).map((data: any) => {
                console.log(data[1])
            })
            //console.log(Object.entries(b))
        });
        handleOpenWithTimeout();
    }, [isOpen]);

    const onselect = async (e: any) => {
        setType(e.target.value);
    };

    const onselected = async (e: any) => {
        console.log(e.target.value)
        setPOId(e.target.value);
        // Object.entries(bill).map((b: any) => {
        //     return (
        //         b[1]?.map((bv: any) => {
        //             console.log(bv)
        //         })
        //     )
        // })
    };

    const handleDialogClose = () => {
        setOpenDialogs(false);
        onClose();
        window.location.reload();
    };

    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            console.log(files)
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = (file: File) => {
        console.log(file);
        setLoading(true);
        setFile(file);
        const formData = new FormData();
        formData.append('file', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setLoading(false);
            if (reader.result === null) {
                // Handle the case where reader.result is null
                setImagePreview(null);
            } else {
                // Handle the case where reader.result is a string
                setImagePreview(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    let images: any = [];
    const handleChange = useCallback(
        (event: any) => {
            // const files = event.target.files;
            // if (files && files[0]) {
            //   handleFileChange(files[0]);
            // }
            for (let i = 0; i < event.target.files.length; i++) {
                images.push((event.target.files[i]));
                let reader = new FileReader();
                let file = event.target.files[i];
                // handleFileChange(images);
                setFile(images);
                reader.onloadend = () => {
                    SetSelectedFile((preValue) => {
                        return [
                            ...preValue,
                            {
                                // id: shortid.generate(),
                                filename: event.target.files[i].name,
                                filetype: event.target.files[i].type,
                                fileimage: reader.result,
                                datetime: event.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(event.target.files[i].size)
                            }
                        ]
                    });
                }
                if (event.target.files[i]) {
                    reader.readAsDataURL(file);
                }
            }
        },
        []
    );

    const handleUploadChange = async () => {
        setOpenDialogs(true);
        console.log("Upload file:", file);
        try {
            if (file !== null && file.length > 0) {
                const formData = new FormData();
                file.map((file: any) => {
                    formData.append('file', file);
                });
                console.log(formData, file);
                formData.append('name', type)
                // const datas = {
                //     file: formData,
                //     name: type
                // }
                const data = await uploadReceipt(pOId[0], pOId[1], formData, accessToken)
                if (data?.status == 200) {
                    console.log("Upload successful:", data);
                    setCreateSuccess(true);
                } else {
                    setCreateError(true);
                    console.error("Upload failed:", data);
                }
            }
            else {
                console.error("Invalid file:", file);
                setCreateError(true);
            }
        }
        catch (error) {
            console.error("Error uploading:", error);
        }
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={props.open}
            // onClose={()=>onClose()}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Typography className='unPaidHead'>Upload Receipt/Bill</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => onClose()}>
                                <CloseIcon sx={{ color: "red" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: "#f8f7fa" }} >
                    {bill?.length === 0 ? (
                        <CardContent className='noInvoice'>
                            <Typography variant="body1" className='noInvoiceFound'><ReceiptIcon /></Typography>
                            <Typography variant="body1" className='noInvoiceFound'>No purchase order found.</Typography>
                        </CardContent>
                    ) : (
                        bill?.map((row: any, index: any) =>
                        (
                            <Card className='contractCard' >
                                <CardContent >
                                    <Grid container className='makePayment'>

                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant='h5' color="rgb(60 60 60 / 87%)">Upload Type</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                    <FormControl variant="outlined" fullWidth size="small">
                                                        <InputLabel id="demo-select-small" required>Type</InputLabel>
                                                        <Select
                                                            // error={selectedData.assetUid === undefined && completed}
                                                            required
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={type}
                                                            label="Type"
                                                            onChange={(e) => onselect(e)}
                                                            name='Type'
                                                        >
                                                            <MenuItem value="DeliverySlip">Delivery Slip</MenuItem>
                                                            <MenuItem value="Receipt">Receipt</MenuItem>
                                                            <MenuItem value="Bill">Bill</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant='h5' color="rgb(60 60 60 / 87%)">Purchaser Order No.</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                    <FormControl variant="outlined" fullWidth size="small">
                                                        <InputLabel id="demo-select-small" required>Purchase Order No</InputLabel>
                                                        <Select
                                                            // error={selectedData.assetUid === undefined && completed}
                                                            required
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={pOId[1]}
                                                            label="Purchase Order Number"
                                                            onChange={(e) => onselected(e)}
                                                            name='purchaseOrder'
                                                        >
                                                            {Object.entries(row).map((data: any) => {
                                                                return (
                                                                    data[1]?.map((purchaseId: any, index: any) => {
                                                                        return (
                                                                            <MenuItem key={index} value={[data[0], purchaseId]}>{purchaseId}</MenuItem>
                                                                        )
                                                                    })
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box>
                                            <Paper
                                                variant="outlined"
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                                style={{
                                                    border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
                                                    padding: 20,
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                    background: dragOver ? '#eee' : '#fafafa',
                                                    position: 'relative',
                                                }}
                                            >
                                                <input
                                                    accept="image/*,.pdf"
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    multiple
                                                    type="file"
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Box display="flex" flexDirection="column" alignItems="center">
                                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                                            <CloudUploadIcon style={{ fontSize: 60 }} />
                                                        </IconButton>
                                                        <Typography>Upload</Typography>
                                                        <Typography>Drag and drop files here or click to select files</Typography>
                                                    </Box>
                                                </label>
                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Paper>

                                            <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                                                {selectedfile.map((i: any) => {
                                                    console.log(typeof (i.fileimage))
                                                    return (
                                                        <>
                                                            {/* < Grid item xs={12} sm={6} md={4} p={2}>
                                                        <Box
                                                            component="img"
                                                            src={i.fileimage}
                                                            alt="Image Preview"
                                                            sx={{ width: '100%', height: 'auto' }}
                                                        />
                                                    </Grid> */}

                                                            <iframe
                                                                title="PDF Viewer"
                                                                src={i.fileimage}
                                                                style={{ height: '800px', width: '100%' }}
                                                            />

                                                        </>
                                                    )
                                                }
                                                )}

                                            </Grid >
                                        </Box>
                                    </Grid>

                                </CardContent>
                            </Card>
                        ))
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadChange}>Submit</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openDialogs}
                // onClose={() => setOpenDialogs(false)}
                onClose={handleDialogClose}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Uploaded succesfully" : " Upload Failed "} />
        </>
    );
}


export default UploadSlip;
