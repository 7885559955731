import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import Genralconfiguration from "./Generalconfiguration"
import Tradeconfiguration from "./tradeconfiguration/Tradeconfiguration"
import { useMediaQuery, useTheme } from '@mui/material';

import TextField from '@mui/material/TextField';

import Financesettings from "./financesettings/Financesettings";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getTenantDetails } from '../../services/TenantService';
import { inherits } from 'util';
import Stack from '@mui/material/Stack';
import ProcessMetrics from './processworkflowmetrics/ProcessMetrics';
import Questions from '../../pages/questionBuilder/components/Questions';
import PermanentDrawerLeft from '../../pages/questionBuilder/TradeQuestions';
import MyComponent from '../../pages/questionBuilder/TestCompoenet';
import Mailcontent from './Mailconfiguration/Mailcontent';

  

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    > •
    </Box>
);





function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Tenantconfiguration() {
    const [tradeType, setTradeType] = React.useState<any>('');
    const [type, setType] = React.useState<any>('');
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
// console.log(location.state?.assetObjectData;) 
   
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [tenantDetails, setTenantDetails] = React.useState<any>();

    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
      }
    

    React.useEffect(() => {
        (async () => {
        const response = await getTenantDetails(accessToken);
        if(response.status == 201 || response.status == 200){
            setTenantDetails(response.data);
        }
    })()
      }, []);

    //to handle asset questions 
    const handleAssetQuestionsClick = () => {
        // Add any specific logic you want to execute when the "Asset Questions" tab is clicked
        // For example, you can pass the asset prop to the Questions component
        setType("asset")
    };

    return (<>
        <Grid container  >
            <Grid item xs={12} sm={12}>
                <Box>
                    {/* {tenantDetails != undefined ? <Card>
                        <Grid container sx={{ p: 3 }} spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={12} md={4} >
                            {tenantDetails?.name != undefined ?
                                <Typography variant="h2" component="h2" sx={{ mt: 1 }}>
                                    {tenantDetails?.name}
                                </Typography> :
                                <Typography variant="h3" sx={{ mt: 1 }} color={"grey"}>
                                    {"{Organization Name}"}
                                </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} md={6} >
                            </Grid>
                            <Grid item xs={12} md={2}  >
                                <Stack  direction="row" ><Typography variant="h5" component="h5" sx={{ mr: 1 }}>Updated Date :</Typography> {dateFormatter(tenantDetails?.updatedAt)}</Stack>
                            </Grid>
                        </Grid>
                    </Card>
                    : <></>} */}

                    <Card sx={{ minWidth: '100%', mt: 1 }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab sx={{textTransform :"none"}} label="General Configuration" {...a11yProps(0)} />
                                <Tab  sx={{textTransform :"none"}} label="Asset" {...a11yProps(1)} onClick={handleAssetQuestionsClick}  />

                                <Tab sx={{textTransform :"none"}} label="Trade Configuration" {...a11yProps(2)} />
                                <Tab sx={{textTransform :"none"}} label="Finance  Settings" {...a11yProps(3)} />
                                <Tab sx={{textTransform :"none"}} label="Mail Configuration" {...a11yProps(4)} />
                                <Tab sx={{textTransform :"none"}} label="Process Workflow Metrics" {...a11yProps(5)} />
                                {/* <Tab sx={{textTransform :"none"}} label="Test" {...a11yProps(6)} /> */}

                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Genralconfiguration />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                        <Box>
                            <Questions questionType={type} tradeTypeName='asset' />
                        </Box>
                    </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <PermanentDrawerLeft />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <Financesettings/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                            <Mailcontent/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}>
                            <ProcessMetrics />
                        </CustomTabPanel>
                        {/* <CustomTabPanel value={value} index={6}>
                            <MyComponent />
                        </CustomTabPanel> */}
                    </Card>
                </Box>
            </Grid>
        </Grid>
        </>
    );
}