import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';

const IntegerComponent: React.FC = () => {
  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={12}>
            <TextField
              id="integer-input"
              label="Integer Input"
              fullWidth
              size='small'
              type='number' // Set the input type to 'number'
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default IntegerComponent;