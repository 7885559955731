import { ConfigUrl } from '../auth/OidcConfig';
import { executePostData, executePostImage } from './ApiBase';

export const importCustomers = async (file: any,encodedMapping :any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/import/customers/mapping/${encodedMapping}`;
    return await executePostImage(url, file, accessToken);
};

export const importVendors = async (file: any,encodedMapping :any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/import/vendors/mapping/${encodedMapping}`;
    return await executePostImage(url, file, accessToken);
};

export const importCodes = async (file: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/import/codes`;
    return await executePostImage(url, file, accessToken);
};

export const importLedger = async (file: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/import/ledger`;
    return await executePostImage(url, file, accessToken);
};