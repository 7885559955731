import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';

const MultiLineText: React.FC = () => {
  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
        <Grid container spacing={2} mt={1}>

          <Grid item xs={12} md={12}>
            <TextField
              id="multiline-text"
              label="Multiline Text"
              multiline
              fullWidth
              rows={4}
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default MultiLineText;
